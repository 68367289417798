import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import PaginatedItems from "./Pagination";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import AddWeekModal from "./addWeekModal";
import Loader from "./loader";
import { Field } from "formik";
import Select from "react-select";
import CreateFormModal from "./createFormModal";
import EditEventModal from "./ModalAddDealer_CreateEvent";
import { Link, useNavigate } from "react-router-dom";
import FilterBar from "./components/filterBar";
import debounce from "lodash/debounce";
import { internalUsers } from "./constants/userTypes";

const Forms = ({
  setDisplayComponent,
  eventTrigger,
  setEditEventID,
  setIsDarkMode,
  isDarkMode,
  isLoading,
  setIsLoading,
  users,
  dealerships,
  setPrevDisplay,
  events,
  setEvents,
  // setDealerships2,
  key,
  setKey,
  total,
  setTotal,
  searchCache,
  setSearchCache,
  pageCache,
  setPageCache,
  deleteStates,
  setDeleteStates,
  pageNo,
  setPageNo,
  selectedDealershipOption,
}) => {
  const { language } = useLanguage();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userType = localStorage.getItem("userType");
  const pageTranslations = translations["EventsPortal"];
  const navigate = useNavigate();
  const statusOptions = [
    {
      label: translations.usersPage.createUser.statusOptions.active[language],
      value: "Active",
    },
    {
      label:
        translations.usersPage.createUser.statusOptions.cancelled[language],
      value: "Cancelled",
    },
    {
      label: translations.usersPage.createUser.statusOptions.waiting[language],
      value: "Waiting",
    },
    {
      label:
        translations.usersPage.createUser.statusOptions.completed[language],
      value: "Completed",
    },
  ];
  const filterOptions = [
    {
      label: translations.EventsPortal.filterOptions.currentYear[language],
      value: "currentYear",
    },
    {
      label: "Archive",
      value: "previousYear",
    },
    {
      label: translations.EventsPortal.filterOptions.futureYear[language],
      value: "future",
    },
    {
      label:
        translations.usersPage.createUser.statusOptions.cancelled[language],
      value: "cancel",
    },
  ];
  const token = localStorage.getItem("token");
  // const [events, setEvents] = useState({});
  const [eventsSearch, setEventsSearch] = useState();
  const [editID, seteditID] = useState();
  // const [pageCache, setPageCache] = useState({});
  // const [searchCache, setSearchCache] = useState({});
  // const [pageNo, setPageNo] = useState(1);
  const [activeSearch, setActiveSearch] = useState(false);
  const [eventId, setEventId] = useState("");
  const [parentId, setParentId] = useState("");
  const [week, setWeek] = useState("");
  const [loading, setLoading] = useState("");
  const [filterOption, setFilterOption] = useState(filterOptions[0].value);
  // const [deleteStates, setDeleteStates] = useState();
  const [oldData, setOldData] = useState(null);
  const [brands, setBrands] = useState();
  const [parentForms, setParentForms] = useState([]);
  const [clientForms, setClientForms] = useState(false);
  const [childId, setChildId] = useState("");
  const [checkedFields, setCheckedFields] = useState([]);
  const [repOptions2, setrepOptions2] = useState([]);
  const [agents, setagents] = useState([]);
  const [managers, setmanagers] = useState([]);
  const [packageOptions, setpackageOptions] = useState([]);
  const containerRef = useRef(null);
  const topMarkerRef = useRef(null);
  const bottomMarkerRef = useRef(null);
  const [allowScrollFetch, setAllowScrollFetch] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [initialScrollDone, setInitialScrollDone] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [dataEnd, setDataEnd] = useState(false);
  const [listIds, setListIds] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [configIds, setConfigIds] = useState([]);
  const hasPrivateEventDestroy =
    userData &&
    userData.roles[0].permissions.some(
      (item) => item.name === "Private Event Destroy"
    );

  const hasCreateParentForm =
    userData &&
    userData.roles[0].permissions.some(
      (item) => item.name === "Create Parent Form"
    );

  const hasPrivateEventShow =
    userData &&
    userData.roles[0].permissions.some(
      (item) => item.name === "Private Event Index"
    );
  const hasProspectiveIndex =
    userData &&
    userData.roles[0].permissions.some(
      (item) => item.name === "Prospective Index"
    );
  const StatusCondition =
    (hasPrivateEventDestroy && !hasCreateParentForm && !hasPrivateEventShow) ||
    (!hasPrivateEventDestroy && hasCreateParentForm && !hasPrivateEventShow) ||
    (!hasPrivateEventDestroy && !hasCreateParentForm && hasPrivateEventShow);
  const StatusCondition2 =
    (hasPrivateEventDestroy && hasCreateParentForm && !hasPrivateEventShow) ||
    (!hasPrivateEventDestroy && hasCreateParentForm && hasPrivateEventShow) ||
    (!hasPrivateEventDestroy && hasCreateParentForm && hasPrivateEventShow);
  const StatusCondition3 =
    !hasPrivateEventDestroy && !hasCreateParentForm && !hasPrivateEventShow;
  const StatusCondition4 =
    !hasPrivateEventDestroy && !hasCreateParentForm && hasPrivateEventShow;

  //   number: 405,
  //   Dealer: "Val D'Or Chrysler",
  //   Representative: "Vincent Mercier",
  //   Week: 27,
  //   drive: "https://drive.google.com/drive/folders/1roc6fyoGWS34MBbzqao0Rqx78N80vAUa",
  // }, {
  //   number: 407,
  //   Dealer: "Granby Toyota",
  //   Representative: "Représentant",
  //   Week: 27,
  //   drive: "https://drive.google.com/drive/folders/1roc6fyoGWS34MBbzqao0Rqx78N80vAUa",
  // }
  const repOptions =
    users &&
    users.representatives.length > 0 &&
    users.representatives.map((user) => ({
      value: user.id,
      label: user.name,
    }));
  const dealerOptions =
    dealerships &&
    dealerships.length > 0 &&
    dealerships.map((dealer) => ({
      value: dealer.id,
      label: dealer.name,
    }));

  function formatDate(date) {
    const datesArray = date.split(" / ");
    const lastDate = datesArray[datesArray.length - 1];

    return lastDate;
  }

  const allfields = [
    // {
    //   content: "ID",
    //   name: "user_id",
    //   type: "text",
    //   filterType:"",
    //   value:""
    // },
    {
      content: translations.forms.formFields.week[language],
      name: "week",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.name[language],
      name: "nom_vp",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.quickCreationModal.dealership[language],
      name: "dealership",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.projectManager[language],
      name: "project_manager",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.sales_representative[language],
      name: "sales_rep",
      type: "dropdown",
      filterType: "",
      value: "",
    },

    {
      content: translations.forms.privateEventformFields.package[language],
      name: "package",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.privateEventformFields.startDate[language],
      name: "start_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.privateEventformFields.endDate[language],
      name: "end_date",
      type: "date",
      filterType: "",
      value: "",
    },

    // {
    //   content: translations.usersPage.createUser.status[language],
    //   name: "status",
    //   type: "text",
    // },

    {
      content: translations.forms.formFields.animator[language],
      name: "animator",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.mailing[language],
      name: "mailing",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.web[language],
      name: "web",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.banner[language],
      name: "banner",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.newsletter[language],
      name: "news_letter",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.facebook[language],
      name: "facebook",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.adwords[language],
      name: "ad_words",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.clients[language],
      name: "clients",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.inactive[language],
      name: "inactive",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.conquestSection.title[language],
      name: "conquest",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.massif[language],
      name: "massif",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.amount[language],
      name: "amount",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.confirmationAgent[language],
      name: "confirmation_agent",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.noShowAgent[language],
      name: "no_show_agent",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.carton[language],
      name: "carton",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: "SMS",
      name: "sms",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.quickCreationModal.brand[language],
      name: "brand",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.amountAnimator[language],
      name: "amount_animator",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.invoiceAnimator[language],
      name: "invoice_animator",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.commissionPaid[language],
      name: "commission_paid",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.instructions[language],
      name: "instructions",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content:
        translations.forms.privateEventformFields.promoAndRequestSection
          .comments[language],
      name: "comments",
      type: "text",
      filterType: "",
      value: "",
    },
  ];

  const textOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const weekOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
    {
      label: translations.commonFields.queryFilters.between[language],
      value: "between",
    },
  ];

  const textOptions2 = [
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const animatorOptions =
    users &&
    users.animaters.length > 0 &&
    users.animaters.map((user) => ({
      value: user.id,
      label: user.name,
    }));

  const fetchData2 = async (scrollType, week) => {
    const tbody = document.getElementById("tbody");

    try {
      // setLoading(true);
      const res = await fetch(ApiUrl + "/api/user/private-events-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          setweek: week,
          scrollType: scrollType,
          type: filterOption ? filterOption : "",
          [filterOption === "cancel" ? "cancelYear" : "previousYear"]:
            selectedYear ? selectedYear : "",
          dealership_id:
            selectedDealershipOption?.map((option) => option.value) || [],
        }),
      });
      // console.log("Get Events res: ", res);
      const data = await res.json();
      // console.log("Get Events data: ", data)
      // console.log(result)
      if (data.status === "success") {
        // const newData = data.private_events.data;
        const newData = data.private_events;

        // if (!Object.keys(newData).includes("Week 1") && scrollType === "up") {
        //   const tbody = document.getElementById('tbody');
        //   const rows = tbody.getElementsByTagName('tr');
        //   // Check if "week-top" id is already set to avoid re-execution

        //     Array.from(rows).map((row, index) => {
        //       const topweek = row.getElementsByTagName("td")[0].innerHTML;

        //       // Only set the id if it matches the top week and no id is already set
        //       if (topweek == Object.keys(events)[0].split(" ")[1]) {
        //         // const matchedRow = rows.find((item) =>  )
        //         console.log(topweek + "==>");
        //         // console.log(matchedRow,"==>");

        //         row.setAttribute('id', 'week-top');
        //       }
        //     });

        //     const row = document.getElementById("week-top");
        //     if (row) {
        //       row.scrollIntoView({ behavior: "smooth", block: "center" });
        //     }
        //   }

        // const newTotal = data.private_events?.total;

        // Update the cache with new data
        // Set state with new data

        // const weeks = Object.keys(newData).sort(
        //   (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
        // );

        // // Calculate the midpoint
        // const totalWeeks = weeks.length;
        // const middleWeekCount = totalWeeks >= 4 ? 2 : 1;
        // const midIndex1 = Math.floor(totalWeeks / 2) - Math.floor(middleWeekCount / 2);
        // const midIndex2 = midIndex1 + middleWeekCount;

        // const middleWeeks = weeks.slice(midIndex1, midIndex2);

        // // Extract data for middle two weeks
        // const events = middleWeeks.reduce((acc, week) => {
        //   if (newData[week]) {
        //     acc[week] = newData[week];
        //   }
        //   return acc;
        // }, {});

        // Set events state
        const updatedEvents = { ...events };

        // Loop through the keys of newData and update the state
        if (Object.keys(newData) && Object.keys(newData).length > 0) {
          Object.keys(newData).length > 0 &&
            Object.keys(newData).forEach((week) => {
              // If the week already exists in events
              if (updatedEvents[week]) {
                // Filter out duplicate entries based on 'id'
                const uniqueNewEntries = newData[week].filter(
                  (newEntry) =>
                    !updatedEvents[week].some(
                      (existingEntry) => existingEntry.id === newEntry.id
                    )
                );

                // Concatenate the non-duplicate entries
                updatedEvents[week] = [
                  ...updatedEvents[week],
                  ...uniqueNewEntries,
                ];
              } else {
                // If the week does not exist, add the new week and its data
                updatedEvents[week] = newData[week];
              }
            });
        }

        if (Object.keys(newData).length === 0) {
          setDataEnd(true);
        }
        const sortedUpdatedEvents =
          Object.keys(updatedEvents) &&
          Object.keys(updatedEvents).length > 0 &&
          Object.keys(updatedEvents)
            .sort(
              (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
            ) // Sort based on week number
            .reduce((sortedObj, week) => {
              sortedObj[week] = updatedEvents[week];
              return sortedObj;
            }, {});

        // Set the updated events
        setEvents(sortedUpdatedEvents);

        // setEvents(newData);
        // setEventsSearch(newData);
        // setTotal(newTotal);
        // const weekUp = weeks.slice(0, midIndex1);
        // const weekDown = weeks.slice(midIndex2);

        // // Flatten data for before and after middle weeks
        // const weekUpData = weekUp.flatMap((week) => newData[week]);
        // const weekDownData = weekDown.flatMap((week) => newData[week]);
        // setWeekRemDn(weekUpData);
        // setWeekRemDn(weekDownData);

        setDeleteStates(
          sortedUpdatedEvents &&
            Object.keys(sortedUpdatedEvents).map((key) =>
              sortedUpdatedEvents[key].map((item) => ({ [item.id]: false }))
            )
        );
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // if (pageCache[pageNo]) {
      //   // Data already in cache, no need for API call
      //   const { cachedResponse, cachedTotal } = pageCache[pageNo];
      //   // setEvents(cachedResponse);
      //   setEventsSearch(cachedResponse);
      //   setTotal(cachedTotal);
      // } else {
      try {
        setLoading(true);
        const res = await fetch(
          ApiUrl + "/api/user/private-events-index?page=" + pageNo,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("Get Events res: ", res);
        const data = await res.json();
        // console.log("Get Events data: ", data)
        // console.log(result)
        if (data.status === "success") {
          // const newData = data.private_events.data;
          const newData = data.private_events;
          const newTotal = data.private_events?.total;
          // const dealerData = data.dealerships && data.dealerships.length > 0 && data.dealerships.map((item)=> {return {label: item.name,value:item.id}});
          // setDealerships2(dealerData)

          // Set events state
          setEvents(newData);
          // setEvents(newData);
          setEventsSearch(newData);
          setTotal(newTotal);

          setrepOptions2(
            data &&
              data.data.representative.length > 0 &&
              data.data.representative.map((user) => ({
                value: user.id,
                label: user.name,
              }))
          );
          setagents(
            data &&
              data.data.agents.length > 0 &&
              data.data.agents.map((user) => ({
                value: user.id,
                label: user.name,
              }))
          );
          setmanagers(
            data &&
              data.data.projectManagers.length > 0 &&
              data.data.projectManagers.map((user) => ({
                value: user.id,
                label: user.name,
              }))
          );
          setpackageOptions(
            data &&
              data.data.packages.length > 0 &&
              data.data.packages.map((user) => ({
                value: user.id,
                label: user.name,
              }))
          );
          setPageCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));

          setDeleteStates(
            newData &&
              Object.keys(newData).map((key) =>
                newData[key].map((item) => ({ [item.id]: false }))
              )
          );

          autoScrollMid();
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error("Something went wrong");
      } finally {
      }
      // }
    };
    if (!activeSearch && token && selectedDealershipOption?.length === 0) {
      fetchData();
    }
    const userData = JSON.parse(localStorage.getItem("userData"));
    setIsDarkMode(userData.theme_mode !== "light" ? true : false);
    // }, [pageNo, pageCache, events, eventTrigger]);
  }, []);

  useEffect(() => {
    if (eventTrigger) {
      const newData = eventTrigger.data;
      const newTotal = eventTrigger.total;

      // Update the cache with new data
      setPageCache((prevCache) => ({
        ...prevCache,
        [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
      }));
      // Set state with new data
      setEvents(newData);
      setEventsSearch(newData);
      setTotal(newTotal);
    }
  }, [eventTrigger]);

  const SCROLL_TRIGGER_OFFSET = 1000; // Pixels before the top or bottom marker to trigger the fetch
  // const SCROLL_THRESHOLD_PERCENT = 0.1;

  const fetchDataOnScroll = async (direction) => {
    if (isFetching || !allowScrollFetch || dataEnd) return; // Prevent fetch if already fetching or scroll fetching is disabled

    setIsFetching(true);
    try {
      if (direction === "up") {
        console.log("Fetching data for upper weeks.");
        // containerRef.current.style.overflowY="hidden"

        await fetchData2("up", Object.keys(events)[0].split(" ")[1]); // Call API for upper weeks
        // containerRef.current.style.overflowY="auto"
        // const row = document.getElementById('week-top');

        // if (row) {
        //   row.removeAttribute('id');
        // }
      } else if (direction === "down") {
        console.log("Fetching data for lower weeks.");
        await fetchData2(
          "down",
          Object.keys(events)[Object.keys(events).length - 1].split(" ")[1]
        ); // Call API for lower weeks
      }
    } finally {
      setIsFetching(false);
    }
  };

  // Memoize the debounced function to prevent unnecessary re-creation
  const debouncedHandleScroll = useMemo(
    () =>
      debounce(() => {
        if (!initialScrollDone || !allowScrollFetch) return;

        const container = containerRef.current;
        const topMarker = topMarkerRef.current;
        const bottomMarker = bottomMarkerRef.current;
        console.log(container && container.scrollTop && bottomMarker, "=-=>");

        if (container && container.scrollTop && bottomMarker) {
          const containerRect = container.getBoundingClientRect();
          const topMarkerRect = topMarker.getBoundingClientRect();
          const bottomMarkerRect = bottomMarker.getBoundingClientRect();
          const currentScrollTop = container.scrollTop;

          // Fetch data if within offset range
          //         if (distanceToTopMarker > -1000) {

          // // Example: Add id to the first row (week 33)
          //           fetchDataOnScroll("up");
          //         } else

          // if (currentScrollTop !== prevScrollPos) {
          //   console.log(currentScrollTop !== prevScrollPos , "==>");

          // Calculate distance to top and bottom markers
          // const distanceToTopMarker = topMarkerRect.top - containerRect.top;
          const distanceToBottomMarker =
            bottomMarkerRect.bottom - containerRect.bottom;
          // console.log(distanceToBottomMarker,"==>");

          // Fetch data if within offset range (for vertical scroll)
          if (distanceToBottomMarker < SCROLL_TRIGGER_OFFSET) {
            fetchDataOnScroll("down");
          }

          // Update the previous scroll position
          //   setPrevScrollPos(currentScrollTop);
          // }
        }
      }, 300), // Debounce time (adjust as needed)
    [initialScrollDone, allowScrollFetch, dataEnd, events] // Dependency array includes scroll flag
  );

  // Use `useCallback` to wrap the debounced function
  const handleScroll = useCallback(debouncedHandleScroll, [
    debouncedHandleScroll,
  ]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  // console.log(topMarkerRef.current?.getBoundingClientRect().top - containerRef.current?.getBoundingClientRect().top  );

  const autoScrollMid = () => {
    const container = containerRef.current;

    if (container) {
      // const middleScrollPos =
      //   (container.scrollHeight - container.clientHeight) / 2;
      const bottomScrollPos = container.scrollHeight - container.clientHeight;

      container.scrollTo({
        top: bottomScrollPos - 300,
        behavior: "smooth",
      });

      setPrevScrollPos(bottomScrollPos); // Set initial scroll position

      // Set initial scroll as done after a delay to ensure smooth scroll completion
      setTimeout(() => {
        setInitialScrollDone(true);
      }, 1000); // Adjust the timeout to match the smooth scroll duration if necessary
    }
  };

  const handleDelete = async (id, index, accordionIndex) => {
    const page =
      events.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
    const searchParam = key ? "&search_parameter=" + key : "";
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl +
          "/api/user/private-event-destroy/" +
          id +
          "/" +
          filterOption +
          "?page=" +
          page,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        toast.success("Event deleted successful");
        // const newData = data.private_events.data;
        // const newData = data.private_events;
        // const newTotal = data.private_events.total;
        const updatedEvents = { ...events };

        for (let week in updatedEvents) {
          // Filter and map the events in the current week
          updatedEvents[week] = updatedEvents[week]
            .filter((event) => event.id !== id)
            .map((event) => event);

          // If the filtered array is empty, delete the week
        }

        const sortedUpdatedEvents =
          Object.keys(updatedEvents) &&
          Object.keys(updatedEvents).length > 0 &&
          Object.keys(updatedEvents)
            .sort(
              (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
            ) // Sort based on week number
            .reduce((sortedObj, week) => {
              sortedObj[week] = updatedEvents[week];
              return sortedObj;
            }, {});

        setEvents(sortedUpdatedEvents);
        // setTotal(newTotal);
        // setPageCache({
        //   [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        // });
        // if (key) {
        //   setSearchCache({
        //     [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        //   });
        // }

        // if (activeSearch) {
        //   setPageNo(
        //     events && Object.keys(events).length > 1
        //       ? pageNo
        //       : pageNo - 1 > 0
        //       ? pageNo - 1
        //       : pageNo
        //   );
        // }

        // Update delete state for the current item
        const newDeleteStates = [...deleteStates];
        newDeleteStates[accordionIndex][index][id] = false;
        setDeleteStates(newDeleteStates);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleCopy = async () => {
    const page =
      events.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
    const searchParam = key ? "&search_parameter=" + key : "";
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl + "/api/user/copy-parentform?page=" + page + "" + searchParam,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            [clientForms ? "child_id" : "parent_id"]: parentId,
            // child_id: childId,
            event_id: eventId,
            // week: week,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        setDisplayComponent("edit-form");
        window.history.pushState(
          {
            id: "gallery",
            randomData: window.Math.random(),
          },
          "title",
          `/event-portal?page=edit-form&event_id=${eventId}&form_id=${data.event_url}`
        );
        // toast.success("Event copied successfully");
        // setDisplayComponent("edit-event");
        // window.history.pushState(
        //   {
        //     id: "gallery",
        //     randomData: window.Math.random(),
        //   },
        //   "title",
        //   "/event-portal?page=edit-event&parent_id=" + data.event_url
        // );
        // const newData = data.event_list.data;
        // const newTotal = data.event_list.total;
        // setEvents(newData);
        // setTotal(newTotal);
        // if (key) {
        //   setSearchCache({
        //     [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        //   });
        // } else {
        //   setPageCache({
        //     [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        //   });
        // }
        // if (activeSearch) {
        //   setPageNo(
        //     events && Object.keys(events).length > 1
        //       ? pageNo
        //       : pageNo - 1 > 0
        //       ? pageNo - 1
        //       : pageNo
        //   );
        // }
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleSearch = async (pageNo, initial) => {
    if (!initial && searchCache[pageNo]) {
      // Data already in cache, no need for API call
      const { cachedResponse, cachedTotal } = searchCache[pageNo];
      console.log("here0");

      setEvents(cachedResponse);
      setDeleteStates(
        cachedResponse &&
          Object.keys(cachedResponse).map((key) =>
            cachedResponse[key].map((item) => ({ [item.id]: false }))
          )
      );
      setTotal(cachedTotal);
    } else {
      // setFilterLoading(true);
      // setGetLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/private-events-index/" + key + "?page=" + pageNo,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          setActiveSearch(true);

          // const newData = data.private_events.data;
          const newData = data.private_events;
          const newTotal = data.private_events.total;
          // Update the cache with new data

          setSearchCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));
          // Set state with new data
          setEvents(newData);
          setEventsSearch(newData);
          setTotal(newTotal);
          setDeleteStates(
            newData &&
              Object.keys(newData).map((key) =>
                newData[key].map((item) => ({ [item.id]: false }))
              )
          );
          // if (pageNo === 1) toast.success("Search successful");
          // setFilterLoading(false);
          // setGetLoading(false);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error("Something went wrong");
        // setFilterLoading(false);
        // setGetLoading(false);
      }
    }
  };

  const filterEvents = async (filterData) => {
    setIsLoading(true);
    setAllowScrollFetch(false);
    try {
      const res = await fetch(ApiUrl + "/api/user/private-events-index/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterData),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);

        // const newData = data.private_events.data;
        const newData = data.private_events;
        const newTotal = data.private_events.total;
        containerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        const sortedUpdatedEvents =
          Object.keys(newData) &&
          Object.keys(newData).length > 0 &&
          Object.keys(newData)
            .sort(
              (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
            ) // Sort based on week number
            .reduce((sortedObj, week) => {
              sortedObj[week] = newData[week];
              return sortedObj;
            }, {});

        setEvents(sortedUpdatedEvents);
        setTotal(newTotal);

        if (key) {
          setSearchCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
        } else {
          setPageCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
          setDeleteStates(
            sortedUpdatedEvents &&
              Object.keys(sortedUpdatedEvents).map((key) =>
                sortedUpdatedEvents[key].map((item) => ({ [item.id]: false }))
              )
          );
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
      setAllowScrollFetch(true);
    }
  };

  const getFilteredEvents = async (filterPost) => {
    // setIsLoading(true);
    setAllowScrollFetch(false);
    try {
      const res = await fetch(ApiUrl + "/api/user/private-events-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterPost),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);

        // const newData = data.private_events.data;
        const newData = data.private_events;
        const newTotal = data.private_events.total;
        const sortedUpdatedEvents =
          Object.keys(newData) &&
          Object.keys(newData).length > 0 &&
          Object.keys(newData)
            .sort(
              (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
            ) // Sort based on week number
            .reduce((sortedObj, week) => {
              sortedObj[week] = newData[week];
              return sortedObj;
            }, {});

        setEvents(sortedUpdatedEvents);
        setTotal(newTotal);

        if (key) {
          setSearchCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
        } else {
          setPageCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
          setDeleteStates(
            sortedUpdatedEvents &&
              Object.keys(sortedUpdatedEvents).map((key) =>
                sortedUpdatedEvents[key].map((item) => ({ [item.id]: false }))
              )
          );
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
      setAllowScrollFetch(true);
    }
  };

  const handlePageChange = (page) => {
    if (activeSearch) handleSearch(page);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageCache((prevCache) => ({
        ...prevCache,
        pageNo: pageNo,
      }));
      setPageNo(1);
      setSearchCache({});
      // handleSearch(1, true);
    }, 500);

    return () => clearTimeout(timer);
  }, [key]);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-brands", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          setBrands(data.list_of_brands);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
        // Handle error or show a toast if needed
      }
    };
    const getFiltersData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-fillter", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const filteredFields = allfields.filter(
            (field) => data.fillter[field.name] === 1
          );

          setCheckedFields(filteredFields);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
        // Handle error or show a toast if needed
      }
    };
    const getListIds = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(ApiUrl + "/api/user/list-ids", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const lists =
            data.list_ids.lists &&
            data.list_ids.lists.length > 0 &&
            data.list_ids.lists.map((list) => {
              return { label: list.list_name, value: list.list_id };
            });
          const configss =
            data.config_ids.lists &&
            data.config_ids.lists.length > 0 &&
            data.config_ids.lists.map((list) => {
              return {
                label: list.calendar_config_name,
                value: list.calendar_config_id,
              };
            });

          setListIds(lists);
          setConfigIds(configss);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong2");
      } finally {
      }
    };
    getBrands();
    getFiltersData();
    // getListIds();
  }, [token]);

  const changeDropdowns = async (bodyData) => {
    setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/change-status", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        const updatedEvents = { ...events };
        for (let week in updatedEvents) {
          updatedEvents[week] = updatedEvents[week].map((event) =>
            event.id === bodyData.id
              ? {
                  ...event,
                  [Object.keys(bodyData)[1]]:
                    bodyData[Object.keys(bodyData)[1]],
                }
              : event
          );
        }

        setEvents(updatedEvents);
        setDeleteStates(
          updatedEvents &&
            Object.keys(updatedEvents).map((key) =>
              updatedEvents[key].map((item) => ({ [item.id]: false }))
            )
        );

        if (key) {
          setSearchCache({
            [pageNo]: {
              cachedResponse: updatedEvents,
              cachedTotal: events.total,
            },
          });
        } else {
          setPageCache({
            [pageNo]: {
              cachedResponse: updatedEvents,
              cachedTotal: events.total,
            },
          });
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };
  const checkParent = async (event_Id) => {
    setIsLoading(true);
    try {
      const res = await fetch(
        ApiUrl + "/api/user/check-parentform/" + event_Id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        // toast.success(data.message);
        // setDisplayComponent("edit-form");
        // window.history.pushState(
        //   {
        //     id: "gallery",
        //     randomData: window.Math.random(),
        //   },
        //   "title",
        //   "/event-portal?page=edit-form&parent_id=" + event_Id
        // );
        if (data?.parentForm && data?.parentForm.length > 0) {
          const options =
            data?.parentForm &&
            data?.parentForm.length > 0 &&
            data.parentForm.map((item) => {
              return { label: item?.indexed_date, value: item.id };
            });
          setParentForms(options);
        }
        if (data?.clientForm && data?.clientForm.length > 0) {
          const options =
            data?.clientForm &&
            data?.clientForm.length > 0 &&
            data.clientForm.map((item) => {
              return { label: item?.indexed_date, value: item.id };
            });
          setParentForms(options);
          setClientForms(true);
        }

        setEventId(event_Id);
        // setParentId(data?.parentForm);
        var createModal = new window.bootstrap.Modal(
          document.getElementById("createForm")
        );
        createModal.show();
      } else {
        // toast.error(data.message);
        setDisplayComponent("create-event");

        window.history.pushState(
          {
            id: "gallery",
            randomData: window.Math.random(),
          },
          "title",
          "/event-portal?page=create-event&parent_id=" +
            event_Id +
            "&event-navigate=true&getFormData=false"
        );
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };
  const getDealershipData = async (dealership_Id) => {
    setIsLoading(true);
    try {
      const res = await fetch(
        ApiUrl + "/api/user/dealer-ship-detail/" + dealership_Id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        // toast.success(data.message);
        // setDisplayComponent("edit-form");
        // window.history.pushState(
        //   {
        //     id: "gallery",
        //     randomData: window.Math.random(),
        //   },
        //   "title",
        //   "/event-portal?page=edit-form&parent_id=" + event_Id
        // );
        seteditID(dealership_Id);
        setOldData(data.dealer_ship);
        var createModal = new window.bootstrap.Modal(
          document.getElementById("AddDealerModal")
        );
        createModal.show();
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };

  // Debounce function
  // const debounce2 = (func, delay) => {
  //   let timeoutId;
  //   return (...args) => {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => func(...args), delay);
  //   };
  // };

  // Create a debounced version of handleChange function

  // const handleChange = (
  //   e,
  //   data,
  //   checkedFields,
  //   setCheckedFields,
  //   getFilteredEvents
  // ) => {
  //   setCheckedFields(
  //     checkedFields.map((field) =>
  //       field.name === data.name ? { ...field, value: e.target.value } : field
  //     )
  //   );

  //   if (data.filterType) {
  //     const Data = { [data.name]: [e.target.value, data.filterType] };
  //     const filteredFields = checkedFields.filter(
  //       (field) => field?.value !== "" || field?.value.length > 0
  //     );

  //     // Create a new object from the filtered fields
  //     const filteredData = filteredFields.reduce((acc, field) => {
  //       acc[field.type === "dropdown" ? field.name + "_id" : field.name] =
  //         field.type === "text"
  //           ? [field.value, field.filterType]
  //           : [field.value];
  //       return acc;
  //     }, {});

  //     // Merge the filteredData object with the initial Data object
  //     const mergedData = {
  //       ...filteredData,
  //       ...Data,
  //     };
  //     if (filterOption) {
  //       mergedData.type = filterOption;
  //     }
  //     getFilteredEvents(mergedData);
  //   }
  // };

  // const debouncedHandleChange = debounce2(
  //   (e, data, checkedFields, setCheckedFields, getFilteredEvents) => {
  //     handleChange(e, data, checkedFields, setCheckedFields, getFilteredEvents);
  //   },
  //   500
  // );

  return (
    <div className="Private Event Index">
      {!loading ? (
        <>
          <CreateFormModal
            isDarkMode={isDarkMode}
            handleCopy={handleCopy}
            parentId={parentId}
            setParentId={setParentId}
            eventId={eventId}
            setEventId={setEventId}
            setDisplayComponent={setDisplayComponent}
            parentForms={parentForms}
            setChildId={setChildId}
          />
          <EditEventModal
            dealerships={dealerships}
            eventNavigate={true}
            editID={editID}
            isEdit={true}
            oldData={oldData}
            setOldData={setOldData}
            setIsLoading={setIsLoading}
            brands={brands}
            isDarkMode={isDarkMode}
            repOptions={repOptions}
          />
          <FilterBar
            isDarkMode={isDarkMode}
            allfields={allfields}
            checkedFields={checkedFields}
            setCheckedFields={setCheckedFields}
            textOptions={textOptions}
            textOptions2={textOptions2}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            dealerOptions={dealerOptions}
            repOptions2={repOptions2}
            managers={managers}
            packageOptions={packageOptions}
            animatorOptions={animatorOptions}
            getFilteredEvents={getFilteredEvents}
            weekOptions={weekOptions}
          />
          <div className="container maincont">
            <div id="app" className="eventsContainer">
              <div className="row align-items-center justify-content-between">
                <div className="col-4 d-flex gap-3 align-items-center">
                  <div>
                    <h2
                      id="h1"
                      className={`col-1${isDarkMode ? "darkthead col-1" : ""}`}
                    >
                      {pageTranslations["title"].events[language]}
                    </h2>
                  </div>
                </div>
                <div className="col-8 gap-1 d-flex align-items-center justify-content-end">
                  {userData &&
                    (userData.roles[0].permissions.some(
                      (item) => item.name === "Event Report"
                    ) ||
                      userData.roles[0].permissions.some(
                        (item) => item.name === "Contract And Billing"
                      )) && (
                      <div style={{ width: "250px" }}>
                        <Select
                          className="selectpicker w-100"
                          aria-labelledby="dealership-name-quick"
                          // isClearable
                          options={[
                            userData &&
                              userData.roles[0].permissions.some(
                                (item) => item.name === "Event Report"
                              ) && {
                                label:
                                  translations.topAndsidebar.eventReports[
                                    language
                                  ],
                                value: "view-event-report",
                              },
                            userData &&
                              userData.roles[0].permissions.some(
                                (item) => item.name === "Contract And Billing"
                              ) && {
                                label:
                                  translations.topAndsidebar.billingReport[
                                    language
                                  ],
                                value: "view-billing-report",
                              },
                            !window.location.href.includes("page=events") && {
                              label:
                                translations.EventsPortal.title.events[
                                  language
                                ],
                              value: "events",
                            },
                          ].filter(Boolean)}
                          placeholder={
                            translations["dropdown"].select[language]
                          }
                          isSearchable={false}
                          value={{
                            label:
                              translations.EventsPortal.title.events[language],
                            value: "events",
                          }}
                          // value={
                          //   (filterOptions &&
                          //     filterOptions.length > 0 &&
                          //     filterOptions.find(
                          //       (option) => option.value === filterOption
                          //     )) || {
                          //     value: "",
                          //     label: translations["dropdown"].select[language],
                          //   }
                          // }
                          onChange={(selectedOption) => {
                            setDisplayComponent((prev) => {
                              setPrevDisplay(prev);
                              return `${selectedOption.value}`;
                            });
                            window.history.pushState(
                              {
                                id: "gallery",
                                randomData: window.Math.random(),
                              },
                              "title",
                              `/event-portal?page=${selectedOption.value}`
                            );
                          }}
                          // maxMenuHeight={"130px"}
                          styles={{
                            input: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: isDarkMode
                                ? "transparent"
                                : "white",
                              border: state.isFocused
                                ? "1px solid #80bdff"
                                : isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6", // Adjust the border color for focus
                              boxShadow: state.isFocused
                                ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                : "none", // Bootstrap's box shadow for focus
                              borderRadius: ".45rem",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none", // Hide the indicator separator
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                              color: isDarkMode ? "#ffffff" : "#868686",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: isDarkMode ? "#1f2023" : "white",
                              margin: "0", // Remove default margin
                              zIndex: "9999",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                              // Additional styles for placeholder
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: state.isHovered
                                ? "#80bdff"
                                : isDarkMode
                                ? "#1f2023"
                                : "white",
                              ":hover": {
                                backgroundColor: isDarkMode
                                  ? "#34DBB4"
                                  : "#34DBB4",
                              },
                            }),
                          }}
                        />
                      </div>
                    )}
                  {userType && internalUsers.includes(userType) && (
                    <div style={{ width: "250px" }}>
                      <Select
                        className="selectpicker w-100"
                        aria-labelledby="dealership-name-quick"
                        // isClearable
                        options={filterOptions}
                        placeholder={translations["dropdown"].select[language]}
                        isSearchable={false}
                        value={
                          (filterOptions &&
                            filterOptions.length > 0 &&
                            filterOptions.find(
                              (option) => option.value === filterOption
                            )) || {
                            value: "",
                            label: translations["dropdown"].select[language],
                          }
                        }
                        onChange={(selectedOption) => {
                          setFilterOption(selectedOption.value);
                          setSelectedYear("");
                          const filteredFields = checkedFields.filter(
                            (field) =>
                              field?.value !== "" || field?.value.length > 0
                          );

                          if (filteredFields && filteredFields.length > 0) {
                            const filteredData = filteredFields.reduce(
                              (acc, field) => {
                                acc[
                                  field.type === "dropdown"
                                    ? field.name + "_id"
                                    : field.name
                                ] =
                                  field.type === "text"
                                    ? [field.value, field.filterType]
                                    : [field.value];
                                return acc;
                              },
                              {}
                            );

                            // Merge the filteredData object with the initial Data object
                            const mergedData = {
                              ...filteredData,
                            };

                            mergedData.type = selectedOption.value;

                            getFilteredEvents(mergedData);
                          }

                          // Create a new object from the filtered fields
                          else {
                            const data = { type: selectedOption.value };
                            filterEvents(data);
                          }
                        }}
                        // maxMenuHeight={"130px"}
                        styles={{
                          input: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                          }),
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: isDarkMode
                              ? "transparent"
                              : "white",
                            border: state.isFocused
                              ? "1px solid #80bdff"
                              : isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6", // Adjust the border color for focus
                            boxShadow: state.isFocused
                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                              : "none", // Bootstrap's box shadow for focus
                            borderRadius: ".45rem",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none", // Hide the indicator separator
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                            color: isDarkMode ? "#ffffff" : "#868686",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                            backgroundColor: isDarkMode ? "#1f2023" : "white",
                            margin: "0", // Remove default margin
                            zIndex: "9999",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                            // Additional styles for placeholder
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                            backgroundColor: state.isHovered
                              ? "#80bdff"
                              : isDarkMode
                              ? "#1f2023"
                              : "white",
                            ":hover": {
                              backgroundColor: isDarkMode
                                ? "#34DBB4"
                                : "#34DBB4",
                            },
                          }),
                        }}
                      />
                    </div>
                  )}
                  {(filterOption === "cancel" ||
                    filterOption === "previousYear") && (
                    <div style={{ width: "250px" }}>
                      <Select
                        className="selectpicker w-100"
                        aria-labelledby="dealership-name-quick"
                        // isClearable
                        options={
                          filterOption === "previousYear"
                            ? Array.from(
                                { length: 5 },
                                (_, i) => new Date().getFullYear() - 1 - i
                              ).map((item) => {
                                return { label: item, value: item };
                              })
                            : Array.from(
                                { length: 5 },
                                (_, i) => new Date().getFullYear() - i
                              ).map((item) => {
                                return { label: item, value: item };
                              })
                        }
                        placeholder={translations["dropdown"].select[language]}
                        isSearchable={false}
                        value={
                          // Determine the array of year options based on the filterOption
                          (filterOption === "previousYear"
                            ? Array.from({ length: 5 }, (_, i) => {
                                const year = new Date().getFullYear() - 1 - i;
                                return { label: year, value: year };
                              })
                            : Array.from({ length: 5 }, (_, i) => {
                                const year = new Date().getFullYear() - i;
                                return { label: year, value: year };
                              })
                          ).find((option) => option.value === selectedYear) || {
                            value: "",
                            label: translations["dropdown"].select[language],
                          }
                        }
                        onChange={(selectedOption) => {
                          setSelectedYear(selectedOption.value);
                          const filteredFields = checkedFields.filter(
                            (field) =>
                              field?.value !== "" || field?.value.length > 0
                          );

                          if (filteredFields && filteredFields.length > 0) {
                            const filteredData = filteredFields.reduce(
                              (acc, field) => {
                                acc[
                                  field.type === "dropdown"
                                    ? field.name + "_id"
                                    : field.name
                                ] =
                                  field.type === "text"
                                    ? [field.value, field.filterType]
                                    : [field.value];
                                return acc;
                              },
                              {}
                            );

                            // Merge the filteredData object with the initial Data object
                            const mergedData = {
                              ...filteredData,
                            };
                            if (filterOption === "cancel")
                              mergedData.cancelYear = selectedOption.value;
                            else mergedData.previousYear = selectedOption.value;

                            getFilteredEvents(mergedData);
                          }

                          // Create a new object from the filtered fields
                          else {
                            const data = {
                              type: filterOption,
                              [filterOption === "cancel"
                                ? "cancelYear"
                                : "previousYear"]: selectedOption.value,
                            };
                            filterEvents(data);
                          }
                        }}
                        // maxMenuHeight={"130px"}
                        styles={{
                          input: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                          }),
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: isDarkMode
                              ? "transparent"
                              : "white",
                            border: state.isFocused
                              ? "1px solid #80bdff"
                              : isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6", // Adjust the border color for focus
                            boxShadow: state.isFocused
                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                              : "none", // Bootstrap's box shadow for focus
                            borderRadius: ".45rem",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none", // Hide the indicator separator
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                            color: isDarkMode ? "#ffffff" : "#868686",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                            backgroundColor: isDarkMode ? "#1f2023" : "white",
                            margin: "0", // Remove default margin
                            zIndex: "9999",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                            // Additional styles for placeholder
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                            backgroundColor: state.isHovered
                              ? "#80bdff"
                              : isDarkMode
                              ? "#1f2023"
                              : "white",
                            ":hover": {
                              backgroundColor: isDarkMode
                                ? "#34DBB4"
                                : "#34DBB4",
                            },
                          }),
                        }}
                      />
                    </div>
                  )}
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      id=""
                      type="button"
                      className="btn admin-btn btn-primary"
                      data-bs-target="#filtersidebar"
                      data-bs-toggle="offcanvas"
                      aria-bs-controls="offcanvasmain"
                    >
                      <i className="bi bi-search"></i>{" "}
                    </button>
                  </div>
                  {userData &&
                    userData.roles[0].permissions.some(
                      (item) => item.name === "Private Event Store"
                    ) && (
                      <div className="d-grid gap-2">
                        <button
                          type="button"
                          className="btn admin-btn btn-primary d-flex align-items-center gap-1"
                          // id="create-form-link"
                          // fdprocessedid="v57r2v"
                          // data-bs-dismiss="offcanvas"
                          onClick={() => {
                            setDisplayComponent((prev) => {
                              // setPrevDisplay(prev);
                              return "create-privateEvent";
                            });
                            window.history.pushState(
                              {
                                id: "gallery",
                                randomData: window.Math.random(),
                              },
                              "title",
                              "/event-portal?page=create-privateEvent"
                            );
                          }}
                        >
                          <i className="bi bi-calendar2-event"></i>{" "}
                          {
                            translations.topAndsidebar.createPrivateEvent[
                              language
                            ]
                          }
                        </button>
                      </div>
                    )}
                  {userData &&
                    userData.roles[0].permissions.some(
                      (item) => item.name === "Private Event Store"
                    ) && (
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          id="quickAdd"
                          type="submit"
                          className="btn admin-btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#modalQuickAdd"
                          fdprocessedid="2rud2c"
                        >
                          <i className="bi bi-speedometer"></i>{" "}
                          {translations.topAndsidebar.quickCreation[language]}
                        </button>
                      </div>
                    )}
                </div>
              </div>
              <div className="headersec mt-3">
                {/* <div className="mb-2 d-flex">
                  <input
                    style={{
                      borderColor: "#dee2e6",
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                    }}
                    type="text"
                    autocomplete="off"
                    className={`form-control ${isDarkMode ? "dark-mode" : ""}`}
                    id="searchInput"
                    placeholder={pageTranslations["searchbar"].search[language]}
                    value={key}
                    onChange={(e) => {
                      setKey(e.target.value);
                      // const filteredData = events.filter((event) =>
                      //   event.dealershipName
                      //     .toLowerCase()
                      //     .startsWith(e.target.value.toLowerCase())
                      // );
                      // setEvents(filteredData);
                    }}
                  />
                  {activeSearch && (
                    <button
                      className="bi bi-x-lg btn searchBtn"
                      style={{ padding: "6px 8px" }}
                      onClick={handleClearFilters}
                    ></button>
                  )}
                  <button
                    className="bi bi-search btn searchBtn"
                    style={{ padding: "6px 8px" }}
                    onClick={() => {
                      setPageCache((prevCache) => ({
                        ...prevCache,
                        pageNo: pageNo,
                      }));
                      setPageNo(1);
                      setSearchCache({});
                      if (key) {
                        handleSearch(1, true);
                      }
                    }}
                  />
                </div> */}
                <div className="my-2"></div>
              </div>
              <>
                {/* {checkedFields && checkedFields.length > 0 && (
                <div className="d-flex gap-1 flex-wrap mx-1">
                  {checkedFields.map((data, index) => (
                    <div
                      className="my-2"
                      style={{ width: "", fontSize: "14px" }}
                    >
                      <label class="form-label fw-bold">{data.content}</label>
                      {data.type === "text" && (
                        <div
                          className="d-flex gap-1 align-items-center mx-1"
                          style={{ width: "200px" }}
                        >
                          <Select
                            name={data.name}
                            className="selectpicker"
                            aria-labelledby="dealership-name-quick"
                            placeholder={
                              translations["dropdown"].select[language]
                            }
                            isSearchable={false}
                            options={
                              data.name === "week" ? textOptions2 : textOptions
                            }
                            onChange={(selectedOption) => {
                              // Update the type in the object with the selected type
                              setCheckedFields(
                                checkedFields.map((field) =>
                                  field.name === data.name
                                    ? {
                                        ...field,
                                        filterType: selectedOption.value,
                                      }
                                    : field
                                )
                              );
                              if (data.value) {
                                const Data = {
                                  [data.name]: [
                                    data.value,
                                    selectedOption.value,
                                  ],
                                };
                                const filteredFields = checkedFields.filter(
                                  (field) =>
                                    field?.value !== "" ||
                                    field?.value.length > 0
                                );

                                // Create a new object from the filtered fields
                                const filteredData = filteredFields.reduce(
                                  (acc, field) => {
                                    acc[
                                      field.type === "dropdown"
                                        ? field.name + "_id"
                                        : field.name
                                    ] =
                                      field.type === "text"
                                        ? [field.value, field.filterType]
                                        : [field.value];
                                    return acc;
                                  },
                                  {}
                                );

                                // Merge the filteredData object with the initial Data object
                                const mergedData = {
                                  ...filteredData,
                                  ...Data,
                                };
                                if (filterOption) {
                                  mergedData.type = filterOption;
                                }
                                getFilteredEvents(mergedData);
                              }
                            }}
                            styles={{
                              input: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                              }),
                              control: (provided, state) => ({
                                ...provided,
                                width: "100px",
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "white",
                                border: state.isFocused
                                  ? "1px solid #80bdff"
                                  : isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                                boxShadow: state.isFocused
                                  ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                  : "none",
                                borderRadius: ".45rem",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0.18rem 0.55rem",
                                width: "100%",
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                padding: "0.375rem 0.75rem",
                                color: isDarkMode ? "#ffffff" : "#868686",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                margin: "0",
                                width: "100%",
                                zIndex: "999",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "#333333",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: state.isHovered
                                  ? "#80bdff"
                                  : isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                ":hover": {
                                  backgroundColor: "#34DBB4",
                                },
                              }),
                            }}
                          />
                          <input
                            type="text"
                            name={data.name}
                            className="form-control"
                            onChange={(e) =>
                              debouncedHandleChange(
                                e,
                                data,
                                checkedFields,
                                setCheckedFields,
                                getFilteredEvents
                              )
                            }
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                              width: "100px",
                            }}
                          />
                        </div>
                      )}
                      {data.type === "date" && (
                        <div className="my-2 mx-1" style={{ width: "130px" }}>
                          <input
                            type="date"
                            name={data.name}
                            className={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            onChange={(e) => {
                              // Update the value in the object with the input text
                              setCheckedFields(
                                checkedFields.map((field) =>
                                  field.name === data.name
                                    ? { ...field, value: e.target.value }
                                    : field
                                )
                              );

                              const Data = { [data.name]: [e.target.value] };
                              const filteredFields = checkedFields.filter(
                                (field) =>
                                  field?.value !== "" || field?.value.length > 0
                              );

                              // Create a new object from the filtered fields
                              const filteredData = filteredFields.reduce(
                                (acc, field) => {
                                  acc[
                                    field.type === "dropdown"
                                      ? field.name + "_id"
                                      : field.name
                                  ] =
                                    field.type === "text"
                                      ? [field.value, field.filterType]
                                      : [field.value];
                                  return acc;
                                },
                                {}
                              );

                              // Merge the filteredData object with the initial Data object
                              const mergedData = {
                                ...filteredData,
                                ...Data,
                              };
                              if (filterOption) {
                                mergedData.type = filterOption;
                              }
                              getFilteredEvents(mergedData);
                            }}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                              marginTop: "-8px",
                            }}
                          />
                        </div>
                      )}
                      {data.type === "dropdown" && (
                        <div className="" style={{ maxWidth: "" }}>
                          <Select
                            name={data.name}
                            className="selectpicker w-100"
                            aria-labelledby="dealership-name-quick"
                            placeholder={
                              translations["dropdown"].select[language]
                            }
                            isMulti={true}
                            isClearable={false}
                            isSearchable={true}
                            options={
                              data.name === "dealership"
                                ? dealerOptions
                                : data.name === "project_manager"
                                ? managers
                                : data.name === "sales_rep"
                                ? repOptions2
                                : data.name === "package"
                                ? packageOptions
                                : animatorOptions
                            }
                            maxMenuHeight={"120px"} // Set a max height for the dropdown menu
                            onChange={(selectedOption) => {
                              const selectedIds = selectedOption.map(
                                (data) => data.value
                              );
                              setCheckedFields(
                                checkedFields.map((field) =>
                                  field.name === data.name
                                    ? { ...field, value: selectedIds }
                                    : field
                                )
                              );

                              const Data = { [data.name + "_id"]: selectedIds };
                              const filteredFields = checkedFields.filter(
                                (field) =>
                                  field?.value !== "" || field?.value.length > 0
                              );

                              // Create a new object from the filtered fields
                              const filteredData = filteredFields.reduce(
                                (acc, field) => {
                                  acc[
                                    field.type === "dropdown"
                                      ? field.name + "_id"
                                      : field.name
                                  ] =
                                    field.type === "text"
                                      ? [field.value, field.filterType]
                                      : [field.value];
                                  return acc;
                                },
                                {}
                              );

                              // Merge the filteredData object with the initial Data object
                              const mergedData = {
                                ...filteredData,
                                ...Data,
                              };
                              if (filterOption) {
                                mergedData.type = filterOption;
                              }
                              getFilteredEvents(mergedData);
                            }}
                            styles={{
                              input: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                              }),
                              control: (provided, state) => ({
                                ...provided,
                                width: "100%",
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "white",
                                border: state.isFocused
                                  ? "1px solid #80bdff"
                                  : isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                                boxShadow: state.isFocused
                                  ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                  : "none",
                                borderRadius: ".45rem",
                                maxHeight: "38px", // Constrain the height of the control
                                overflowY: "hidden", // Hide overflow
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0.18rem 0.55rem",
                                width: "100%",
                                overflow: "hidden", // Hide overflow for the container
                              }),
                              multiValue: (provided) => ({
                                ...provided,
                                maxWidth: "calc(100% - 40px)", // Adjust as needed
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Add ellipsis for overflowing text
                              }),
                              multiValueLabel: (provided) => ({
                                ...provided,
                                whiteSpace: "nowrap", // Prevent text from wrapping
                              }),
                              // multiValueRemove: (provided) => ({
                              //   ...provided,
                              //   cursor: 'pointer',
                              // }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                padding: "0.375rem 0.75rem",
                                color: isDarkMode ? "#ffffff" : "#868686",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                margin: "0",
                                zIndex: "999",
                                width: "100%",
                                maxHeight: "120px", // Set a max height for the dropdown menu
                                overflowY: "auto", // Add vertical scrollbar if content overflows
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "#333333",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: state.isHovered
                                  ? "#80bdff"
                                  : isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                ":hover": {
                                  backgroundColor: "#34DBB4",
                                },
                              }),
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )} */}
              </>

              <div
                className=""
                ref={containerRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflowX: "auto",
                  // maxHeight: "calc(100vh - 260px)",
                  maxHeight: "calc(100vh - 200px)",
                }}
              >
                <div ref={topMarkerRef} style={{ height: "1px" }} />
                <table className="table align-content-center">
                  <thead
                    className=""
                    style={{ position: "sticky", top: "0", zIndex: "999" }}
                  >
                    <tr>
                      <th
                        scope="col"
                        className={`${
                          isDarkMode ? "darkthead text-center" : "text-center"
                        }`}
                        style={{
                          position: "-webkit-sticky",
                          position: "sticky",
                          left: "0px",
                          width: "57px",
                        }}
                      >
                        {translations.forms.formFields.week[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{
                          position: "-webkit-sticky",
                          position: "sticky",
                          left: language === "fr" ? "80px" : "58px",
                          width: "83px",
                        }}
                      >
                        {translations.commonFields.name[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        {translations.quickCreationModal.dealership[language]}
                      </th>
                      {userType && internalUsers.includes(userType) && (
                        <th
                          scope="col"
                          className={`${isDarkMode ? "darkthead" : ""}`}
                        >
                          {
                            translations.forms.privateEventformFields.package[
                              language
                            ]
                          }
                        </th>
                      )}
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ minWidth: "125px" }}
                      >
                        {
                          translations.forms.privateEventformFields.startDate[
                            language
                          ]
                        }
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        {
                          translations.forms.privateEventformFields.endDate[
                            language
                          ]
                        }
                      </th>
                      {userType && internalUsers.includes(userType) && (
                        <>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                            // style={{width:"80px"}}
                          >
                            {translations.forms.formFields.clients[language]}
                          </th>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                            style={{ minWidth: "55px" }}
                          >
                            {translations.forms.formFields.inactive[language]}
                          </th>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                            // style={{ minWidth: "55px" }}
                          >
                            {translations.forms.formFields.amount[language]}
                          </th>
                        </>
                      )}
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        {translations.forms.formFields.representative[language]}
                      </th>
                      {/* <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        // style={{
                        //   position: "-webkit-sticky",
                        //   position: "sticky",
                        //   right: "386px",
                        // }}
                      >
                        {translations.createProspective.configId[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        // style={{
                        //   position: "-webkit-sticky",
                        //   position: "sticky",
                        //   right: "386px",
                        // }}
                      >
                        {translations.createProspective.listIds[language]}
                      </th> */}
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        // style={{
                        //   position: "-webkit-sticky",
                        //   position: "sticky",
                        //   right: "386px",
                        // }}
                      >
                        {translations.forms.formFields.animator[language]}
                      </th>

                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{
                          position: "-webkit-sticky",
                          position: "sticky",
                          right:
                            language === "fr"
                              ? userType && !internalUsers.includes(userType)
                                ? "264px"
                                : StatusCondition
                                ? StatusCondition4
                                  ? userType === "Animator" || userType ==="Agent" ? "133px" : "239px"
                                  : "122px"
                                : StatusCondition2
                                ? "228px"
                                : StatusCondition3
                                ? 0
                                : "622px"
                              : userType && !internalUsers.includes(userType)
                              ? "223px"
                              : StatusCondition
                              ? StatusCondition4
                                ? userType === "Animator" || userType ==="Agent" ? "93px" :  "166px"
                                : "94px"
                              : StatusCondition2
                              ? userType === "Project Manager" ? "426px"  : "165px"
                              : StatusCondition3
                              ? 0
                              : "517px",
                        }}
                      >
                        {translations.usersPage.createUser.status[language]}
                      </th>
                      {((userData &&
                        userData.roles[0].permissions.some(
                          (item) => item.name === "Create Parent Form"
                        )) ||
                        (userData &&
                          userData.roles[0].permissions.some(
                            (item) => item.name === "Private Event Index"
                          )) ||
                        (userData &&
                          userData.roles[0].permissions.some(
                            (item) => item.name === "Private Event Destroy"
                          ))) && (
                        <th
                          scope="col"
                          colSpan={5}
                          className={`${
                            isDarkMode ? "darkthead text-center" : "text-center"
                          }`}
                          style={{
                            position: "-webkit-sticky",
                            position: "sticky",
                            right: 0,
                          }}
                        >
                          {
                            pageTranslations["eventsTableHeader"].actions[
                              language
                            ]
                          }
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="event-table" id="tbody">
                    {events && Object.keys(events).length > 0 ? (
                      Object.keys(events).map((key, accordionIndex) => {
                        const eventList = events[key];
                        return (
                          <React.Fragment
                            key={accordionIndex}
                            style={{
                              marginBottom: "1px",
                            }}
                          >
                            {eventList.map((event, index) => {
                              const isLastElement =
                                index === eventList.length - 1;
                              return (
                                <>
                                  <tr
                                    key={index}
                                    className="align-middle"
                                    style={{
                                      position: "relative",
                                      zIndex: 10 + eventList.length - index,
                                      background: `${
                                        index % 2 === 0
                                          ? isDarkMode
                                            ? "#2B3035"
                                            : "#F8F9FA"
                                          : isDarkMode
                                          ? "#343A3F"
                                          : "#E9ECEF"
                                      }`,
                                    }}
                                  >
                                    {index === 0 && (
                                      <td
                                        style={{
                                          borderBottom: "1px solid #dee2e6",
                                          backgroundColor:
                                            "rgba(67, 86, 116, 1)",
                                          position: "-webkit-sticky",
                                          position: "sticky",
                                          left: "0px",
                                          width: "57px",
                                        }}
                                        rowSpan={eventList.length}
                                        className="dlrname fw-bold text-center"
                                        id="weekCol"
                                      >
                                        {key.split(" ")[1]}
                                      </td>
                                    )}
                                    <td
                                      style={{
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        position: "-webkit-sticky",
                                        position: "sticky",
                                        left:
                                          language === "fr" ? "80px" : "58px",
                                        minWidth: "130px",
                                      }}
                                      className="dlrname"
                                    >
                                      {event?.nom_vp}
                                    </td>
                                    <td
                                      style={{
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                        cursor:
                                          userData &&
                                          userData.roles[0].permissions.some(
                                            (item) =>
                                              item.name === "Edit Dealership"
                                          )
                                            ? "pointer"
                                            : "",
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "140px",
                                      }}
                                      className="dlrname"
                                      onClick={() => {
                                        if (
                                          userData &&
                                          userData.roles[0].permissions.some(
                                            (item) =>
                                              item.name === "Edit Dealership"
                                          )
                                        ) {
                                          getDealershipData(
                                            event?.dealership?.id
                                          );
                                        }
                                      }}
                                    >
                                      {event?.dealership?.name}
                                    </td>
                                    {userType &&
                                      internalUsers.includes(userType) && (
                                        <td
                                          style={{
                                            background: `${
                                              index % 2 === 0
                                                ? isDarkMode
                                                  ? "#2B3035"
                                                  : "#F8F9FA"
                                                : isDarkMode
                                                ? "#343A3F"
                                                : "#E9ECEF"
                                            }`,
                                            borderBottom: isLastElement
                                              ? "1px solid #dee2e6"
                                              : "0px solid",
                                          }}
                                          className="dlrname"
                                        >
                                          {event?.package?.name}
                                        </td>
                                      )}
                                    <td
                                      style={{
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "100px",
                                      }}
                                      className="dlrname"
                                    >
                                      {event?.start_date || ""}
                                    </td>
                                    <td
                                      style={{
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "100px",
                                      }}
                                      className="dlrname"
                                    >
                                      {event?.end_date || ""}
                                    </td>
                                    {userType &&
                                      internalUsers.includes(userType) && (
                                        <>
                                          <td
                                            style={{
                                              background: `${
                                                index % 2 === 0
                                                  ? isDarkMode
                                                    ? "#2B3035"
                                                    : "#F8F9FA"
                                                  : isDarkMode
                                                  ? "#343A3F"
                                                  : "#E9ECEF"
                                              }`,
                                              borderBottom: isLastElement
                                                ? "1px solid #dee2e6"
                                                : "0px solid",
                                            }}
                                            className="dlrname"
                                          >
                                            {event?.clients || ""}
                                          </td>
                                          <td
                                            style={{
                                              background: `${
                                                index % 2 === 0
                                                  ? isDarkMode
                                                    ? "#2B3035"
                                                    : "#F8F9FA"
                                                  : isDarkMode
                                                  ? "#343A3F"
                                                  : "#E9ECEF"
                                              }`,
                                              borderBottom: isLastElement
                                                ? "1px solid #dee2e6"
                                                : "0px solid",
                                            }}
                                            className="dlrname"
                                          >
                                            {event?.inactive || ""}
                                          </td>
                                          <td
                                            style={{
                                              background: `${
                                                index % 2 === 0
                                                  ? isDarkMode
                                                    ? "#2B3035"
                                                    : "#F8F9FA"
                                                  : isDarkMode
                                                  ? "#343A3F"
                                                  : "#E9ECEF"
                                              }`,
                                              borderBottom: isLastElement
                                                ? "1px solid #dee2e6"
                                                : "0px solid",
                                            }}
                                            className="dlrname"
                                          >
                                            {event?.amount || ""}
                                          </td>
                                        </>
                                      )}
                                    <td
                                      style={{
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                      }}
                                      className="dlrname"
                                    >
                                      {event?.sales_rep?.name || ""}
                                    </td>

                                    {/* <td
                                      style={{
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "186px",
                                      }}
                                      className="dlrname"
                                    >
                                      <Select
                                        className="selectpicker w-100"
                                        aria-labelledby="dealership-name-quick"
                                        // isClearable
                                        isDisabled={true}
                                        options={repOptions}
                                        placeholder={
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                        isSearchable={true}
                                        value={
                                          (repOptions &&
                                            repOptions.length > 0 &&
                                            repOptions.find(
                                              (option) =>
                                                option.value ===
                                                event?.sales_rep_id
                                            )) || {
                                            value: "",
                                            label: "",
                                            // label:
                                            //   translations["dropdown"].select[
                                            //     language
                                            //   ],
                                          }
                                        }
                                        onChange={(selectedOption) => {
                                          changeRep(
                                            selectedOption.value,
                                            event.id,
                                            key
                                          );
                                        }}
                                        maxMenuHeight={"130px"}
                                        styles={{
                                          input: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black", // Color of the text when typing
                                          }),
                                          control: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: isDarkMode
                                              ? "transparent"
                                              : "transparent",
                                            border: state.isFocused
                                              ? "none"
                                              : isDarkMode
                                              ? "none"
                                              : "none", // Adjust the border color for focus
                                            boxShadow: state.isFocused
                                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                              : "none", // Bootstrap's box shadow for focus
                                            borderRadius: ".45rem",
                                          }),
                                          valueContainer: (provided) => ({
                                            ...provided,
                                            padding: "0.18rem 0rem", // Bootstrap's padding for the value container
                                          }),
                                          indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none", // Hide the indicator separator
                                          }),
                                          dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#868686",
                                            display: "none",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            margin: "0", // Remove default margin
                                          }),
                                          placeholder: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#333333", // Specify your desired color for the placeholder
                                            // Additional styles for placeholder
                                          }),
                                          singleValue: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: state.isHovered
                                              ? "#80bdff"
                                              : isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            ":hover": {
                                              backgroundColor: isDarkMode
                                                ? "#34DBB4"
                                                : "#34DBB4",
                                            },
                                          }),
                                        }}
                                      />
                                    </td> */}
                                    {/* <td
                                      style={{
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "180px",
                                        // position: "-webkit-sticky",
                                        // position: "sticky",
                                        // right: "386px",
                                      }}
                                      className="dlrname"
                                    >
                                      <Select
                                        className="selectpicker w-100"
                                        aria-labelledby="dealership-name-quick"
                                        // isClearable
                                        isDisabled={!hasPrivateEventShow}
                                        options={configIds}
                                        placeholder={
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                        menuPortalTarget={document.body}
                                        // defaultMenuIsOpen={isLastElement}
                                        isSearchable={true}
                                        value={
                                          (configIds &&
                                            configIds.length > 0 &&
                                            configIds.find(
                                              (option) =>
                                                option.value ===
                                                event?.config_id
                                            )) || {
                                            value: "",
                                            label:
                                              translations["dropdown"].select[
                                                language
                                              ],
                                          }
                                        }
                                        onChange={(selectedOption) => {
                                          changeDropdowns(
                                            {
                                              id: event.id,
                                              config_id: selectedOption.value
                                            }
                                          );
                                        }}
                                        maxMenuHeight={"130px"}
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                          input: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black", // Color of the text when typing
                                          }),
                                          control: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: isDarkMode
                                              ? hasPrivateEventShow
                                                ? "transparent"
                                                : "#ced4da66"
                                              : hasPrivateEventShow
                                              ? "white"
                                              : "#ced4da66",
                                            border: state.isFocused
                                              ? "1px solid #80bdff"
                                              : isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6", // Adjust the border color for focus
                                            boxShadow: state.isFocused
                                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                              : "none", // Bootstrap's box shadow for focus
                                            borderRadius: ".45rem",
                                          }),
                                          valueContainer: (provided) => ({
                                            ...provided,
                                            padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                            width: "120px",
                                          }),
                                          indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none", // Hide the indicator separator
                                          }),
                                          dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#868686",
                                            display:
                                              userData &&
                                              userData.roles[0].permissions.some(
                                                (item) =>
                                                  item.name ===
                                                  "Private Event Index"
                                              )
                                                ? "block"
                                                : "none",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            margin: "0", // Remove default margin
                                          }),
                                          placeholder: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#333333", // Specify your desired color for the placeholder
                                            // Additional styles for placeholder
                                          }),
                                          singleValue: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: state.isHovered
                                              ? "#80bdff"
                                              : isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            ":hover": {
                                              backgroundColor: isDarkMode
                                                ? "#34DBB4"
                                                : "#34DBB4",
                                            },
                                          }),
                                        }}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "180px",
                                        // position: "-webkit-sticky",
                                        // position: "sticky",
                                        // right: "386px",
                                      }}
                                      className="dlrname"
                                    >
                                      <Select
                                        className="selectpicker w-100"
                                        aria-labelledby="dealership-name-quick"
                                        // isClearable
                                        isDisabled={!hasPrivateEventShow}
                                        options={listIds}
                                        placeholder={
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                        menuPortalTarget={document.body}
                                        // defaultMenuIsOpen={isLastElement}
                                        isSearchable={true}
                                        value={
                                          (listIds &&
                                            listIds.length > 0 &&
                                            listIds.find(
                                              (option) =>
                                                option.value ===
                                                event?.list_id
                                            )) || {
                                            value: "",
                                            label:
                                              translations["dropdown"].select[
                                                language
                                              ],
                                          }
                                        }
                                        onChange={(selectedOption) => {
                                          
                                          changeDropdowns(
                                            {
                                              id: event.id,
                                              list_id: selectedOption.value
                                            }
                                          );
                                        }}
                                        maxMenuHeight={"130px"}
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                          input: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black", // Color of the text when typing
                                          }),
                                          control: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: isDarkMode
                                              ? hasPrivateEventShow
                                                ? "transparent"
                                                : "#ced4da66"
                                              : hasPrivateEventShow
                                              ? "white"
                                              : "#ced4da66",
                                            border: state.isFocused
                                              ? "1px solid #80bdff"
                                              : isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6", // Adjust the border color for focus
                                            boxShadow: state.isFocused
                                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                              : "none", // Bootstrap's box shadow for focus
                                            borderRadius: ".45rem",
                                          }),
                                          valueContainer: (provided) => ({
                                            ...provided,
                                            padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                            width: "120px",
                                          }),
                                          indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none", // Hide the indicator separator
                                          }),
                                          dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#868686",
                                            display:
                                              userData &&
                                              userData.roles[0].permissions.some(
                                                (item) =>
                                                  item.name ===
                                                  "Private Event Index"
                                              )
                                                ? "block"
                                                : "none",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            margin: "0", // Remove default margin
                                          }),
                                          placeholder: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#333333", // Specify your desired color for the placeholder
                                            // Additional styles for placeholder
                                          }),
                                          singleValue: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: state.isHovered
                                              ? "#80bdff"
                                              : isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            ":hover": {
                                              backgroundColor: isDarkMode
                                                ? "#34DBB4"
                                                : "#34DBB4",
                                            },
                                          }),
                                        }}
                                      />
                                    </td> */}
                                    <td
                                      style={{
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "180px",
                                        // position: "-webkit-sticky",
                                        // position: "sticky",
                                        // right: "386px",
                                      }}
                                      className="dlrname"
                                    >
                                      <Select
                                        className="selectpicker w-100"
                                        aria-labelledby="dealership-name-quick"
                                        // isClearable
                                        // isDisabled={
                                        //   !hasPrivateEventShow ||
                                        //   (userType &&
                                        //     !internalUsers.includes(userType))
                                        // }
                                        isDisabled={userData &&
                                          !userData.roles[0].permissions.some(
                                            (item) => item.name === "Animator DropDown"
                                          )}
                                        options={animatorOptions}
                                        placeholder={
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                        menuPortalTarget={document.body}
                                        // defaultMenuIsOpen={isLastElement}
                                        isSearchable={true}
                                        value={
                                          (animatorOptions &&
                                            animatorOptions.length > 0 &&
                                            animatorOptions.find(
                                              (option) =>
                                                option.value ===
                                                event?.animator_id
                                            )) || {
                                            value: "",
                                            label:
                                              translations["dropdown"].select[
                                                language
                                              ],
                                          }
                                        }
                                        onChange={(selectedOption) => {
                                          changeDropdowns({
                                            id: event.id,
                                            animator_id: selectedOption.value,
                                          });
                                        }}
                                        maxMenuHeight={"130px"}
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                          input: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black", // Color of the text when typing
                                          }),
                                          control: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: isDarkMode
                                              ? userData &&
                                              userData.roles[0].permissions.some(
                                                (item) => item.name === "Animator DropDown"
                                              )
                                                ? "transparent"
                                                : "#ced4da66"
                                              : userData &&
                                              userData.roles[0].permissions.some(
                                                (item) => item.name === "Animator DropDown"
                                              )
                                              ? "white"
                                              : "#ced4da66",
                                            border: state.isFocused
                                              ? "1px solid #80bdff"
                                              : isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6", // Adjust the border color for focus
                                            boxShadow: state.isFocused
                                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                              : "none", // Bootstrap's box shadow for focus
                                            borderRadius: ".45rem",
                                          }),
                                          valueContainer: (provided) => ({
                                            ...provided,
                                            padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                            width: "120px",
                                          }),
                                          indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none", // Hide the indicator separator
                                          }),
                                          dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#868686",
                                            display:
                                            userData &&
                                            userData.roles[0].permissions.some(
                                              (item) => item.name === "Animator DropDown"
                                            )
                                                ? "block"
                                                : "none",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            margin: "0", // Remove default margin
                                          }),
                                          placeholder: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#333333", // Specify your desired color for the placeholder
                                            // Additional styles for placeholder
                                          }),
                                          singleValue: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: state.isHovered
                                              ? "#80bdff"
                                              : isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            ":hover": {
                                              backgroundColor: isDarkMode
                                                ? "#34DBB4"
                                                : "#34DBB4",
                                            },
                                          }),
                                        }}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        position: "-webkit-sticky",
                                        position: "sticky",
                                        right:
                                          language === "fr"
                                            ? userType &&
                                              !internalUsers.includes(userType)
                                              ? "264px"
                                              : StatusCondition
                                              ? StatusCondition4
                                                ? userType === "Animator" ||  userType ==="Agent" ? "133px" : "239px"
                                                : "122px"
                                              : StatusCondition2
                                              ? "228px"
                                              : StatusCondition3
                                              ? 0
                                              : "619px"
                                            : userType &&
                                              !internalUsers.includes(userType)
                                            ? "223px"
                                            : StatusCondition
                                            ? StatusCondition4
                                              ? userType === "Animator" ||  userType ==="Agent" ? "93px" :  "166px"
                                              : "94px"
                                            : StatusCondition2
                                            ? userType === "Project Manager" ? "426px"  : "165px"
                                            : StatusCondition3
                                            ? 0
                                            : "517px",
                                        minWidth: "186px",
                                      }}
                                    >
                                      <div
                                        className="d-flex align-items-center"
                                        style={{ minWidth: "186px" }}
                                      >
                                        <div
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            backgroundColor:
                                              event?.status === "Active" ||
                                              event?.status === "Completed"
                                                ? "#34DBB4"
                                                : event?.status === "Waiting"
                                                ? "orange"
                                                : "red",
                                            borderRadius: "50%",
                                            marginRight: "8px", // Adjust the spacing between the circle and the dropdown
                                          }}
                                        ></div>
                                        <Select
                                          className="selectpicker w-100"
                                          aria-labelledby="dealership-name-quick"
                                          // isClearable
                                          options={statusOptions}
                                          placeholder={
                                            translations["dropdown"].select[
                                              language
                                            ]
                                          }
                                          isDisabled={
                                            userData &&
                                          !userData.roles[0].permissions.some(
                                            (item) => item.name === "Change Event Status"
                                          )
                                          }
                                          isSearchable={false}
                                          value={
                                            (statusOptions &&
                                              statusOptions.length > 0 &&
                                              statusOptions.find(
                                                (option) =>
                                                  option.value === event?.status
                                              )) || {
                                              value: "",
                                              label:
                                                translations["dropdown"].select[
                                                  language
                                                ],
                                            }
                                          }
                                          onChange={(selectedOption) => {
                                            changeDropdowns({
                                              id: event.id,
                                              status: selectedOption.value,
                                            });
                                          }}
                                          menuPortalTarget={document.body}
                                          maxMenuHeight={"100px"}
                                          styles={{
                                            menuPortal: (base) => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                            input: (provided) => ({
                                              ...provided,
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "black", // Color of the text when typing
                                            }),
                                            control: (provided, state) => ({
                                              ...provided,
                                              width: "100%",
                                              backgroundColor: isDarkMode
                                                ? userData &&
                                                userData.roles[0].permissions.some(
                                                  (item) => item.name === "Change Event Status"
                                                )
                                                  ? "transparent"
                                                  : "#ced4da66"
                                                : userData &&
                                                userData.roles[0].permissions.some(
                                                  (item) => item.name === "Change Event Status"
                                                )
                                                ? "white"
                                                : "#ced4da66",
                                              border: state.isFocused
                                                ? "1px solid #80bdff"
                                                : isDarkMode
                                                ? "1px solid #495057"
                                                : "1px solid #dee2e6", // Adjust the border color for focus
                                              boxShadow: state.isFocused
                                                ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                                : "none", // Bootstrap's box shadow for focus
                                              borderRadius: ".45rem",
                                            }),
                                            valueContainer: (provided) => ({
                                              ...provided,
                                              padding: "0.18rem 0.55rem",
                                              width: "100%", // Bootstrap's padding for the value container
                                              // position: "relative",
                                              // zIndex:"10000"
                                            }),
                                            indicatorSeparator: (provided) => ({
                                              ...provided,
                                              display: "none", // Hide the indicator separator
                                            }),
                                            dropdownIndicator: (provided) => ({
                                              ...provided,
                                              padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "#868686",
                                              display:
                                              userData &&
                                              userData.roles[0].permissions.some(
                                                (item) => item.name === "Change Event Status"
                                              )
                                                  ? "block"
                                                  : "none",
                                            }),
                                            menu: (provided) => ({
                                              ...provided,
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "black",
                                              backgroundColor: isDarkMode
                                                ? "#1f2023"
                                                : "white",
                                              margin: "0", // Remove default margin
                                              width: "100%",
                                            }),
                                            placeholder: (provided) => ({
                                              ...provided,
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "#333333", // Specify your desired color for the placeholder
                                              // Additional styles for placeholder
                                            }),
                                            singleValue: (provided) => ({
                                              ...provided,
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "black",
                                            }),
                                            option: (provided, state) => ({
                                              ...provided,
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "black",
                                              backgroundColor: state.isHovered
                                                ? "#80bdff"
                                                : isDarkMode
                                                ? "#1f2023"
                                                : "white",
                                              ":hover": {
                                                backgroundColor: isDarkMode
                                                  ? "#34DBB4"
                                                  : "#34DBB4",
                                              },
                                            }),
                                          }}
                                        />
                                      </div>
                                    </td>
                                    {false && (
                                      <td
                                        style={{
                                          background: `${
                                            index % 2 === 0
                                              ? isDarkMode
                                                ? "#2B3035"
                                                : "#F8F9FA"
                                              : isDarkMode
                                              ? "#343A3F"
                                              : "#E9ECEF"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          paddingInline: "1px",
                                          position: "-webkit-sticky",
                                          position: "sticky",
                                          right:
                                            language === "fr"
                                              ? !hasPrivateEventDestroy &&
                                                !hasPrivateEventShow &&
                                                !hasCreateParentForm
                                                ? 0
                                                : "346px"
                                              : !hasPrivateEventDestroy &&
                                                !hasPrivateEventShow &&
                                                !hasCreateParentForm
                                              ? 0
                                              : "256px",
                                        }}
                                        className="my-2"
                                      >
                                        {}
                                      </td>
                                    )}
                                    {false && (
                                      <td
                                        style={{
                                          background: `${
                                            index % 2 === 0
                                              ? isDarkMode
                                                ? "#2B3035"
                                                : "#F8F9FA"
                                              : isDarkMode
                                              ? "#343A3F"
                                              : "#E9ECEF"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          padding: "0",
                                          position: "-webkit-sticky",
                                          position: "sticky",
                                          right:
                                            language === "fr"
                                              ? !hasPrivateEventDestroy &&
                                                !hasCreateParentForm
                                                ? 0
                                                : hasPrivateEventDestroy !==
                                                  hasCreateParentForm
                                                ? "122px"
                                                : "247px"
                                              : !hasPrivateEventDestroy &&
                                                !hasCreateParentForm
                                              ? 0
                                              : hasPrivateEventDestroy !==
                                                hasCreateParentForm
                                              ? "91px"
                                              : "185px",
                                        }}
                                        className="my-2"
                                      >
                                        {userData &&
                                          userData.roles[0].permissions.some(
                                            (item) =>
                                              item.name ===
                                              "Private Event Index"
                                          ) && (
                                            <button
                                              type="button"
                                              className="btn btn-light edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                              style={{
                                                width: "100%", // Set the width to 100% for responsive design
                                                maxWidth: "150px", // Set a maximum width for the button
                                                whiteSpace: "nowrap", // Prevent text wrapping
                                                textOverflow: "ellipsis", // Add ellipsis if text overflows
                                                overflow: "hidden", // Hide the overflowed text
                                              }}
                                              onClick={() => {
                                                setDisplayComponent(
                                                  "edit-event"
                                                );
                                                setEditEventID(event.id);
                                                window.history.pushState(
                                                  {
                                                    id: "gallery",
                                                    randomData:
                                                      window.Math.random(),
                                                  },
                                                  "title",
                                                  "/event-portal?page=edit-event&parent_id=" +
                                                    event.id
                                                );
                                              }}
                                            >
                                              <i
                                                className={
                                                  userData &&
                                                  userData.roles[0].permissions.some(
                                                    (item) =>
                                                      item.name ===
                                                      "Private Event Show"
                                                  )
                                                    ? "bi bi-pencil"
                                                    : "bi bi-eye"
                                                }
                                              ></i>{" "}
                                              {userData &&
                                              userData.roles[0].permissions.some(
                                                (item) =>
                                                  item.name ===
                                                  "Private Event Show"
                                              )
                                                ? pageTranslations[
                                                    "eventsTableButtons"
                                                  ].edit[language]
                                                : translations.commonWords.view[
                                                    language
                                                  ]}
                                            </button>
                                          )}
                                      </td>
                                    )}
                                    {/* <td
                                  style={{background:`${isDarkMode ? "darkthead" : ""}`} style={{ borrgb(183,187,192 )"nonewhite"}}}>
                                  {userData &&
                                    userData.roles[0].permissions.some(
                                      (item) =>
                                        item.name === "Create Parent Form"
                                    ) && (
                                      <button
                                        type="button"
                                        className="btn btn-light edit-button"
                                        onClick={() => {
                                          checkParent(event.id);
                                        }}
                                      >
                                        <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                                        {pageTranslations["eventsTableButtons"].viewReport[language]}
                                      </button>
                                    )}
                                </td> */}
                                    {
                                      // (
                                      //   hasCreateParentForm ||
                                      //   hasProspectiveIndex) &&
                                      <td
                                        style={{
                                          background: `${
                                            index % 2 === 0
                                              ? isDarkMode
                                                ? "#2B3035"
                                                : "#F8F9FA"
                                              : isDarkMode
                                              ? "#343A3F"
                                              : "#E9ECEF"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          paddingInline: "1px",
                                          position: "-webkit-sticky",
                                          position: "sticky",
                                          right:
                                            language === "fr"
                                              ? userType &&
                                                !internalUsers.includes(
                                                  userType
                                                )
                                                ? 0
                                                : userType === "Animator" ||  userType ==="Agent"? 0 : !hasPrivateEventDestroy &&
                                                  !userData.roles[0].permissions.some(
                                                    (item) =>
                                                      item.name ===
                                                      "Private Event Index"
                                                  )
                                                ? 0
                                                : userType === "Accountant" ? "106px" :"226px"
                                              : !hasPrivateEventDestroy &&
                                                !userData.roles[0].permissions.some(
                                                  (item) =>
                                                    item.name ===
                                                    "Private Event Index"
                                                )
                                              ? 0
                                              : userType &&
                                                !internalUsers.includes(
                                                  userType
                                                )
                                              ? 0
                                              : !hasPrivateEventDestroy
                                              ? userType === "Animator" ||  userType ==="Agent" ? 0 : "72px"
                                              : !userData.roles[0].permissions.some(
                                                  (item) =>
                                                    item.name ===
                                                    "Private Event Index"
                                                )
                                              ? "92px"
                                              : "166px",
                                        }}
                                        className="my-2"
                                      >
                                        <div
                                          className="btn-group"
                                          role="group"
                                          aria-label="Basic example"
                                        >
                                          {/* {hasCreateParentForm && ( */}
                                          {userType &&
                                            internalUsers.includes(
                                              userType
                                            ) && userType !== "Animator" && userType !== "Agent" && userType !== "Accountant" &&  (
                                              <button
                                                type="button"
                                                className="btn btn-light edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                                style={{
                                                  width: "100%", // Set the width to 100% for responsive design
                                                  maxWidth: "150px", // Set a maximum width for the button
                                                  whiteSpace: "nowrap", // Prevent text wrapping
                                                  textOverflow: "ellipsis", // Add ellipsis if text overflows
                                                  overflow: "hidden", // Hide the overflowed text
                                                  minWidth: "130px",
                                                }}
                                                onClick={() => {
                                                  if (event.copy === "0")
                                                    checkParent(event.id);
                                                  else {
                                                    setDisplayComponent(
                                                      "create-event"
                                                    );

                                                    window.history.pushState(
                                                      {
                                                        id: "gallery",
                                                        randomData:
                                                          window.Math.random(),
                                                      },
                                                      "title",
                                                      "/event-portal?page=create-event&parent_id=" +
                                                        event.id +
                                                        "&event-navigate=true&getFormData=false"
                                                    );
                                                  }
                                                }}
                                              >
                                                <i
                                                  className={
                                                    // event.copy === "0"
                                                    //   ? `bi bi-clipboard-plus`
                                                    //   : `bi bi-file-earmark-text`
                                                    "bi bi-plus-circle"
                                                  }
                                                ></i>{" "}
                                                {
                                                  // event.copy === "0"
                                                  //   ? translations.forms.title[
                                                  //       language
                                                  //     ]
                                                  //   :
                                                  translations.commonWords.form[
                                                    language
                                                  ]
                                                }
                                              </button>
                                            )}
                                          {hasCreateParentForm && (
                                            <button
                                              type="button"
                                              className="btn admin-btn edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                              style={{
                                                width: "100%", // Set the width to 100% for responsive design
                                                maxWidth: "150px", // Set a maximum width for the button
                                                whiteSpace: "nowrap", // Prevent text wrapping
                                                textOverflow: "ellipsis", // Add ellipsis if text overflows
                                                overflow: "hidden", // Hide the overflowed text
                                                minWidth: "130px",
                                              }}
                                              disabled={
                                                event &&
                                                (userType &&
                                                  internalUsers.includes(
                                                    userType
                                                  ) ?
                                                  event?.parents_form ===
                                                    null :
                                                  event?.parents_form
                                                    ?.clients_form === null)
                                              }
                                              onClick={() => {
                                                // if (event.copy === "0")
                                                //   checkParent(event.id);
                                                // else {
                                                if (
                                                  userType &&
                                                  internalUsers.includes(
                                                    userType
                                                  )
                                                ) {
                                                  setDisplayComponent(
                                                    "edit-form"
                                                  );
                                                  window.history.pushState(
                                                    {
                                                      id: "gallery",
                                                      randomData:
                                                        window.Math.random(),
                                                    },
                                                    "title",
                                                    "/event-portal?page=edit-form&event_id=" +
                                                      event.id +
                                                      "&form_id=" +
                                                      event?.parents_form?.id
                                                  );
                                                } else {
                                                  navigate(
                                                    "/client-submission?id=" +
                                                      event?.parents_form
                                                        ?.clients_form?.id
                                                  );
                                                }
                                                // setDisplayComponent(
                                                //   "create-event"
                                                // );

                                                // window.history.pushState(
                                                //   {
                                                //     id: "gallery",
                                                //     randomData:
                                                //       window.Math.random(),
                                                //   },
                                                //   "title",
                                                //   "/event-portal?page=create-event&parent_id=" +
                                                //     event.id +
                                                //     "&event-navigate=true&getFormData=true"
                                                // );
                                                // }
                                              }}
                                            >
                                              <i
                                                className={
                                                  // event.copy === "0"
                                                  //   ? `bi bi-clipboard-plus`
                                                  //   :
                                                  `bi bi-file-earmark-text`
                                                }
                                              ></i>{" "}
                                              {
                                                // event.copy === "0"
                                                //   ? translations.forms.title[
                                                //       language
                                                //     ]
                                                //   :
                                                translations.commonWords.view[
                                                  language
                                                ]
                                              }
                                            </button>
                                          )}
                                          {/* {hasProspectiveIndex && ( */}
                                          {
                                            <button
                                              type="button"
                                              className="btn admin-btn d-flex align-items-center justify-content-center gap-1 mx-auto"
                                              style={{
                                                width: "100%", // Set the width to 100% for responsive design
                                                maxWidth: "150px", // Set a maximum width for the button
                                                whiteSpace: "nowrap", // Prevent text wrapping
                                                textOverflow: "ellipsis", // Add ellipsis if text overflows
                                                overflow: "hidden", // Hide the overflowed text
                                              }}
                                              onClick={() => {
                                                setDisplayComponent(
                                                  "event-view"
                                                );

                                                window.history.pushState(
                                                  {
                                                    id: "gallery",
                                                    randomData:
                                                      window.Math.random(),
                                                  },
                                                  "title",
                                                  `/event-portal?page=event-view&event_id=${event.id}`
                                                );
                                              }}
                                            >
                                              <i
                                                className={
                                                  "bi bi-calendar2-event"
                                                }
                                              ></i>{" "}
                                              {
                                                pageTranslations["title"]
                                                  .events[language]
                                              }
                                            </button>
                                          }
                                        </div>
                                      </td>
                                    }
                                    {/* {(hasPrivateEventDestroy ||
                                      userData.roles[0].permissions.some(
                                        (item) =>
                                          item.name === "Private Event Index"
                                      )) && ( */}
                                    {userType &&
                                      internalUsers.includes(userType) && userType !== "Animator" && userType !=="Agent" && (
                                        <td
                                          style={{
                                            background: `${
                                              index % 2 === 0
                                                ? isDarkMode
                                                  ? "#2B3035"
                                                  : "#F8F9FA"
                                                : isDarkMode
                                                ? "#343A3F"
                                                : "#E9ECEF"
                                            }`,
                                            borderBottom: isLastElement
                                              ? "1px solid #dee2e6"
                                              : "0px solid",
                                            paddingInline: "1px",
                                            position: "-webkit-sticky",
                                            position: "sticky",
                                            right: 0,
                                          }}
                                        >
                                          <div
                                            className="btn-group"
                                            role="group"
                                          >
                                            {/* {userData &&
                                            userData.roles[0].permissions.some(
                                              (item) =>
                                                item.name ===
                                                "Private Event Index"
                                            ) && ( */}
                                            {userType &&
                                              internalUsers.includes(
                                                userType
                                              ) && userType !=="Animator" && userType !=="Agent" && (
                                                <button
                                                  type="button"
                                                  className="btn btn-light edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                                  style={{
                                                    width: "100%", // Set the width to 100% for responsive design
                                                    maxWidth: "150px", // Set a maximum width for the button
                                                    whiteSpace: "nowrap", // Prevent text wrapping
                                                    textOverflow: "ellipsis", // Add ellipsis if text overflows
                                                    overflow: "hidden", // Hide the overflowed text
                                                  }}
                                                  onClick={() => {
                                                    setDisplayComponent(
                                                      "edit-event"
                                                    );
                                                    setEditEventID(event.id);
                                                    window.history.pushState(
                                                      {
                                                        id: "gallery",
                                                        randomData:
                                                          window.Math.random(),
                                                      },
                                                      "title",
                                                      "/event-portal?page=edit-event&parent_id=" +
                                                        event.id
                                                    );
                                                  }}
                                                >
                                                  <i
                                                    className={
                                                      userData &&
                                                      userData.roles[0].permissions.some(
                                                        (item) =>
                                                          item.name ===
                                                          "Private Event Show"
                                                      )
                                                        ? "bi bi-pencil"
                                                        : "bi bi-eye"
                                                    }
                                                  ></i>{" "}
                                                  {userData &&
                                                  userData.roles[0].permissions.some(
                                                    (item) =>
                                                      item.name ===
                                                      "Private Event Show"
                                                  )
                                                    ? pageTranslations[
                                                        "eventsTableButtons"
                                                      ].edit[language]
                                                    : translations.commonWords
                                                        .view[language]}
                                                </button>
                                              )}
                                            {hasPrivateEventDestroy && (
                                              <>
                                                {deleteStates &&
                                                deleteStates[accordionIndex][
                                                  index
                                                ][event.id] ? (
                                                  <div
                                                    className="btn-group"
                                                    role="group"
                                                    style={{ width: "180px" }}
                                                  >
                                                    <button
                                                      type="button"
                                                      className="btn btn-success delete-button"
                                                      onClick={() =>
                                                        handleDelete(
                                                          event.id,
                                                          index,
                                                          accordionIndex
                                                        )
                                                      }
                                                    >
                                                      {
                                                        pageTranslations[
                                                          "eventsTableButtons"
                                                        ].confirm[language]
                                                      }
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-secondary cancel-button"
                                                      onClick={() =>
                                                        setDeleteStates(
                                                          (prevStates) => {
                                                            const newStates = [
                                                              ...prevStates,
                                                            ];
                                                            newStates[
                                                              accordionIndex
                                                            ][index][
                                                              event.id
                                                            ] = false;
                                                            return newStates;
                                                          }
                                                        )
                                                      }
                                                    >
                                                      {
                                                        pageTranslations[
                                                          "eventsTableButtons"
                                                        ].cancel[language]
                                                      }
                                                    </button>
                                                  </div>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    className="btn btn-danger before-delete-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                                    style={{
                                                      width: "100%", // Set the width to 100% for responsive design
                                                      maxWidth: "150px", // Set a maximum width for the button
                                                      whiteSpace: "nowrap", // Prevent text wrapping
                                                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                                                      overflow: "hidden", // Hide the overflowed text
                                                    }}
                                                    onClick={() => {
                                                      setDeleteStates(
                                                        (prevStates) => {
                                                          const newStates = [
                                                            ...prevStates,
                                                          ];
                                                          newStates[
                                                            accordionIndex
                                                          ][index][
                                                            event.id
                                                          ] = true;
                                                          return newStates;
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <i className="bi bi-trash"></i>{" "}
                                                    {
                                                      pageTranslations[
                                                        "eventsTableButtons"
                                                      ].delete[language]
                                                    }
                                                  </button>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </td>
                                      )}
                                  </tr>
                                  {isLastElement && (
                                    <tr
                                      key={index}
                                      className="align-middle"
                                      style={{
                                        position: "relative",
                                        height: "30px",
                                        zIndex: 10 + eventList.length - index,
                                      }}
                                    >
                                      <td
                                        id="totalRow"
                                        style={{
                                          borderBottom: "1px solid #dee2e6",
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          position: "-webkit-sticky",
                                          position: "sticky",
                                          left: "0px",
                                          width: "57px",
                                        }}
                                        className="dlrname fw-bold text-center"
                                      >
                                        Total
                                      </td>
                                      <td
                                        id="totalRow"
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          fontWeight: "700",
                                          position: "-webkit-sticky",
                                          position: "sticky",
                                          left:
                                            language === "fr" ? "80px" : "58px",
                                          width: "83px",
                                        }}
                                        className="dlrname"
                                      >
                                        {eventList.length}
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname"
                                      >
                                        -
                                      </td>
                                      {userType &&
                                        internalUsers.includes(userType) && (
                                          <td
                                            style={{
                                              background: `${
                                                isDarkMode
                                                  ? "#435574"
                                                  : "#435574"
                                              }`,
                                              borderBottom: isLastElement
                                                ? "1px solid #dee2e6"
                                                : "0px solid",
                                            }}
                                            id="totalRow"
                                            className="dlrname"
                                          >
                                            -
                                          </td>
                                        )}
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          minWidth: "100px",
                                        }}
                                        id="totalRow"
                                        className="dlrname"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          minWidth: "100px",
                                        }}
                                        id="totalRow"
                                        className="dlrname"
                                      >
                                        -
                                      </td>
                                      {userType &&
                                        internalUsers.includes(userType) && (
                                          <>
                                            <td
                                              style={{
                                                background: `${
                                                  isDarkMode
                                                    ? "#435574"
                                                    : "#435574"
                                                }`,
                                                borderBottom: isLastElement
                                                  ? "1px solid #dee2e6"
                                                  : "0px solid",
                                                fontWeight: "700",
                                              }}
                                              id="totalRow"
                                              className="dlrname"
                                            >
                                              {eventList.reduce(
                                                (sum, event) =>
                                                  sum +
                                                  (Number(event?.clients) || 0),
                                                0
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                background: `${
                                                  isDarkMode
                                                    ? "#435574"
                                                    : "#435574"
                                                }`,
                                                borderBottom: isLastElement
                                                  ? "1px solid #dee2e6"
                                                  : "0px solid",
                                                fontWeight: "700",
                                              }}
                                              id="totalRow"
                                              className="dlrname"
                                            >
                                              {eventList.reduce(
                                                (sum, event) =>
                                                  sum +
                                                  (Number(event?.inactive) ||
                                                    0),
                                                0
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                background: `${
                                                  isDarkMode
                                                    ? "#435574"
                                                    : "#435574"
                                                }`,
                                                borderBottom: isLastElement
                                                  ? "1px solid #dee2e6"
                                                  : "0px solid",
                                                fontWeight: "700",
                                              }}
                                              id="totalRow"
                                              className="dlrname"
                                            >
                                              {eventList.reduce(
                                                (sum, event) =>
                                                  sum +
                                                  (Number(event?.amount) || 0),
                                                0
                                              )}
                                            </td>
                                          </>
                                        )}

                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname"
                                      >
                                        -
                                      </td>
                                      {/* <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname"
                                      >
                                        -
                                      </td> */}
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          position: "-webkit-sticky",
                                          position: "sticky",
                                          right:
                                            language === "fr"
                                              ? userType &&
                                                !internalUsers.includes(
                                                  userType
                                                )
                                                ? "264px"
                                                : StatusCondition
                                                ? StatusCondition4
                                                  ? userType === "Animator" ||  userType ==="Agent" ? "133px" :  "239px"
                                                  : "122px"
                                                : StatusCondition2
                                                ? "228px"
                                                : StatusCondition3
                                                ? 0
                                                : "622px"
                                              : userType &&
                                                !internalUsers.includes(
                                                  userType
                                                )
                                              ? "223px"
                                              : StatusCondition
                                              ? StatusCondition4
                                                ? userType === "Animator" ||  userType ==="Agent" ? "93px" :  "166px"
                                                : "94px"
                                              : StatusCondition2
                                              ? userType === "Project Manager" ? "426px"  : "165px"
                                              : StatusCondition3
                                              ? 0
                                              : "517px",

                                          width: "151px",
                                        }}
                                        id="totalRow"
                                      >
                                        -
                                      </td>
                                      {/* <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          paddingInline: "1px",
                                          position: "-webkit-sticky",
                                          position: "sticky",
                                          right:
                                            language === "fr"
                                              ? !hasPrivateEventDestroy &&
                                                !hasPrivateEventShow &&
                                                !hasCreateParentForm
                                                ? 0
                                                : "345px"
                                              : !hasPrivateEventDestroy &&
                                                !hasPrivateEventShow &&
                                                !hasCreateParentForm
                                              ? 0
                                              : "254px",
                                        }}
                                        id="totalRow"
                                      >
                                        {" "}
                                        -{" "}
                                      </td> */}
                                      {false && (
                                        <td
                                          style={{
                                            background: `${
                                              isDarkMode ? "#435574" : "#435574"
                                            }`,
                                            borderBottom: isLastElement
                                              ? "1px solid #dee2e6"
                                              : "0px solid",
                                            padding: "0",
                                            position: "-webkit-sticky",
                                            position: "sticky",
                                            right:
                                              language === "fr"
                                                ? userData &&
                                                  !hasPrivateEventDestroy &&
                                                  !hasCreateParentForm
                                                  ? 0
                                                  : hasPrivateEventDestroy !==
                                                    hasCreateParentForm
                                                  ? "122px"
                                                  : "247px"
                                                : !hasPrivateEventDestroy &&
                                                  !hasCreateParentForm
                                                ? 0
                                                : hasPrivateEventDestroy !==
                                                  hasCreateParentForm
                                                ? "91px"
                                                : "185px",
                                            width: "120px",
                                          }}
                                          id="totalRow"
                                          className="my-2"
                                        >
                                          -
                                        </td>
                                      )}
                                      {/* <td
                                  style={{background:`${isDarkMode ? "darkthead" : ""}`} style={{ borrgb(183,187,192 )"nonewhite"}}}>
                                  {userData &&
                                    userData.roles[0].permissions.some(
                                      (item) =>
                                        item.name === "Create Parent Form"
                                    ) && (
                                      <button
                                        type="button"
                                        className="btn btn-light edit-button"
                                        onClick={() => {
                                          checkParent(event.id);
                                        }}
                                      >
                                        <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                                        {pageTranslations["eventsTableButtons"].viewReport[language]}
                                      </button>
                                    )}
                                </td> */}
                                      {/* {(hasCreateParentForm ||
                                        hasProspectiveIndex) && ( */}
                                      {
                                        <td
                                          style={{
                                            background: `${
                                              isDarkMode ? "#435574" : "#435574"
                                            }`,
                                            borderBottom: isLastElement
                                              ? "1px solid #dee2e6"
                                              : "0px solid",
                                            paddingInline: "1px",
                                            position: "-webkit-sticky",
                                            position: "sticky",
                                            right:
                                            language === "fr"
                                              ? userType &&
                                                !internalUsers.includes(
                                                  userType
                                                )
                                                ? 0
                                                : userType === "Animator" ||  userType ==="Agent"? 0 : !hasPrivateEventDestroy &&
                                                  !userData.roles[0].permissions.some(
                                                    (item) =>
                                                      item.name ===
                                                      "Private Event Index"
                                                  )
                                                ? 0
                                                : userType === "Accountant" ? "106px" :"226px"
                                              : !hasPrivateEventDestroy &&
                                                !userData.roles[0].permissions.some(
                                                  (item) =>
                                                    item.name ===
                                                    "Private Event Index"
                                                )
                                              ? 0
                                              : userType &&
                                                !internalUsers.includes(
                                                  userType
                                                )
                                              ? 0
                                              : !hasPrivateEventDestroy
                                              ? userType === "Animator" ||  userType ==="Agent" ? 0 : "72px"
                                              : !userData.roles[0].permissions.some(
                                                  (item) =>
                                                    item.name ===
                                                    "Private Event Index"
                                                )
                                              ? "92px"
                                              : "166px",
                                          }}
                                          id="totalRow"
                                          className="my-2"
                                        >
                                          {"-"}
                                        </td>
                                      }
                                      {/* {(hasPrivateEventDestroy ||
                                        userData.roles[0].permissions.some(
                                          (item) =>
                                            item.name === "Private Event Index"
                                        )) && ( */}
                                      {userType &&
                                        internalUsers.includes(userType) && userType !=="Animator" && userType !=="Agent" && (
                                          <td
                                            style={{
                                              background: `${
                                                isDarkMode
                                                  ? "#435574"
                                                  : "#435574"
                                              }`,
                                              borderBottom: isLastElement
                                                ? "1px solid #dee2e6"
                                                : "0px solid",
                                              paddingInline: "1px",
                                              position: "-webkit-sticky",
                                              position: "sticky",
                                              width: "120px",
                                              right: "0px",
                                            }}
                                            className="my-2"
                                            id="totalRow"
                                          >
                                            {"-"}
                                          </td>
                                        )}
                                    </tr>
                                  )}
                                </>
                              );
                            })}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <tr
                        className="align-middle"
                        style={{
                          position: "relative",
                          // height: "30px",
                          // zIndex: 10 + eventList.length - index,
                        }}
                      >
                        <td
                          style={{
                            background: `${
                              isDarkMode ? "transparent" : "white"
                            }`,
                            borderStyle: "none",
                          }}
                          colSpan={15}
                          className="text-center py-5"
                        >
                          <h4>
                            {translations.EventsPortal.noDataMessage[language]}
                          </h4>
                        </td>
                      </tr>
                    )}
                    {isFetching && (
                      <tr className="skeleton-loader">
                        <th className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </th>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        {userType && internalUsers.includes(userType) && (
                          <td className="p-2">
                            <div
                              className={
                                isDarkMode ? "skeleton-dark" : "skeleton"
                              }
                            ></div>
                          </td>
                        )}
                        {/* <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td> */}
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        {userType && internalUsers.includes(userType) && (
                          <>
                            <td className="p-2">
                              <div
                                className={
                                  isDarkMode ? "skeleton-dark" : "skeleton"
                                }
                              ></div>
                            </td>
                            <td className="p-2">
                              <div
                                className={
                                  isDarkMode ? "skeleton-dark" : "skeleton"
                                }
                              ></div>
                            </td>
                            <td className="p-2">
                              <div
                                className={
                                  isDarkMode ? "skeleton-dark" : "skeleton"
                                }
                              ></div>
                            </td>
                          </>
                        )}
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>

                        {/* {(hasCreateParentForm || hasProspectiveIndex) && ( */}
                        {
                          <td className={` ${"p-2"}`}>
                            <div
                              className={
                                isDarkMode ? "skeleton-dark" : "skeleton"
                              }
                            ></div>
                          </td>
                        }
                        {/* {hasPrivateEventDestroy ||
                          (userData.roles[0].permissions.some(
                            (item) => item.name === "Private Event Index"
                          ) && ( */}
                        {userType && internalUsers.includes(userType) && (
                          <td
                            className={` ${
                              hasPrivateEventDestroy ||
                              userData.roles[0].permissions.some(
                                (item) => item.name === "Private Event Index"
                              )
                                ? "p-2"
                                : "p-0"
                            }`}
                          >
                            <div
                              className={
                                isDarkMode ? "skeleton-dark" : "skeleton"
                              }
                            ></div>
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
                <div ref={bottomMarkerRef} style={{ height: "1px" }} />
              </div>

              {/* {events &&
                Object.keys(events).length > 0 &&
                total !== 0 &&
                total > 30 && (
                  <PaginatedItems
                    total={total}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    handlePageChange={handlePageChange}
                    isDarkMode={isDarkMode}
                    itemsPerPage={30}
                  />
                )} */}
            </div>
          </div>

          <AddWeekModal
            handleCopy={handleCopy}
            eventId={eventId}
            week={week}
            setWeek={setWeek}
            setEventId={setEventId}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Forms;
