import React, { useEffect, useState } from "react";
import { useLanguage } from "../../languageContext";
import translations from "../../translation.json";
import { toast } from "react-toastify";
import { ApiUrl } from "../../ApiUrl";
import { format, formatDistanceToNow, max, min, parseISO } from "date-fns";
import { usePDF } from "react-to-pdf";
import { internalUsers } from "../../constants/userTypes";

const EventReport2 = ({
  isDarkMode,
  setDisplayComponent,
  setPrevDisplay,
  setIsLoading,
  isLoading,
}) => {
  const { language } = useLanguage();
  const userType = localStorage.getItem("userType");
  const [eventId, setEventId] = useState(null);
  const [reportData, setEventReportData] = useState(null);
  const [commenttData, setCommentData] = useState("");
  const token = localStorage.getItem("token");
  const { toPDF, targetRef } = usePDF({ filename: "Event-Report.pdf" });

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await fetch(ApiUrl + "/api/user/event-report", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            private_event_id: eventId,
          }),
        });
        const data = await res.json();
        if (data.status === "success") {
          // const newData = data.private_events.data;
          setEventReportData(data);

          // autoScrollMid();
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    if (token && eventId) {
      fetchData();
    }
  }, [eventId]);

  const sendComment = async () => {
    setIsLoading(true);

    try {
      const url = `${ApiUrl}/api/user/event-report-pdf`;
      const bodyData = {
        private_event_id: eventId,
        comment: commenttData,
      };
      const method = "POST";
      const res = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      // console.log("res", res)
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        setEventReportData({
          ...reportData,
          private_event: {
            ...reportData.private_event,
            comments: [...reportData.private_event.comments, data?.comment],
          },
        });

        setCommentData("");

        // navigate("/event-portal");
        // window.history.back();
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const getPDF = async () => {
    setIsLoading(true);

    try {
      const url = `${ApiUrl}/api/user/download-event-report-pdf/${eventId}`;
      const method = "GET";
      const res = await fetch(url, {
        method,
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });
      // console.log("res", res)
      // const data = await res.json();
      const blob = await res.blob(); // Get the binary data
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlBlob;
      link.setAttribute("download", "Event-Report-" + eventId + ".pdf"); // Set the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  return (
    <div>
      <div className="container maincont">
        <div id="app" className="appcountainer2" ref={targetRef}>
          <div className="row align-items-center justify-content-between">
            <div className="col-7 d-flex gap-3 align-items-center">
              <div>
                <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                  {translations.viewEventReport.title[language]}
                  {" - "}
                  {reportData?.private_event?.dealer_ship?.name}
                  {": "}
                  {/* {reportData && reportData.prospect_sources.length > 0
                    ? format(
                        max(
                          reportData?.prospect_sources.map((date) =>
                            parseISO(date?.appointment_date)
                          )
                        ), // Convert to Date objects
                        "dd/MM/yyyy"
                      )
                    : "N/A"}{" "} */}
                  {reportData?.private_event?.start_date}{" "}
                  {translations.commonWords.to[language]}{" "}
                  {reportData?.private_event?.end_date}
                  {/* {reportData && reportData.prospect_sources.length > 0
                    ? format(
                        min(
                          reportData.prospect_sources?.map((date) =>
                            parseISO(date?.appointment_date)
                          )
                        ), // Convert to Date objects
                        "dd/MM/yyyy"
                      )
                    : "N/A"} */}
                </h2>
              </div>
            </div>
            <div className="col-5 gap-1 d-flex align-items-center justify-content-end">
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary"
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalQuickAdd"
                  // fdprocessedid="2rud2c"
                  onClick={() => {
                    setDisplayComponent("event-view");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      "/event-portal?page=event-view&event_id=" + eventId
                    );
                  }}
                >
                  <i className="bi bi-speedometer"></i>{" "}
                  {translations.eventView.buttons.dashboard[language]}
                </button>
              </div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary"
                  onClick={() => {
                    setDisplayComponent("appointments");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      `/event-portal?page=appointments&eventId=${eventId}`
                    );
                  }}
                >
                  <i className="bi bi-calendar2-range"></i>{" "}
                  {translations.eventView.buttons.calender[language]}
                </button>
              </div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary"
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalQuickAdd"
                  // fdprocessedid="2rud2c"
                  onClick={() => {
                    setDisplayComponent("clients");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      `/event-portal?page=clients&eventId=${eventId}`
                    );
                  }}
                >
                  <i className="bi bi-people"></i>{" "}
                  {translations.forms.formFields.clients[language]}
                </button>
              </div>
              <div
                className="btn-group d-flex align-items-center gap-1"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary d-flex align-items-center gap-1"
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalQuickAdd"
                  // fdprocessedid="2rud2c"
                  onClick={() => {
                    setDisplayComponent("copy-client");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      `/event-portal?page=copy-client&eventId=${eventId}`
                    );
                  }}
                >
                  <i className="bi bi-clipboard-plus"></i>{" "}
                  {translations.forms.formFields.copyClient[language]}
                </button>
              </div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  //   className="btn admin-btn btn-primary"
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalQuickAdd"
                  // fdprocessedid="2rud2c"
                  style={{
                    background: "#05cc9e",
                    // color: "white",
                    paddingBlock: "0.375rem",
                    paddingInline: "0.75rem",
                    borderRadius: "0.375rem",
                    borderColor: "#05cc9e",
                    borderStyle: "solid",
                  }}
                >
                  <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                  {translations.viewEventReport.title[language]}
                </button>
              </div>
            </div>
          </div>
          <div className="my-2 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center flex-wrap gap-4 ">
              <h5 id="h1" className={``}>
                Client {": "} {reportData?.private_event?.clients}
              </h5>
              <h5 id="h1" className={``}>
                {translations.forms.conquestBtn[language]} {": "}{" "}
                {reportData?.private_event?.conquest}
              </h5>
            </div>
            <div className="">
              {" "}
              {reportData && reportData?.prospect_sources.length > 0 && (
                <button
                  type="button"
                  className="admin-btn d-flex align-items-center gap-2"
                  onClick={getPDF}
                >
                  <i className="bi bi-download"></i>
                  {translations.commonWords.downloadPDF[language]}{" "}
                </button>
              )}
            </div>
          </div>
          {!isLoading && (
            <div className="row">
              <div
                className={`${
                  userType && internalUsers.includes(userType)
                    ? "col-9"
                    : "col-12"
                }`}
                // style={{
                //   display: "flex",
                //   flexDirection: "column",
                //   justifyContent: "space-between",
                // }}
                // ref={containerRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflowX: "auto",
                  //   justifyContent: "space-between",
                  // height: "calc(100vh - 260px)",
                }}
              >
                {reportData && reportData?.prospect_sources.length > 0 ? (
                  <>
                    <table
                      className={`table table-hover text-center align-middle reportTable`}
                    >
                      <thead
                        style={{ position: "sticky", top: "0", zIndex: "1" }}
                      >
                        <tr>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                            style={{
                              width: "20%",
                            }}
                          >
                            {
                              translations.eventReport2.appointmentSource[
                                language
                              ]
                            }
                          </th>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <th
                                scope="col"
                                className={`${isDarkMode ? "darkthead" : ""}`}
                                style={{
                                  minWidth:
                                    language === "en" ? "125px" : "150px",
                                  width: "10%",
                                }}
                              >
                                {item?.appointment_date}
                              </th>
                            ))}

                          {/* <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "135px" : "195px",
                      }}
                    >
                      {translations.clients.table.noShowStatus[language]}
                    </th> */}
                          <th
                            scope="col"
                            colSpan={2}
                            className={`${isDarkMode ? "darkthead" : ""}`}
                            style={{
                              width: "20%",
                            }}
                          >
                            Total
                          </th>
                          {/* <th scope="col"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="dlrname">Fidgi - CA</td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.one_Fidgi_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.one_Fidgi_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {
                              translations.commonWords.usersTypes.animator[
                                language
                              ]
                            }
                          </td>

                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.one_Animator_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum +
                                  (Number(event?.one_Animator_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {translations.forms.conquestBtn[language]}
                          </td>

                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.one_Conquest_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum +
                                  (Number(event?.one_Conquest_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {
                              translations.eventReport2.totalAppointments[
                                language
                              ]
                            }
                          </td>

                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td
                                className="dlrname text-white"
                                style={{
                                  //   borderBottom: "1px solid #dee2e6",
                                  background: `${
                                    isDarkMode ? "#435574" : "#435574"
                                  }`,
                                }}
                              >
                                {item?.one_total_count}{" "}
                              </td>
                            ))}

                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.one_total_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {
                              translations.eventReport2.appointmentsConfirmed[
                                language
                              ]
                            }
                          </td>

                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td
                                className="dlrname text-white"
                                style={{
                                  //   borderBottom: "1px solid #dee2e6",
                                  background: `${
                                    isDarkMode ? "#435574" : "#435574"
                                  }`,
                                }}
                              >
                                {item?.confirmed_count}{" "}
                              </td>
                            ))}

                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.confirmed_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-hover text-center align-middle mt-4 reportTable">
                      <thead
                        style={{ position: "sticky", top: "0", zIndex: "1" }}
                      >
                        <tr>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                            style={{
                              width: "20%",
                            }}
                          >
                            {translations.eventReport2.presenceSource[language]}
                          </th>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <th
                                scope="col"
                                className={`${isDarkMode ? "darkthead" : ""}`}
                                style={{
                                  minWidth:
                                    language === "en" ? "125px" : "150px",
                                  width: "10%",
                                }}
                              >
                                {item?.appointment_date}
                              </th>
                            ))}
                          <th
                            scope="col"
                            colSpan={2}
                            className={`${isDarkMode ? "darkthead" : ""}`}
                            style={{
                              width: "20%",
                            }}
                          >
                            Total
                          </th>
                          {/* <th scope="col"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="dlrname">Fidgi - CA</td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.two_Fidgi_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.two_Fidgi_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {
                              translations.commonWords.usersTypes.animator[
                                language
                              ]
                            }
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.two_Animator_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum +
                                  (Number(event?.two_Animator_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {translations.forms.conquestBtn[language]}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.two_Conquest_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum +
                                  (Number(event?.two_Conquest_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {/* {translations.eventReport2.totalAppointments[language]} */}
                            Total Fidgi
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td
                                className="dlrname text-white"
                                style={{
                                  //   borderBottom: "1px solid #dee2e6",
                                  background: `${
                                    isDarkMode ? "#435574" : "#435574"
                                  }`,
                                }}
                              >
                                {item?.two_t_count}{" "}
                              </td>
                            ))}

                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.two_t_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {translations.eventReport2.presenceIn[language]}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td
                                className="dlrname text-white"
                                style={{
                                  //   borderBottom: "1px solid #dee2e6",
                                  background: `${
                                    isDarkMode ? "#435574" : "#435574"
                                  }`,
                                }}
                              >
                                {Number(
                                  item?.percentage_of_appointment_total
                                ).toFixed(2)}
                                {"% "}
                              </td>
                            ))}

                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 && (
                                <>
                                  {(
                                    (reportData.prospect_sources.reduce(
                                      (sum, event) =>
                                        sum + (Number(event?.two_t_count) || 0),
                                      0
                                    ) /
                                      reportData.prospect_sources.reduce(
                                        (sum, event) =>
                                          sum +
                                          (Number(event?.one_total_count) || 0),
                                        0
                                      )) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </>
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {/* {translations.eventReport2.presenceIn[language]} */}
                            Walkin
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td
                                className="dlrname text-white"
                                style={{
                                  //   borderBottom: "1px solid #dee2e6",
                                  background: `${
                                    isDarkMode ? "#435574" : "#435574"
                                  }`,
                                }}
                              >
                                {item?.Walk_in_count}{" "}
                              </td>
                            ))}

                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.Walk_in_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {translations.eventReport2.totalPresences[language]}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td
                                className="dlrname text-white"
                                style={{
                                  //   borderBottom: "1px solid #dee2e6",
                                  background: `${
                                    isDarkMode ? "#435574" : "#435574"
                                  }`,
                                }}
                              >
                                {item?.two_total_count}{" "}
                              </td>
                            ))}

                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.two_total_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-hover text-center align-middle mt-4 reportTable">
                      <thead
                        style={{ position: "sticky", top: "0", zIndex: "1" }}
                      >
                        <tr>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                            style={{
                              width: "20%",
                            }}
                          >
                            {translations.eventReport2.salesSource[language]}
                          </th>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <th
                                scope="col"
                                className={`${isDarkMode ? "darkthead" : ""}`}
                                style={{
                                  minWidth:
                                    language === "en" ? "125px" : "150px",
                                  width: "10%",
                                }}
                              >
                                {item?.appointment_date}
                              </th>
                            ))}
                          {/* <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "135px" : "195px",
                      }}
                    >
                      {translations.clients.table.noShowStatus[language]}
                    </th> */}
                          <th
                            scope="col"
                            colSpan={2}
                            className={`${isDarkMode ? "darkthead" : ""}`}
                            style={{
                              width: "20%",
                            }}
                          >
                            Total
                          </th>
                          {/* <th scope="col"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="dlrname">
                            {translations.eventReport2.newFidgi[language]}{" "}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.new_fidgi_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.new_fidgi_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {translations.eventReport2.usedFidgi[language]}{" "}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.use_fidgi_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.use_fidgi_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {translations.eventReport2.newAnimator[language]}{" "}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.new_animator_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum +
                                  (Number(event?.new_animator_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {translations.eventReport2.usedAnimator[language]}{" "}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.use_animator_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum +
                                  (Number(event?.use_animator_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {translations.eventReport2.newConquest[language]}{" "}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.new_conquest_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum +
                                  (Number(event?.new_conquest_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {translations.eventReport2.usedConquest[language]}{" "}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.use_conquest_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum +
                                  (Number(event?.use_conquest_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {translations.eventReport2.newDealership[language]}{" "}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.new_concession_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum +
                                  (Number(event?.new_concession_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {translations.eventReport2.usedDealership[language]}{" "}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.use_concession_count}{" "}
                              </td>
                            ))}

                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum +
                                  (Number(event?.use_concession_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {translations.eventReport2.totalNew[language]}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td
                                className="dlrname text-white"
                                style={{
                                  //   borderBottom: "1px solid #dee2e6",
                                  background: `${
                                    isDarkMode ? "#435574" : "#435574"
                                  }`,
                                }}
                              >
                                {item?.new_used_count}{" "}
                              </td>
                            ))}
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.new_used_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {translations.eventReport2.totalUsed[language]}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td
                                className="dlrname text-white"
                                style={{
                                  //   borderBottom: "1px solid #dee2e6",
                                  background: `${
                                    isDarkMode ? "#435574" : "#435574"
                                  }`,
                                }}
                              >
                                {item?.use_used_count}{" "}
                              </td>
                            ))}
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.use_used_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {translations.eventReport2.totalSales[language]}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td
                                className="dlrname text-white"
                                style={{
                                  //   borderBottom: "1px solid #dee2e6",
                                  background: `${
                                    isDarkMode ? "#435574" : "#435574"
                                  }`,
                                }}
                              >
                                {item?.total_new_used_count}{" "}
                              </td>
                            ))}
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum +
                                  (Number(event?.total_new_used_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="dlrname">
                            {translations.eventReport2.noShow[language]}{" "}
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td className="dlrname">
                                {item?.no_show_count}{" "}
                              </td>
                            ))}
                          <td className="dlrname">
                            {reportData &&
                              reportData.prospect_sources &&
                              reportData.prospect_sources.length > 0 &&
                              reportData.prospect_sources.reduce(
                                (sum, event) =>
                                  sum + (Number(event?.no_show_count) || 0),
                                0
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            {
                              translations.eventReport2.closinginPersent[
                                language
                              ]
                            }
                          </td>
                          {reportData &&
                            reportData.prospect_sources &&
                            reportData.prospect_sources.length > 0 &&
                            reportData.prospect_sources.map((item, index) => (
                              <td
                                className="dlrname text-white"
                                style={{
                                  //   borderBottom: "1px solid #dee2e6",
                                  background: `${
                                    isDarkMode ? "#435574" : "#435574"
                                  }`,
                                }}
                              >
                                {Number(item?.new_used_percentage).toFixed(2)}
                                {"% "}
                              </td>
                            ))}
                          <td
                            className="dlrname text-white"
                            style={{
                              //   borderBottom: "1px solid #dee2e6",
                              background: `${
                                isDarkMode ? "#435574" : "#435574"
                              }`,
                            }}
                          >
                            <>
                              {(
                                (reportData?.prospect_sources.reduce(
                                  (sum, event) =>
                                    sum +
                                    (Number(event?.total_new_used_count) || 0),
                                  0
                                ) /
                                  reportData?.prospect_sources.reduce(
                                    (sum, event) =>
                                      sum +
                                      (Number(event?.two_total_count) || 0),
                                    0
                                  )) *
                                100
                              ).toFixed(2)}
                              %
                            </>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : (
                  <div className="d-flex justify-content-center py-4">
                    <h4>{translations.EventsPortal.noDataMessage[language]}</h4>
                  </div>
                )}
              </div>
              {userType && internalUsers.includes(userType) && (
                <div
                  className={`col-3 py-3`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "auto",
                    // maxHeight:"50vh",
                    //   justifyContent: "space-between",
                    height: "calc(100vh - 260px)",
                  }}
                >
                  <h4 id="h1" className={""}>
                    {translations.viewEventReport.commentSection[language]}
                  </h4>
                  <div className="my-3 d-flex flex-column gap-3">
                    <label for="annual-sales-count" className="form-label">
                      {translations.commonWords.comment[language]}
                    </label>
                    <div
                      className="d-flex flex-column gap-3"
                      // style={{ width: "500px" }}
                    >
                      <textarea
                        type="text"
                        className="form-control"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          border: isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6",
                        }}
                        value={commenttData}
                        onChange={(e) => {
                          setCommentData(e.target.value);
                        }}
                      />
                      <button
                        className="client-btn"
                        disabled={commenttData === ""}
                        style={{ maxWidth: "100px" }}
                        onClick={sendComment}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                  {reportData &&
                  reportData?.private_event?.comments.length > 0 ? (
                    <div
                      className=""
                      style={{
                        maxHeight: "calc(100% - 50px)",
                        overflowY: "auto",
                      }}
                    >
                      {reportData?.private_event?.comments.map(
                        (comment, index) => (
                          <div
                            key={index}
                            className="d-flex justify-content-start my-4 me-2"
                            style={{
                              maxWidth: "100%", // Adjust for bubble width
                              borderRadius: "6px", // Rounded corners for the bubble
                              padding: "10px 15px",
                              backgroundColor: isDarkMode
                                ? "#343a40"
                                : "#f7f7f7",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                              position: "relative", // For the tail
                              alignSelf: "flex-start", // Align to the left
                            }}
                          >
                            <div
                              className="d-flex justify-content-start"
                              style={{ flexDirection: "column", width: "100%" }}
                            >
                              <p className="fw-bold">{comment?.user?.name} </p>
                              <div className="pt-2">
                                <p className="">{comment?.comment}</p>
                                <div className="d-flex justify-content-end">
                                <p className="" style={{ fontSize: "12px" }}>
                                  {comment.date
                                    ? format(
                                        new Date(comment.date),
                                        "dd MMMM yyyy, HH:mm a"
                                      )
                                    : // formatDistanceToNow(new Date(comment.date), { addSuffix: true })
                                      ""}
                                </p>
                                </div>
                              </div>
                            </div>

                            {/* Tail of the bubble */}
                            <div
                              style={{
                                content: "''",
                                position: "absolute",
                                bottom: "-10px",
                                left: "20px",
                                width: "0",
                                height: "0",
                                borderStyle: "solid",
                                borderWidth: isDarkMode
                                  ? "10px 10px 0 10px"
                                  : "10px 10px 0 10px",
                                borderColor: isDarkMode
                                  ? "#343a40 transparent transparent transparent"
                                  : "#f7f7f7 transparent transparent transparent",
                              }}
                            ></div>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center py-4">
                      <h5>
                        {translations.EventsPortal.noDataMessage[language]}
                      </h5>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventReport2;
