import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import PaginatedItems from "./Pagination";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import AddWeekModal from "./addWeekModal";
import Loader from "./loader";
import { Field } from "formik";
import Select from "react-select";
import CreateFormModal from "./createFormModal";
import EditEventModal from "./ModalAddDealer_CreateEvent";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import debounce from "lodash/debounce";
import FilterBar from "./components/filterBar";
const EventReport = ({
  setDisplayComponent,
  eventTrigger,
  setEditEventID,
  setIsDarkMode,
  isDarkMode,
  isLoading,
  setIsLoading,
  users,
  dealerships,
  setPrevDisplay,
  events,
  setEvents,
  key,
  setKey,
  total,
  setTotal,
  searchCache,
  setSearchCache,
  pageCache,
  setPageCache,
  deleteStates,
  setDeleteStates,
  pageNo,
  setPageNo,
  // setDealerships2,
  selectedOption
}) => {
  const { language } = useLanguage();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const pageTranslations = translations["EventsPortal"];
  const filterOptions = [
    {
      label: translations.EventsPortal.filterOptions.currentYear[language],
      value: "currentYear",
    },
    {
      label: "Archive",
      value: "previousYear",
    },
    {
      label: translations.EventsPortal.filterOptions.futureYear[language],
      value: "future",
    },
    {
      label:
        translations.usersPage.createUser.statusOptions.cancelled[language],
      value: "cancel",
    },
  ];
  const token = localStorage.getItem("token");
  // const [events, setEvents] = useState(null);
  const [eventsSearch, setEventsSearch] = useState();
  const [editID, seteditID] = useState();
  // const [pageCache, setPageCache] = useState({});
  // const [searchCache, setSearchCache] = useState({});
  // const [pageNo, setPageNo] = useState(1);
  // const [total, setTotal] = useState();
  const [activeSearch, setActiveSearch] = useState(false);
  // const [key, setKey] = useState("");
  const [eventId, setEventId] = useState("");
  const [parentId, setParentId] = useState("");
  const [week, setWeek] = useState("");
  const [loading, setLoading] = useState("");
  const [filterOption, setFilterOption] = useState(filterOptions[0].value);
  // const [deleteStates, setDeleteStates] = useState();
  const [oldData, setOldData] = useState(null);
  const [brands, setBrands] = useState();
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const containerRef = useRef(null);
  const topMarkerRef = useRef(null);
  const bottomMarkerRef = useRef(null);
  const [allowScrollFetch, setAllowScrollFetch] = useState(true);
  const [initialScrollDone, setInitialScrollDone] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [dataEnd, setDataEnd] = useState(false);
  const [checkedFields, setCheckedFields] = useState([]);
  const [repOptions2, setrepOptions2] = useState([]);
  const [agents, setagents] = useState([]);
  const [managers, setmanagers] = useState([]);
  const [packageOptions, setpackageOptions] = useState([]);
  const repOptions =
    users &&
    users.representatives.length > 0 &&
    users.representatives.map((user) => ({
      value: user.id,
      label: user.name,
    }));

  const allfields = [
    // {
    //   content: "ID",
    //   name: "user_id",
    //   type: "text",
    //   filterType:"",
    //   value:""
    // },
    {
      content: translations.forms.formFields.week[language],
      name: "week",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.name[language],
      name: "nom_vp",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.quickCreationModal.dealership[language],
      name: "dealership",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.projectManager[language],
      name: "project_manager",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.sales_representative[language],
      name: "sales_rep",
      type: "dropdown",
      filterType: "",
      value: "",
    },

    {
      content: translations.forms.privateEventformFields.package[language],
      name: "package",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.privateEventformFields.startDate[language],
      name: "start_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.privateEventformFields.endDate[language],
      name: "end_date",
      type: "date",
      filterType: "",
      value: "",
    },

    // {
    //   content: translations.usersPage.createUser.status[language],
    //   name: "status",
    //   type: "text",
    // },

    {
      content: translations.forms.formFields.animator[language],
      name: "animator",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.mailing[language],
      name: "mailing",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.web[language],
      name: "web",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.banner[language],
      name: "banner",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.newsletter[language],
      name: "news_letter",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.facebook[language],
      name: "facebook",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.adwords[language],
      name: "ad_words",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.clients[language],
      name: "clients",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.inactive[language],
      name: "inactive",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.conquestSection.title[language],
      name: "conquest",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.massif[language],
      name: "massif",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.amount[language],
      name: "amount",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.confirmationAgent[language],
      name: "confirmation_agent",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.noShowAgent[language],
      name: "no_show_agent",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.carton[language],
      name: "carton",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: "SMS",
      name: "sms",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.quickCreationModal.brand[language],
      name: "brand",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.amountAnimator[language],
      name: "amount_animator",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.invoiceAnimator[language],
      name: "invoice_animator",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.commissionPaid[language],
      name: "commission_paid",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.instructions[language],
      name: "instructions",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content:
        translations.forms.privateEventformFields.promoAndRequestSection
          .comments[language],
      name: "comments",
      type: "text",
      filterType: "",
      value: "",
    },
  ];
  const textOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const weekOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
    {
      label: translations.commonFields.queryFilters.between[language],
      value: "between",
    },
  ];

  const textOptions2 = [
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const animatorOptions =
    users &&
    users.animaters.length > 0 &&
    users.animaters.map((user) => ({
      value: user.id,
      label: user.name,
    }));
  const dealerOptions =
    dealerships &&
    dealerships.length > 0 &&
    dealerships.map((dealer) => ({
      value: dealer.id,
      label: dealer.name,
    }));

  const handleSelectAllChange = () => {
    if (selectAll) {
      // Deselect all
      setSelectedWeeks([]);
    } else {
      // Select all
      const allWeeks = Object.keys(events);
      setSelectedWeeks(allWeeks);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (weekKey) => {
    setSelectedWeeks((prevSelected) =>
      prevSelected.includes(weekKey)
        ? prevSelected.filter((key) => key !== weekKey)
        : [...prevSelected, weekKey]
    );
  };

  const exportToExcel = () => {
    const selectedData = selectedWeeks
      .map((weekKey) => events[weekKey])
      .flat()
      .map((event) => ({
        Week: `Week ${event.week}`,
        ID: event?.id || "Null",
        Name: event.nom_vp,
        StartDate: event.start_date,
        EndDate: event.end_date,
        Status: event?.status || "Null",
        Dealership: event?.dealership?.name || "Null",
        Seller: event?.sales_rep?.name || "Null",
        Package: event.package?.name || "Null",
        Remarks: event?.comments || "Null",
        Animator: event?.animator?.name || "Null",
        PostalInvite: event?.postal_invitation || "",
        Web: event?.web || "Null",
        Banner: event?.banner || "Null",
        Newsletter: event?.news_letter || "Null",
        Facebook: event?.facebook || "Null",
        AdWords: event?.ad_words || "Null",
        SMS: event?.sms || "Null",
        Clients: event?.clients || "Null",
        Inactive: event?.inactive || "Null",
        Conquest: event?.conquest || "Null",
        Massif: event?.massif || "Null",
        Amount: event?.amount || "Null",
        ConfirmationAgent: event?.confirmation_agent?.name || "Null",
        ConfirmationAgentID: event?.confirmation_agent?.id || "",
        NoShowAgent: event?.no_show_agent?.name || "Null",
        NoShowAgentID: event?.no_show_agent?.id || "",
        Carton: event?.carton || "Null",
        CreatedBy: event?.created_by || "Null",
      }));

    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Weeks Data");

    XLSX.writeFile(
      workbook,
      `Event Report ${format(new Date(), "dd MMMM yyyy")}.xlsx`
    );
  };
  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setEvents(cachedResponse);
        setEventsSearch(cachedResponse);
        setTotal(cachedTotal);
      } else {
        try {
          setLoading(true);
          const res = await fetch(
            ApiUrl + "/api/user/private-events-index?page=" + pageNo,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // console.log("Get Events res: ", res);
          const data = await res.json();
          // console.log("Get Events data: ", data)
          // console.log(result)
          if (data.status === "success") {
            const newData = data.private_events;
            const newTotal = data.private_events?.total;
            // const dealerData = data.dealerships && data.dealerships.length > 0 && data.dealerships.map((item)=> {return {label: item.name,value:item.id}});
            // setDealerships2(dealerData)
            if (Object.keys(newData).length === 0) {
              setDataEnd(true);
            }
            // Update the cache with new data
            // Set state with new data
            setEvents(newData);
            setEventsSearch(newData);
            setTotal(newTotal);
            setPageCache((prevCache) => ({
              ...prevCache,
              [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
            }));
            setrepOptions2(
              data &&
                data.data.representative.length > 0 &&
                data.data.representative.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))
            );
            setagents(
              data &&
                data.data.agents.length > 0 &&
                data.data.agents.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))
            );
            setmanagers(
              data &&
                data.data.projectManagers.length > 0 &&
                data.data.projectManagers.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))
            );
            setpackageOptions(
              data &&
                data.data.packages.length > 0 &&
                data.data.packages.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))
            );

            setDeleteStates(
              newData &&
                Object.keys(newData).map((key) =>
                  newData[key].map((item) => ({ [item.id]: false }))
                )
            );
            autoScrollMid();
          } else {
            toast.error(data.message);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toast.error("Something went wrong");
        } finally {
        }
      }
    };

    if (!activeSearch && token) {
      fetchData();
    }
    const userData = JSON.parse(localStorage.getItem("userData"));
    setIsDarkMode(userData.theme_mode !== "light" ? true : false);
  }, [pageNo, pageCache, events, eventTrigger]);

  useEffect(() => {
    if (eventTrigger) {
      const newData = eventTrigger.data;
      const newTotal = eventTrigger.total;

      // Update the cache with new data
      setPageCache((prevCache) => ({
        ...prevCache,
        [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
      }));
      // Set state with new data
      setEvents(newData);
      setEventsSearch(newData);
      setTotal(newTotal);
    }
  }, [eventTrigger]);

  const handleCopy = async () => {
    const page =
      events.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
    const searchParam = key ? "&search_parameter=" + key : "";
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl + "/api/user/copy-parentform?page=" + page + "" + searchParam,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            parent_id: parentId,
            // week: week,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        setDisplayComponent("edit-form");
        window.history.pushState(
          {
            id: "gallery",
            randomData: window.Math.random(),
          },
          "title",
          `/event-portal?page=edit-form&event_id=${eventId}&form_id=${data.event_url}`
        );
        // toast.success("Event copied successfully");
        // setDisplayComponent("edit-event");
        // window.history.pushState(
        //   {
        //     id: "gallery",
        //     randomData: window.Math.random(),
        //   },
        //   "title",
        //   "/event-portal?page=edit-event&parent_id=" + data.event_url
        // );
        // const newData = data.event_list.data;
        // const newTotal = data.event_list.total;
        // setEvents(newData);
        // setTotal(newTotal);
        // if (key) {
        //   setSearchCache({
        //     [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        //   });
        // } else {
        //   setPageCache({
        //     [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        //   });
        // }
        // if (activeSearch) {
        //   setPageNo(
        //     events && Object.keys(events).length > 1
        //       ? pageNo
        //       : pageNo - 1 > 0
        //       ? pageNo - 1
        //       : pageNo
        //   );
        // }
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleSearch = async (pageNo, initial) => {
    if (!initial && searchCache[pageNo]) {
      // Data already in cache, no need for API call
      const { cachedResponse, cachedTotal } = searchCache[pageNo];
      setEvents(cachedResponse);
      setTotal(cachedTotal);
    } else {
      // setFilterLoading(true);
      // setGetLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/private-events-index/" + key + "?page=" + pageNo,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          setActiveSearch(true);

          const newData = data.private_events;
          const newTotal = data.private_events?.total;
          // Update the cache with new data
          setSearchCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));
          // Set state with new data
          setEvents(newData);
          setEventsSearch(newData);
          setTotal(newTotal);
          // if (pageNo === 1) toast.success("Search successful");
          // setFilterLoading(false);
          // setGetLoading(false);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error("Something went wrong");
        // setFilterLoading(false);
        // setGetLoading(false);
      }
    }
  };

  const filterEvents = async (filterType) => {
    setIsLoading(true);
    try {
      const res = await fetch(
        ApiUrl + "/api/user/private-events-index/" + filterType,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);

        const newData = data.private_events;
        const newTotal = data.private_events.total;
        containerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        const sortedUpdatedEvents =
          Object.keys(newData) &&
          Object.keys(newData).length > 0 &&
          Object.keys(newData)
            .sort(
              (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
            ) // Sort based on week number
            .reduce((sortedObj, week) => {
              sortedObj[week] = newData[week];
              return sortedObj;
            }, {});

        setEvents(sortedUpdatedEvents);
        setTotal(newTotal);

        if (key) {
          setSearchCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
        } else {
          setPageCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
          setDeleteStates(
            sortedUpdatedEvents &&
              Object.keys(sortedUpdatedEvents).map((key) =>
                sortedUpdatedEvents[key].map((item) => ({ [item.id]: false }))
              )
          );
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };

  const handlePageChange = (page) => {
    if (activeSearch) handleSearch(page);
  };

  const getFilteredEvents = async (filterPost) => {
    // setIsLoading(true);
    setAllowScrollFetch(false);
    try {
      const res = await fetch(ApiUrl + "/api/user/private-events-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterPost),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);

        // const newData = data.private_events.data;
        const newData = data.private_events;
        const newTotal = data.private_events.total;
        const sortedUpdatedEvents =
          Object.keys(newData) &&
          Object.keys(newData).length > 0 &&
          Object.keys(newData)
            .sort(
              (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
            ) // Sort based on week number
            .reduce((sortedObj, week) => {
              sortedObj[week] = newData[week];
              return sortedObj;
            }, {});

        setEvents(sortedUpdatedEvents);
        setTotal(newTotal);

        if (key) {
          setSearchCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
        } else {
          setPageCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
          setDeleteStates(
            sortedUpdatedEvents &&
              Object.keys(sortedUpdatedEvents).map((key) =>
                sortedUpdatedEvents[key].map((item) => ({ [item.id]: false }))
              )
          );
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
      setAllowScrollFetch(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageCache((prevCache) => ({
        ...prevCache,
        pageNo: pageNo,
      }));
      setPageNo(1);
      setSearchCache({});
      // handleSearch(1, true);
    }, 500);

    return () => clearTimeout(timer);
  }, [key]);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-brands", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          setBrands(data.list_of_brands);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
        // Handle error or show a toast if needed
      }
    };
    const getFiltersData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-fillter", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const filteredFields = allfields.filter(
            (field) => data.fillter[field.name] === 1
          );

          setCheckedFields(filteredFields);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
        // Handle error or show a toast if needed
      }
    };
    getBrands();
    getFiltersData();
  }, [token]);

  const fetchData2 = async (scrollType, week) => {
    try {
      // setLoading(true);
      const res = await fetch(ApiUrl + "/api/user/private-events-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          setweek: week,
          scrollType: scrollType,
          type: filterOption ? filterOption : "",
          // [filterOption === "cancel" ? "cancelYear" : "previousYear"]:
          //   selectedYear ? selectedYear : "",
          dealership_id: selectedOption && selectedOption.value ? selectedOption.value : "",
        }),
      });
      // console.log("Get Events res: ", res);
      const data = await res.json();
      // console.log("Get Events data: ", data)
      // console.log(result)
      if (data.status === "success") {
        // const newData = data.private_events.data;
        const newData = data.private_events;

        // Set events state
        const updatedEvents = { ...events };

        // Loop through the keys of newData and update the state
        if (Object.keys(newData) && Object.keys(newData).length > 0) {
          Object.keys(newData).length > 0 &&
            Object.keys(newData).forEach((week) => {
              // If the week already exists in events
              if (updatedEvents[week]) {
                // Filter out duplicate entries based on 'id'
                const uniqueNewEntries = newData[week].filter(
                  (newEntry) =>
                    !updatedEvents[week].some(
                      (existingEntry) => existingEntry.id === newEntry.id
                    )
                );

                // Concatenate the non-duplicate entries
                updatedEvents[week] = [
                  ...updatedEvents[week],
                  ...uniqueNewEntries,
                ];
              } else {
                // If the week does not exist, add the new week and its data
                updatedEvents[week] = newData[week];
              }
            });
        }
        if (Object.keys(newData).length === 0) {
          setDataEnd(true);
        }
        const sortedUpdatedEvents =
          Object.keys(updatedEvents) &&
          Object.keys(updatedEvents).length > 0 &&
          Object.keys(updatedEvents)
            .sort(
              (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
            ) // Sort based on week number
            .reduce((sortedObj, week) => {
              sortedObj[week] = updatedEvents[week];
              return sortedObj;
            }, {});

        // Set the updated events
        setEvents(sortedUpdatedEvents);

        // setEvents(newData);
        // setEventsSearch(newData);
        // setTotal(newTotal);
        // const weekUp = weeks.slice(0, midIndex1);
        // const weekDown = weeks.slice(midIndex2);

        // // Flatten data for before and after middle weeks
        // const weekUpData = weekUp.flatMap((week) => newData[week]);
        // const weekDownData = weekDown.flatMap((week) => newData[week]);
        // setWeekRemDn(weekUpData);
        // setWeekRemDn(weekDownData);

        setDeleteStates(
          sortedUpdatedEvents &&
            Object.keys(sortedUpdatedEvents).map((key) =>
              sortedUpdatedEvents[key].map((item) => ({ [item.id]: false }))
            )
        );
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };

  const SCROLL_TRIGGER_OFFSET = 1000; // Pixels before the top or bottom marker to trigger the fetch
  // const SCROLL_THRESHOLD_PERCENT = 0.1;

  const fetchDataOnScroll = async (direction) => {
    if (isFetching || !allowScrollFetch || dataEnd) return; // Prevent fetch if already fetching or scroll fetching is disabled

    setIsFetching(true);
    try {
      if (direction === "up") {
        console.log("Fetching data for upper weeks.");
        await fetchData2("up", Object.keys(events)[0].split(" ")[1]); // Call API for upper weeks
      } else if (direction === "down") {
        console.log("Fetching data for lower weeks.");
        await fetchData2(
          "down",
          Object.keys(events)[Object.keys(events).length - 1].split(" ")[1]
        ); // Call API for lower weeks
      }
    } finally {
      setIsFetching(false);
    }
  };

  // Memoize the debounced function to prevent unnecessary re-creation
  const debouncedHandleScroll = useMemo(
    () =>
      debounce(() => {
        if (!initialScrollDone || !allowScrollFetch) return;

        const container = containerRef.current;
        const topMarker = topMarkerRef.current;
        const bottomMarker = bottomMarkerRef.current;
        if (container && container.scrollTop && topMarker && bottomMarker) {
          const containerRect = container.getBoundingClientRect();
          const topMarkerRect = topMarker.getBoundingClientRect();
          const bottomMarkerRect = bottomMarker.getBoundingClientRect();

          // Calculate distance to top and bottom markers
          const distanceToTopMarker = topMarkerRect.top - containerRect.top;
          const distanceToBottomMarker =
            bottomMarkerRect.bottom - containerRect.bottom;

          // Fetch data if within offset range
          // if (distanceToTopMarker > -1000) {
          //   fetchDataOnScroll("up");
          // } else
          if (distanceToBottomMarker < SCROLL_TRIGGER_OFFSET) {
            fetchDataOnScroll("down");
          }
        }
      }, 300), // Debounce time (adjust as needed)
    [initialScrollDone, dataEnd, allowScrollFetch, events] // Dependency array includes scroll flag
  );

  // Use `useCallback` to wrap the debounced function
  const handleScroll = useCallback(debouncedHandleScroll, [
    debouncedHandleScroll,
  ]);

  useEffect(() => {
    const container = containerRef.current;
    console.log(container, "test3");

    if (container) {
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  // console.log(topMarkerRef.current?.getBoundingClientRect().top - containerRef.current?.getBoundingClientRect().top  );

  const autoScrollMid = () => {
    const container = containerRef.current;

    if (container) {
      // const middleScrollPos =
      //   (container.scrollHeight - container.clientHeight) / 2;

      // container.scrollTo({
      //   top: middleScrollPos,
      //   behavior: "smooth",
      // });

      // setPrevScrollPos(middleScrollPos); // Set initial scroll position

      // Set initial scroll as done after a delay to ensure smooth scroll completion
      setTimeout(() => {
        setInitialScrollDone(true);
      }, 500); // Adjust the timeout to match the smooth scroll duration if necessary
    }
  };

  return (
    <div className="Events">
      {!loading ? (
        <>
          <CreateFormModal
            isDarkMode={isDarkMode}
            handleCopy={handleCopy}
            parentId={parentId}
            setParentId={setParentId}
            eventId={eventId}
            setEventId={setEventId}
            setDisplayComponent={setDisplayComponent}
          />
          <EditEventModal
            dealerships={dealerships}
            eventNavigate={true}
            editID={editID}
            isEdit={true}
            oldData={oldData}
            setOldData={setOldData}
            setIsLoading={setIsLoading}
            brands={brands}
            isDarkMode={isDarkMode}
            repOptions={repOptions}
          />
          <FilterBar
            isDarkMode={isDarkMode}
            allfields={allfields}
            checkedFields={checkedFields}
            setCheckedFields={setCheckedFields}
            textOptions={textOptions}
            textOptions2={textOptions2}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            dealerOptions={dealerOptions}
            repOptions2={repOptions2}
            managers={managers}
            packageOptions={packageOptions}
            animatorOptions={animatorOptions}
            getFilteredEvents={getFilteredEvents}
            weekOptions={weekOptions}
          />
          <div className="container maincont">
            <div id="app" className="appcountainer2">
              <div className="row align-items-center">
                <div className="col-8">
                  <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                    {translations.viewEventReport.title[language]}
                  </h2>
                </div>
                <div className="col-4 d-flex gap-2 justify-content-end">
                  <div className="">
                    {userData &&
                      (userData.roles[0].permissions.some(
                        (item) => item.name === "Event Report"
                      ) ||
                        userData.roles[0].permissions.some(
                          (item) => item.name === "Contract And Billing"
                        )) && (
                        <div style={{ width: "250px" }}>
                          <Select
                            className="selectpicker w-100"
                            aria-labelledby="dealership-name-quick"
                            // isClearable
                            options={[
                              userData &&
                                userData.roles[0].permissions.some(
                                  (item) => item.name === "Event Report"
                                ) &&
                                !window.location.href.includes(
                                  "page=view-event-report"
                                ) && {
                                  label:
                                    translations.topAndsidebar.eventReports[
                                      language
                                    ],
                                  value: "view-event-report",
                                },
                              userData &&
                                userData.roles[0].permissions.some(
                                  (item) => item.name === "Contract And Billing"
                                ) &&
                                !window.location.href.includes(
                                  "page=view-billing-report"
                                ) && {
                                  label:
                                    translations.topAndsidebar.billingReport[
                                      language
                                    ],
                                  value: "view-billing-report",
                                },
                              !window.location.href.includes("page=events") && {
                                label:
                                  translations.EventsPortal.title.events[
                                    language
                                  ],
                                value: "events",
                              },
                            ].filter(Boolean)}
                            placeholder={
                              translations["dropdown"].select[language]
                            }
                            isSearchable={false}
                            value={
                              {
                                label:
                                  translations.topAndsidebar.eventReports[
                                    language
                                  ],
                                value: "view-event-report",
                              }
                            }
                            // value={
                            //   (filterOptions &&
                            //     filterOptions.length > 0 &&
                            //     filterOptions.find(
                            //       (option) => option.value === filterOption
                            //     )) || {
                            //     value: "",
                            //     label: translations["dropdown"].select[language],
                            //   }
                            // }
                            onChange={(selectedOption) => {
                              setDisplayComponent((prev) => {
                                setPrevDisplay(prev);
                                return `${selectedOption.value}`;
                              });
                              window.history.pushState(
                                {
                                  id: "gallery",
                                  randomData: window.Math.random(),
                                },
                                "title",
                                `/event-portal?page=${selectedOption.value}`
                              );
                            }}
                            // maxMenuHeight={"130px"}
                            styles={{
                              input: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                              }),
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "white",
                                border: state.isFocused
                                  ? "1px solid #80bdff"
                                  : isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6", // Adjust the border color for focus
                                boxShadow: state.isFocused
                                  ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                  : "none", // Bootstrap's box shadow for focus
                                borderRadius: ".45rem",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none", // Hide the indicator separator
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                color: isDarkMode ? "#ffffff" : "#868686",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                margin: "0", // Remove default margin
                                zIndex: "9999",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                // Additional styles for placeholder
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: state.isHovered
                                  ? "#80bdff"
                                  : isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                ":hover": {
                                  backgroundColor: isDarkMode
                                    ? "#34DBB4"
                                    : "#34DBB4",
                                },
                              }),
                            }}
                          />
                        </div>
                      )}
                  </div>
                  <div style={{ width: "250px" }}>
                    <Select
                      className="selectpicker w-100"
                      aria-labelledby="dealership-name-quick"
                      // isClearable
                      options={filterOptions}
                      placeholder={translations["dropdown"].select[language]}
                      isSearchable={false}
                      value={
                        (filterOptions &&
                          filterOptions.length > 0 &&
                          filterOptions.find(
                            (option) => option.value === filterOption
                          )) || {
                          value: "",
                          label: translations["dropdown"].select[language],
                        }
                      }
                      onChange={(selectedOption) => {
                        setFilterOption(selectedOption.value);
                        filterEvents(selectedOption.value);
                      }}
                      // maxMenuHeight={"120px"}
                      styles={{
                        input: (provided) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          backgroundColor: isDarkMode ? "transparent" : "white",
                          border: state.isFocused
                            ? "1px solid #80bdff"
                            : isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6", // Adjust the border color for focus
                          boxShadow: state.isFocused
                            ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                            : "none", // Bootstrap's box shadow for focus
                          borderRadius: ".45rem",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          display: "none", // Hide the indicator separator
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                          color: isDarkMode ? "#ffffff" : "#868686",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "black",
                          backgroundColor: isDarkMode ? "#1f2023" : "white",
                          margin: "0", // Remove default margin
                          zIndex: "999",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                          // Additional styles for placeholder
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "black",
                          backgroundColor: state.isHovered
                            ? "#80bdff"
                            : isDarkMode
                            ? "#1f2023"
                            : "white",
                          ":hover": {
                            backgroundColor: isDarkMode ? "#34DBB4" : "#34DBB4",
                          },
                        }),
                      }}
                    />
                  </div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      id=""
                      type="button"
                      className="btn admin-btn btn-primary"
                      data-bs-target="#filtersidebar"
                      data-bs-toggle="offcanvas"
                      aria-bs-controls="offcanvasmain"
                    >
                      <i className="bi bi-search"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="row align-items-center"
                style={{ minHeight: "44px" }}
              >
                <div className="col-3">
                  {selectedWeeks && selectedWeeks.length > 0 && (
                    <button
                      type="button"
                      className="client-btn d-flex align-items-center gap-2"
                      onClick={exportToExcel}
                    >
                      <i className="bi bi-upload"></i>
                      {translations.commonWords.export[language]}{" "}
                    </button>
                  )}
                </div>
              </div>
              {false && (
                <div className="headersec">
                  {/* <div className="mb-2 d-flex">
                  <input
                    style={{
                      borderColor: "#dee2e6",
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                    }}
                    type="text"
                    autocomplete="off"
                    className={`form-control ${isDarkMode ? "dark-mode" : ""}`}
                    id="searchInput"
                    placeholder={pageTranslations["searchbar"].search[language]}
                    value={key}
                    onChange={(e) => {
                      setKey(e.target.value);
                      // const filteredData = events.filter((event) =>
                      //   event.dealershipName
                      //     .toLowerCase()
                      //     .startsWith(e.target.value.toLowerCase())
                      // );
                      // setEvents(filteredData);
                    }}
                  />
                  {activeSearch && (
                    <button
                      className="bi bi-x-lg btn searchBtn"
                      style={{ padding: "6px 8px" }}
                      onClick={handleClearFilters}
                    ></button>
                  )}
                  <button
                    className="bi bi-search btn searchBtn"
                    style={{ padding: "6px 8px" }}
                    onClick={() => {
                      setPageCache((prevCache) => ({
                        ...prevCache,
                        pageNo: pageNo,
                      }));
                      setPageNo(1);
                      setSearchCache({});
                      if (key) {
                        handleSearch(1, true);
                      }
                    }}
                  />
                </div> */}
                  <div className="my-2"></div>
                </div>
              )}

              <div
                // className="bodysecsearch"
                ref={containerRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflowX: "auto",
                  maxHeight: "calc(100vh - 230px)",
                }}
              >
                <div ref={topMarkerRef} style={{ height: "1px" }} />
                <table className="table align-content-center fixed-table2">
                  <thead
                    className=""
                    style={{ position: "sticky", top: "0", zIndex: "1" }}
                  >
                    <tr>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      ></th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        {userData &&
                          userData.roles[0].permissions.some(
                            (item) => item.name === "Reports Export Permission"
                          ) && (
                            <input
                              type="checkbox"
                              className=""
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                            />
                          )}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead px-4" : "px-4"}`}
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className={`${
                          isDarkMode ? "darkthead text-center" : "text-center"
                        }`}
                        style={{ width: "200px" }}
                      >
                        {translations.forms.datesBtn[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        {translations.usersPage.createUser.status[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        {translations.quickCreationModal.dealership[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        {translations.viewBillingPage.seller[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ width: "150px" }}
                      >
                        {translations.commonFields.name[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        {
                          translations.forms.privateEventformFields.package[
                            language
                          ]
                        }
                      </th>

                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        {translations.viewEventReport.remarks[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        {translations.forms.formFields.animator[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ minWidth: "150px" }}
                      >
                        {translations.viewBillingPage.postalInvite[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        Web
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                      >
                        {translations.forms.formFields.banner[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ width: "150px" }}
                      >
                        {translations.forms.formFields.newsletter[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ width: "150px" }}
                      >
                        Facebook
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ width: "150px" }}
                      >
                        Ad-Words
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ width: "150px" }}
                      >
                        SMS
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ width: "150px" }}
                      >
                        {translations.forms.formFields.clients[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ width: "150px" }}
                      >
                        {translations.forms.formFields.inactive[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ width: "150px" }}
                      >
                        {translations.forms.conquestBtn[language]}
                      </th>

                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ width: "150px" }}
                      >
                        {translations.forms.formFields.massif[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ width: "150px" }}
                      >
                        {translations.forms.formFields.amount[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{
                          minWidth: language === "fr" ? "200px" : "180px",
                        }}
                      >
                        {
                          translations.forms.formFields.confirmationAgent[
                            language
                          ]
                        }
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{
                          minWidth: language === "fr" ? "210px" : "200px",
                        }}
                      >
                        {translations.forms.formFields.confirmationAgent[
                          language
                        ] + " "}
                        ID
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{
                          minWidth: language === "fr" ? "220px" : "150px",
                        }}
                      >
                        {translations.forms.formFields.noShowAgent[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{
                          minWidth: language === "fr" ? "240px" : "180px",
                        }}
                      >
                        {translations.forms.formFields.noShowAgent[language] +
                          " "}
                        ID
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ width: "150px" }}
                      >
                        {translations.forms.formFields.carton[language]}
                      </th>
                      <th
                        scope="col"
                        className={`${isDarkMode ? "darkthead" : ""}`}
                        style={{ minWidth: "120px" }}
                      >
                        {translations.forms.formFields.createdBy[language]}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {events && Object.keys(events).length > 0 ? (
                      Object.keys(events).map((key, accordionIndex) => {
                        const eventList = events[key];

                        return (
                          <React.Fragment
                            key={accordionIndex}
                            style={{
                              marginBottom: "1px",
                            }}
                          >
                            <tr>
                              <td
                                className="fw-bold"
                                style={{
                                  backgroundColor: "#ced4da66",
                                  borderTop: "1px solid #dee2e6",
                                  borderBottom: "1px solid #dee2e6",
                                }}
                              >
                                {userData &&
                                  userData.roles[0].permissions.some(
                                    (item) =>
                                      item.name === "Reports Export Permission"
                                  ) && (
                                    <input
                                      type="checkbox"
                                      className=""
                                      checked={selectedWeeks.includes(key)}
                                      onChange={() => handleCheckboxChange(key)}
                                    />
                                  )}
                              </td>
                              <td
                                colSpan={29}
                                className="fw-bold"
                                style={{
                                  backgroundColor: "#ced4da66",
                                  borderTop: "1px solid #dee2e6",
                                  borderBottom: "1px solid #dee2e6",
                                }}
                              >
                                {`Week ${key.split(" ")[1]}`}
                              </td>
                            </tr>
                            {eventList.map((event, index) => {
                              const isLastElement =
                                index === eventList.length - 1;

                              return (
                                <>
                                  <tr
                                    key={index}
                                    className="result-box align-middle"
                                    style={{
                                      marginBottom: "1px",
                                    }}
                                  >
                                    <td
                                      className="dlrname"
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    ></td>
                                    <td
                                      className="dlrname"
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    ></td>
                                    <td
                                      className="dlrname px-4"
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.id}
                                    </td>
                                    <td
                                      className="dlrname"
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "200px",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.start_date || ""} -{" "}
                                      {event?.end_date || ""}
                                    </td>
                                    <td
                                      className="dlrname"
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.status || ""}{" "}
                                    </td>
                                    <td
                                      className="dlrname"
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "150px",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.dealership?.name}
                                    </td>
                                    <td
                                      className="dlrname"
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "120px",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.sales_rep?.name}{" "}
                                    </td>
                                    <td
                                      className="dlrname"
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "200px",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.nom_vp}
                                    </td>

                                    <td
                                      className="dlrname"
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.package?.name}
                                    </td>
                                    <td
                                      className="dlrname"
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.comments}
                                    </td>
                                    <td
                                      className="dlrname"
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.animator?.name}
                                    </td>
                                    <td
                                      className="dlrname"
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.postal_invitation}
                                    </td>

                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.web}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.banner}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.news_letter}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.facebook}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.ad_words}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        cursor: "pointer",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setDisplayComponent("edit-event");
                                        setEditEventID(event.id);
                                        window.history.pushState(
                                          {
                                            id: "gallery",
                                            randomData: window.Math.random(),
                                          },
                                          "title",
                                          "/event-portal?page=edit-event&parent_id=" +
                                            event.id
                                        );
                                      }}
                                    >
                                      {event?.sms}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.clients}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.inactive}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.conquest}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.massif}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.amount}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.confirmation_agent?.name}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.confirmation_agent?.id}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.no_show_agent?.name}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        minWidth: "150px",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.no_show_agent?.id}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.carton}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: isLastElement
                                          ? "1px solid #dee2e6"
                                          : "0px solid",
                                        background: `${
                                          index % 2 === 0
                                            ? isDarkMode
                                              ? "#2B3035"
                                              : "#F8F9FA"
                                            : isDarkMode
                                            ? "#343A3F"
                                            : "#E9ECEF"
                                        }`,
                                      }}
                                    >
                                      {event?.created_by}
                                    </td>
                                  </tr>
                                  {isLastElement && (
                                    <tr
                                      key={index}
                                      className="align-middle"
                                      style={{
                                        position: "relative",
                                        height: "30px",
                                        // zIndex: 10 + eventList.length - index,
                                      }}
                                    >
                                      <td
                                        id="totalRow"
                                        style={{
                                          borderBottom: "1px solid #dee2e6",
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // position: "-webkit-sticky",
                                          // position: "sticky",
                                          // left: "0px",
                                          // width: "57px",
                                        }}
                                        className="dlrname fw-bold text-center text-white"
                                      ></td>
                                      <td
                                        id="totalRow"
                                        style={{
                                          borderBottom: "1px solid #dee2e6",
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // position: "-webkit-sticky",
                                          // position: "sticky",
                                          // left: "0px",
                                          width: "57px",
                                        }}
                                        className="dlrname fw-bold text-center text-white"
                                      >
                                        Total
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        id="totalRow"
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          fontWeight: "700",
                                          // position: "-webkit-sticky",
                                          // position: "sticky",
                                          // left:
                                          //   language === "fr" ? "80px" : "58px",
                                          width: "83px",
                                        }}
                                        className="dlrname text-white"
                                      >
                                        {eventList.length}
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>

                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          fontWeight: "700",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        {eventList.reduce(
                                          (sum, event) =>
                                            sum + (Number(event?.clients) || 0),
                                          0
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          fontWeight: "700",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        {eventList.reduce(
                                          (sum, event) =>
                                            sum +
                                            (Number(event?.inactive) || 0),
                                          0
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                          fontWeight: "700",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        {eventList.reduce(
                                          (sum, event) =>
                                            sum + (Number(event?.amount) || 0),
                                          0
                                        )}
                                      </td>

                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                      <td
                                        style={{
                                          background: `${
                                            isDarkMode ? "#435574" : "#435574"
                                          }`,
                                          // cursor: "pointer",
                                          borderBottom: isLastElement
                                            ? "1px solid #dee2e6"
                                            : "0px solid",
                                        }}
                                        id="totalRow"
                                        className="dlrname text-white"
                                      >
                                        -
                                      </td>
                                    </tr>
                                  )}
                                </>
                              );
                            })}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <tr style={{ borderStyle: "none" }}>
                        <td
                          colSpan={29}
                          className="text-center py-5"
                          style={{ borderStyle: "none" }}
                        >
                          <h4>
                            {translations.EventsPortal.noDataMessage[language]}
                          </h4>
                        </td>
                      </tr>
                    )}
                    {isFetching && (
                      <tr className="skeleton-loader">
                        <th className="p-2">
                          {/* <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div> */}
                        </th>
                        <td className="p-2">
                          {/* <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div> */}
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                        <td className="p-2">
                          <div
                            className={
                              isDarkMode ? "skeleton-dark" : "skeleton"
                            }
                          ></div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div ref={bottomMarkerRef} style={{ height: "1px" }} />
              </div>

              {/* {events &&
                Object.keys(events).length > 0 &&
                total !== 0 &&
                total > 10 && (
                  <PaginatedItems
                    total={total}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    handlePageChange={handlePageChange}
                    isDarkMode={isDarkMode}
                  />
                )} */}
            </div>
          </div>
          <AddWeekModal
            handleCopy={handleCopy}
            eventId={eventId}
            week={week}
            setWeek={setWeek}
            setEventId={setEventId}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default EventReport;
