import React, { useState } from "react";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import Select from "react-select";
import { ApiUrl } from "./ApiUrl";
import { toast } from "react-toastify";
function CreateFormModal({
  isDarkMode,
  handleCopy,
  setParentId,
  eventId,
  setEventId,
  setDisplayComponent,
  parentForms,
  setChildId,
  parentId
}) {
  const { language } = useLanguage();
  const [valid, setValid] = useState(false);
  const [selectedParent, setSelectedParent] = useState("");
  var button = document.getElementById("createFormCloseBtn");
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const getChildForms = async (id) => {
    try {
      const res = await fetch(ApiUrl + "/api/user/select-childform/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        setParentId(data?.parentForm);
        setChildId(data?.childForm);
      } else {
        toast.error(data.message);
      }
      // setIsLoading(false);
    } catch (err) {
      // setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };
  

  return (
    <div id="createForm" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{
              borderBottom: isDarkMode
                ? "1px solid #495057"
                : "1px solid #dee2e6",
            }}
          >
            <h3>{translations.EventsPortal.formModal.title[language]}</h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              id="createFormCloseBtn"
              style={{
                filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                color: isDarkMode ? "#ffffff" : "rgb(255, 255, 255) !important",
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="">
              <div className="w-100">
                <p className="">
                  {translations.EventsPortal.formModal.copyText[language]}{" "}
                </p>
              </div>
            </div>
            <div className="my-3">
              <label className="py-2">
                {translations.EventsPortal.formModal.label[language]}{" "}
              </label>
              <div className="row">
                <div className={`${userData &&
                  userData.roles[0].permissions.some(
                    (item) => item.name === "Create Dealership"
                  ) ? "col-9" :"col"}`}>
                  <Select
                    className="selectpicker w-100"
                    aria-labelledby="dealership-name-quick"
                    options={parentForms}
                    placeholder={translations["dropdown"].select[language]}
                    isSearchable={true}
                    // value={
                    //   (parentForms &&
                    //     parentForms.length > 0 &&
                    //     parentForms.find(
                    //       (option) =>
                    //         option.value ===
                    //         event?.sales_rep_id
                    //     )) || {
                    //     value: "",
                    //     label:
                    //       "",
                    //     // label:
                    //     //   translations["dropdown"].select[
                    //     //     language
                    //     //   ],
                    //   }
                    // }
                    onChange={(selectedOption) => {
                      
                      // setSelectedParent(selectedOption.value);
                      setParentId(selectedOption.value);
                      // getChildForms(selectedOption.value);
                    }}
                    maxMenuHeight={"130px"}
                    styles={{
                      input: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: isDarkMode ? "transparent" : "white",
                        border: state.isFocused
                          ? "1px solid #80bdff"
                          : isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6", // Adjust the border color for focus
                        boxShadow: state.isFocused
                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                          : "none", // Bootstrap's box shadow for focus
                        borderRadius: ".45rem",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "0.25rem 0.55rem", // Bootstrap's padding for the value container
                      }),
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                        color: isDarkMode ? "#ffffff" : "#868686",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black",
                        backgroundColor: isDarkMode ? "#1f2023" : "white",
                        margin: "0", // Remove default margin
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                        // Additional styles for placeholder
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black",
                        backgroundColor: state.isHovered
                          ? "#80bdff"
                          : isDarkMode
                          ? "#1f2023"
                          : "white",
                        ":hover": {
                          backgroundColor: isDarkMode ? "#34DBB4" : "#34DBB4",
                        },
                      }),
                    }}
                  />
                </div>
                {
                  userData &&
                  userData.roles[0].permissions.some(
                    (item) => item.name === "Create Dealership"
                  ) && 

                <div className="col">
                  <button
                    type="button"
                    className="client-btn btn-success px-3 py-2 w-100"
                    data-bs-dismiss="modal"
                    disabled={!parentId}
                    onClick={() => {
                      button.click();
                      handleCopy();
                    }}
                    style={{
                      cursor: parentId ? "pointer" : "not-allowed",
                    }}
                  >
                    {
                      translations.EventsPortal.eventsTableButtons.copy[
                        language
                      ]
                    }
                  </button>
                </div>
                }
              </div>
            </div>
          </div>

          <div
            class="modal-footer"
            id="modalfooter"
            style={{
              borderTop: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6",
            }}
          >
            <div className="d-flex align-items-center gap-2 justify-content-between w-100">
              <div style={{width: "80%"}}>
                <p className="">
                  {translations.EventsPortal.formModal.createText[language]}{" "}
                </p>
              </div>
              <button
                type="button"
                className="btn admin-btn btn-primary px-4 py-2"
                id="form-send-client"
                onClick={() => {
                  button.click();
                  setDisplayComponent("create-event");
                  window.history.pushState(
                    {
                      id: "gallery",
                      randomData: window.Math.random(),
                    },
                    "title",
                    "/event-portal?page=create-event&parent_id=" +
                      eventId +
                      "&event-navigate=true"
                  );
                  setEventId("");
                }}
              >
                {translations.forms.title[language]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateFormModal;
