import React, { useEffect, useState } from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import ColumnChart from "./columnChart";
import AppointmentsPerHour from "./appointmentsPerHour";
const AppointmentsSection = ({
  isDarkMode,
  dashboardData,
  getDataByDate,
  eventDates,
}) => {
  const { language } = useLanguage();
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (dashboardData) {
      setSelectedDate(dashboardData?.graphDate);
    }
  }, [dashboardData]);

  return (
    <div className="py-2">
      <div className="row">
        <div className="col-6">
          <div className="d-flex gap-4 flex-column flex-wrap w-100">
            <div className="d-flex gap-4 w-100">
              <div
                className="d-flex align-items-center justify-content-center w-100"
                style={{
                  backgroundColor: "#32DBB4",
                  minHeight: "230px",
                  borderRadius: "0.375rem",
                }}
              >
                <span
                  className="mb-0 me-2"
                  style={{ fontSize: "60px", color: "#263238" }}
                >
                  <i className="bi bi-calendar"></i>
                </span>
                <div className="d-flex flex-column">
                  <p
                    className=""
                    style={{
                      fontSize: "70px",
                      fontWeight: "700",
                      marginLeft: "-5px",
                      color: "#263238",
                    }}
                  >
                    {/* CAD {numeral (graphData?.aveAmount).format("0,000.00")} */}
                    {dashboardData?.totalAppointments}
                  </p>
                  <p
                    className="text-black text-center"
                    style={{ color: "#263238" }}
                  >
                    {translations.eventView.appointments[language]}
                  </p>
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center w-100"
                style={{
                  backgroundColor: "#0095ff",
                  minHeight: "230px",
                  borderRadius: "0.375rem",
                }}
              >
                <span
                  className="text-white mb-0 me-2"
                  style={{ fontSize: "60px" }}
                >
                  <i className="bi bi-patch-check-fill"></i>
                </span>
                <div className="d-flex flex-column">
                  <p
                    className="text-white d-flex align-items-center gap-2"
                    style={{
                      fontSize: "70px",
                      fontWeight: "700",
                      // marginLeft: "-5px",
                    }}
                  >
                    {/* CAD {numeral (graphData?.aveAmount).format("0,000.00")} */}
                    {dashboardData?.confirmed}{" "}
                    <span style={{ fontSize: "1.475rem" }}>
                      ({dashboardData?.confirmedAppointment}%){" "}
                    </span>
                  </p>
                  <p className="text-white text-center" style={{}}>
                    {
                      translations.createProspective.confirmationSatusOptions
                        .opt1[language]
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-4 w-100">
              <div
                className="d-flex align-items-center justify-content-center w-100"
                style={{
                  backgroundColor: "#445675",
                  minHeight: "230px",
                  borderRadius: "0.375rem",
                }}
              >
                <span
                  className="text-white mb-0 me-2"
                  style={{ fontSize: "60px" }}
                >
                  <i className="bi bi-person-walking"></i>
                </span>
                <div className="d-flex flex-column">
                  <p
                    className="text-white d-flex align-items-center gap-2"
                    style={{
                      fontSize: "70px",
                      fontWeight: "700",
                      // marginLeft: "-5px",
                    }}
                  >
                    {/* CAD {numeral (graphData?.aveAmount).format("0,000.00")} */}
                    {dashboardData?.attended}{" "}
                    <span style={{ fontSize: "1.475rem" }}>
                      ({dashboardData?.attendedAppointment}%){" "}
                    </span>
                  </p>
                  <p className="text-white text-center" style={{}}>
                    {translations.eventView.attended[language]}
                  </p>
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center w-100"
                style={{
                  backgroundColor: "#DC3444",
                  minHeight: "230px",
                  borderRadius: "0.375rem",
                }}
              >
                <span
                  className="text-white mb-0 me-2"
                  style={{ fontSize: "60px" }}
                >
                  <i className="bi bi-car-front-fill"></i>
                </span>
                <div className="d-flex flex-column">
                  <p
                    className="text-white d-flex align-items-center gap-2"
                    style={{
                      fontSize: "70px",
                      fontWeight: "700",
                      // marginLeft: "-5px",
                    }}
                  >
                    {/* CAD {numeral (graphData?.aveAmount).format("0,000.00")} */}
                    {dashboardData?.sold}{" "}
                    <span style={{ fontSize: "1.475rem" }}>
                      ({dashboardData?.soldAppointment}%){" "}
                    </span>
                  </p>
                  <p className="text-white text-center" style={{}}>
                    {translations.eventView.sold[language]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div
            className="w-100 h-100"
            style={{
              // boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
              background: isDarkMode ? "" : "rgba(243,243,243)",
              borderRadius: "0.375rem",
            }}
          >
            <div className="d-flex align-items-center p-2">
              <div className="w-50">
                <h3 className={`${isDarkMode ? "darkthead" : ""}`}>
                  {translations.eventView.totalAppointmentsPerHour[language]}
                </h3>
              </div>
              <div className="w-50">
                <div className="input-group" style={{ width: "100%" }} >
                  <span className="input-group-text" style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }} >Date</span>

                  <select
                    class={`form-select ${
                      isDarkMode ? "custom-select" : ""
                    }`}
                    value={selectedDate}
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        getDataByDate(
                          { graphDate: e.target.value },
                          "appointmentsPerHourGraph"
                        );
                      }
                      setSelectedDate(e.target.value);
                    }}
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                  >
                    <option value="" style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}>
                      {translations.dropdown.select[language]}
                    </option>
                    {eventDates &&
                      eventDates.length > 0 &&
                      eventDates.map((date) => (
                        <option key={date.value} value={date.value} style={{
                          background: isDarkMode ? "#1B1F22" : "",
                          color: isDarkMode ? "#ffffff" : "",
                        }}>
                          {date.label}
                        </option>
                      ))}
                  </select>

                  {/* <input
                    type="date"
                    className={`form-control`}
                    // style={{
                    //   backgroundColor: isDarkMode ? "transparent" : "",
                    //   color: isDarkMode ? "#ffffff" : "#000000",
                    //   border: isDarkMode
                    //     ? "1px solid #495057"
                    //     : "1px solid #dee2e6",
                    // }}
                    // value={new Date().toISOString().split("T")[0]}
                    onChange={(e) => {
                      getDataByDate({ graphDate: e.target.value }, "appointmentsPerHourGraph");
                    }}
                  /> */}
                </div>
              </div>
            </div>
            {dashboardData && (
              <AppointmentsPerHour
                isDarkMode={isDarkMode}
                graphData={dashboardData.appointmentsPerHourGraph}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsSection;
