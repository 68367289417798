import React, { useEffect, useState } from "react";
import { useLanguage } from "../../languageContext";
import translations from "../../translation.json";
import { ApiUrl } from "../../ApiUrl";
import { toast } from "react-toastify";
import Loader from "../../loader";
import ClientsFilterbar from "./../../components/clientsFilterbar";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import * as XLSX from "xlsx";
import { format } from "date-fns";

const BonusConfirmation = ({
  isDarkMode,
  setDisplayComponent,
  setPrevDisplay,
  dealerships2,
  setIsLoading,
}) => {
  const { language } = useLanguage();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventId, setEventId] = useState(null);
  const [events, setEvents] = useState([]);
  const [checkedFields, setCheckedFields] = useState([]);
  const token = localStorage.getItem("token");
  const [filtersData, setFiltersData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDealership, setSelectedDealership] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [dateRange, setDateRange] = useState({ start_date: "", end_date: "" });

  const allfields = [
    {
      content: translations.commonFields.firstName[language],
      name: "first_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.lastName[language],
      name: "last_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.phone[language],
      name: "phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.email[language],
      name: "email",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.make[language],
      name: "make",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.createProspective.model[language],
      name: "model",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.usersPage.createUser.year[language],
      name: "year",
      type: "number",
      filterType: "",
      value: "",
    },
    {
      content: "KM",
      name: "km",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.clients.table.callStatus[language],
      name: "call_status",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.address[language],
      name: "address",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.city[language],
      name: "city",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.province[language],
      name: "province",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: "Activix ID",
      name: "activix_id",
      type: "number",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.prospectiveType[language],
      name: "prospect_type",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content:
        translations.forms.formFields.conquestSection.postalCode[language],
      name: "postal_code",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.officeNumber[language],
      name: "office_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.appointments.appointment[language],
      name: "appointment",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.mobileNumber[language],
      name: "mobile_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.language[language],
      name: "language",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.VIN[language],
      name: "vin",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.appointmentDate[language],
      name: "appointment_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.purchaseDate[language],
      name: "purchase_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.lastServiceDate[language],
      name: "last_service_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.prospectiveSource[language],
      name: "prospect_source",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.greetings[language],
      name: "greetings",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: "OG Rep",
      name: "og_rep",
      type: "text",
      filterType: "",
      value: "",
    },
  ];
  const textOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const textOptions2 = [
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const appointmentOption = [
    { label: translations.dropdown.yes[language], value: "1" },
    { label: translations.dropdown.no[language], value: "0" },
  ];
  // const prospectiveSourceOptions = Object.keys(
  //   translations.createProspective.prospectiveSourceOptions
  // ).map((key) => ({
  //   label: {
  //     en: translations.createProspective.prospectiveSourceOptions[key]["en"],
  //     fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
  //   },
  //   value: {
  //     en: translations.createProspective.prospectiveSourceOptions[key]["en"],
  //     fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
  //   },
  // }));

  const prospectiveSourceOptions = [
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt1[language],
      value: translations.createProspective.prospectiveSourceOptions.opt1["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt2[language],
      value: translations.createProspective.prospectiveSourceOptions.opt2["en"],
    },
    // {
    //   label:
    //     translations.createProspective.prospectiveSourceOptions.opt3[language],
    //   value: translations.createProspective.prospectiveSourceOptions.opt3["en"],
    // },
    // {
    //   label:
    //     translations.createProspective.prospectiveSourceOptions.opt4[language],
    //   value: translations.createProspective.prospectiveSourceOptions.opt4["en"],
    // },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt5[language],
      value: translations.createProspective.prospectiveSourceOptions.opt5["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt6[language],
      value: translations.createProspective.prospectiveSourceOptions.opt6["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt7[language],
      value: translations.createProspective.prospectiveSourceOptions.opt7["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt8[language],
      value: translations.createProspective.prospectiveSourceOptions.opt8["en"],
    },
  ];

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await fetch(
          ApiUrl + `/api/user/bonus-confirmation-agent-report`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              private_event_ids: [],
            }),
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          // const newData = data.private_events.data;

          setClients(data.appointmentData);
          const allAppointmentIds =
            data.prospectives &&
            data.prospectives.length > 0 &&
            data.prospectives.map((appointment) => appointment.id);
          setSelectedRows(data.appointmentData);

          // autoScrollMid();
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    };
    const getFiltersData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-fillter", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const filteredFields = allfields?.filter(
            (field) => data?.fillter[field.name] === 1
          );

          setCheckedFields(filteredFields);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
        // Handle error or show a toast if needed
      }
    };
    if (token) {
      fetchData();
    }
    // getFiltersData();
  }, []);

  const handleCheckboxChange = (appointmentId) => {
    setSelectedRows(
      (prevSelected) =>
        prevSelected.includes(appointmentId)
          ? prevSelected.filter((id) => id !== appointmentId) // Deselect if selected
          : [...prevSelected, appointmentId] // Add if not selected
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      // Deselect all
      setSelectedRows([]);
    } else {
      // Select all appointments' IDs
      const allAppointmentIds = clients.map((appointment) => appointment.id);
      setSelectedRows(allAppointmentIds);
    }
    setSelectAll(!selectAll); // Toggle the state of selectAll
  };

  const getFilteredEvents = async (filterPost) => {
    // setLoading(true);
    setIsLoading(true);

    try {
      const res = await fetch(
        ApiUrl + "/api/user/bonus-confirmation-agent-report",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            private_event_ids: selectedEvents.map((event) => event.value),
            ...filterPost,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        setClients(data.prospectives);
        const allAppointmentIds =
          data.prospectives &&
          data.prospectives.length > 0 &&
          data.prospectives.map((appointment) => appointment.id);
        setSelectedRows(allAppointmentIds);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      // setAllowScrollFetch(true);
      setIsLoading(false);
    }
  };

  const getEventsList = async (dealershipId) => {
    // setLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/p-events/" + dealershipId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        const lists =
          data?.private_events &&
          data?.private_events.length > 0 &&
          data?.private_events.map((list) => {
            return { label: list?.nom_vp, value: list?.id };
          });

        setEvents(lists);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      // setIsLoading(false);
    }
  };

  const getFilteredProspects = async (start_date, end_date) => {
    setIsLoading(true);
    // setAllowScrollFetch(false);

    try {
      const res = await fetch(
        ApiUrl + "/api/user/bonus-confirmation-agent-report",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ start_date: start_date, end_date: end_date }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        setClients(data.appointmentData);
        // const allAppointmentIds =
        //   data.appointmentData &&
        //   data.appointmentData.length > 0 &&
        //   data.appointmentData.map((appointment) => appointment.id);
        setSelectedRows(data.appointmentData);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      // setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
      // setAllowScrollFetch(true);
      setIsLoading(false);
    }
  };

  const exportToExcel = () => {
    // Filter the selected appointments based on their IDs
    // const selectedData = clients
    //   .filter((appointment) => selectedRows.includes(appointment.id)) // Only include selected appointments
    //   .map((appointment) => ({
    //     Prospect_ID: appointment?.id || "Null",
    //     Event_ID: appointment.private_event.id || "Null",
    //     Dealership: appointment?.private_event.dealership?.name || "Null",
    //     Prospect_Source: appointment?.prospect_source || "Null",
    //     Greetings: appointment?.greetings || "Null",
    //     First_Name: appointment?.first_name || "Null",
    //     Last_Name: appointment?.last_name || "Null",
    //     Phone_Number: appointment?.phone || "Null",
    //     Mobile_Number: appointment?.mobile_phone || "Null",
    //     Office_Number: appointment?.office_phone || "Null",
    //     Address: appointment?.address || "Null",
    //     City: appointment?.city || "Null",
    //     Province: appointment?.province || "Null",
    //     Postal_Code: appointment?.postal_code || "Null",
    //     Email: appointment?.email || "Null",
    //     Make: appointment.make || "Null",
    //     Model: appointment.model || "Null",
    //     Year: appointment.year || "Null",
    //     Purchase_Date: appointment.purchase_date || "Null",
    //     Last_Service_Date: appointment.last_service_date || "Null",
    //     KM: appointment.km || "Null",
    //     VIN: appointment.vin || "Null",
    //     Representative: appointment?.og_rep || "Null",
    //     Call_Status: appointment?.call_status || "Null",
    //     Appointment_Date:
    //       appointment?.appointments[0]?.appointment_date || "Null",
    //     Appointment_Time:
    //       appointment?.appointments[0]?.appointment_time || "Null",
    //     Confirmation_Status:
    //       appointment?.appointments[0]?.confirmation_status || "Null",
    //     Visit_Status: appointment?.appointments[0]?.visit_status || "Null",
    //     No_Show_Status: appointment?.appointments[0]?.no_show_status || "Null",
    //   }));

    const selectedData = selectedRows // Only include selected appointments
      .map((appointment) => ({
        Agent_Name: appointment?.appointment_agent_name || "Null",
        Total_Confirmed_Appointments: appointment.total_count || "Null",
      }));

    // Generate the Excel sheet
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Appointments");

    // Save the file
    XLSX.writeFile(
      workbook,
      `Bonus Confirmation Report ${format(new Date(), "dd MMMM yyyy")}.xlsx`
    );
  };

  return (
    <div>
      {!loading ? (
        <div className="container maincont">
          <div id="app" className="appcountainer2">
            <ClientsFilterbar
              isDarkMode={isDarkMode}
              allfields={allfields}
              checkedFields={checkedFields}
              setCheckedFields={setCheckedFields}
              textOptions={textOptions}
              textOptions2={textOptions2}
              appointmentOption={appointmentOption}
              prospectiveSourceOptions={prospectiveSourceOptions}
              filtersData={filtersData}
              setFiltersData={setFiltersData}
              // filterOption={filterOption}
              // setFilterOption={setFilterOption}
              // dealerOptions={dealerOptions}
              // repOptions2={repOptions2}
              // managers={managers}
              // packageOptions={packageOptions}
              // animatorOptions={animatorOptions}
              getFilteredEvents={getFilteredEvents}
              // weekOptions={weekOptions}
            />
            <div className="row align-items-center justify-content-between pb-2">
              <div className="col-4 d-flex gap-3 align-items-center">
                <div>
                  <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                    {
                      translations.topAndsidebar.bonusConfirmationReport[
                        language
                      ]
                    }
                  </h2>
                </div>
              </div>
              <div className="col-8 gap-2 d-flex align-items-center justify-content-end">
                <div style={{ width: "250px" }} className="input-group">
                  {/* <Select
                    className="selectpicker w-100"
                    aria-labelledby="dealership-name-quick"
                    isClearable
                    options={dealerships2}
                    placeholder={translations["dropdown"].select[language]}
                    isSearchable={true}
                    maxMenuHeight={"120px"}
                    value={selectedDealership}
                    // value={
                    //   (filterOptions &&
                    //     filterOptions.length > 0 &&
                    //     filterOptions.find(
                    //       (option) => option.value === filterOption
                    //     )) || {
                    //     value: "",
                    //     label: translations["dropdown"].select[language],
                    //   }
                    // }
                    onChange={(selectedOption) => {
                      setSelectedDealership(selectedOption);
                      if (selectedOption) getEventsList(selectedOption.value);
                      else {
                        setSelectedEvents([]);
                        setSelectedRows([]);
                        getFilteredProspects([]);
                      }
                    }}
                    styles={{
                      input: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: isDarkMode ? "transparent" : "white",
                        border: state.isFocused
                          ? "1px solid #80bdff"
                          : isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6", // Adjust the border color for focus
                        boxShadow: state.isFocused
                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                          : "none", // Bootstrap's box shadow for focus
                        borderRadius: ".45rem",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                      }),
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none", // Hide the indicator separator
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                        color: isDarkMode ? "#ffffff" : "#868686",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black",
                        backgroundColor: isDarkMode ? "#1f2023" : "white",
                        margin: "0", // Remove default margin
                        zIndex: "9999",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                        // Additional styles for placeholder
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black",
                        backgroundColor: state.isHovered
                          ? "#80bdff"
                          : isDarkMode
                          ? "#1f2023"
                          : "white",
                        ":hover": {
                          backgroundColor: isDarkMode ? "#34DBB4" : "#34DBB4",
                        },
                      }),
                    }}
                  /> */}
                  <span
                    className="input-group-text"
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                  >
                    {
                      translations.forms.privateEventformFields.startDate[
                        language
                      ]
                    }
                  </span>

                  <input
                    type="date"
                    class={`form-control ${isDarkMode ? "custom-date" : ""}`}
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                    name="last_sale_date"
                    value={dateRange.start_date}
                    onChange={(e) => {
                      setDateRange({
                        ...dateRange,
                        start_date: e.target.value,
                      });
                      getFilteredProspects(e.target.value, dateRange.end_date);
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "250px",
                  }}
                  className={"input-group"}
                >
                  <span
                    className="input-group-text"
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                  >
                    {
                      translations.forms.privateEventformFields.endDate[
                        language
                      ]
                    }
                  </span>

                  <input
                    type="date"
                    class={`form-control ${isDarkMode ? "custom-date" : ""}`}
                    style={{
                      backgroundColor: isDarkMode
                        ? dateRange.start_date === ""
                          ? "#ced4da66"
                          : "transparent"
                        : dateRange.start_date === ""
                        ? "#ced4da66"
                        : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                    name="last_sale_date"
                    value={dateRange.end_date}
                    disabled={dateRange.start_date === ""}
                    onChange={(e) => {
                      setDateRange({
                        ...dateRange,
                        end_date: e.target.value,
                      });
                      getFilteredProspects(
                        dateRange.start_date,
                        e.target.value
                      );
                    }}
                  />
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  {/* <button
                    id=""
                    type="button"
                    disabled={selectedEvents.length === 0}
                    className="btn admin-btn btn-primary"
                    data-bs-target="#clientFiltersidebar"
                    data-bs-toggle="offcanvas"
                    aria-bs-controls="offcanvasmain"
                  >
                    <i className="bi bi-search"></i>{" "}
                  </button> */}
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <div className="col-4 d-flex gap-3 align-items-center">
                <div
                  className="row align-items-center"
                  style={{ minHeight: "40px" }}
                >
                  <div className="col-3">
                    {selectedRows && selectedRows.length > 0 && (
                      <button
                        type="button"
                        className="client-btn d-flex align-items-center gap-2"
                        onClick={exportToExcel}
                      >
                        <i className="bi bi-upload"></i>
                        {translations.commonWords.export[language]}{" "}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              // className="pt-4"
              // style={{
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "space-between",
              // }}
              // ref={containerRef}
              style={{
                display: "flex",
                flexDirection: "column",
                overflowX: "auto",
                justifyContent: "space-between",
                height: "calc(100vh - 260px)",
              }}
            >
              <table className="table table-hover text-center align-middle">
                <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
                  <tr>
                    {/* <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                    >
                      {
                        <input
                          type="checkbox"
                          className=""
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                      }
                    </th> */}
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "180px" : "165px",
                      }}
                    >
                      {
                        translations.createProspective.appointmentAgent[
                          language
                        ]
                      }
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "125px" : "150px",
                      }}
                    >
                      {
                        translations.createProspective
                          .totalConfirmedAppointments[language]
                      }
                    </th>
                  </tr>
                </thead>
                {clients && !loading && (
                  <tbody id="">
                    {clients && clients.length > 0 ? (
                      clients.map((client, index) => {
                        return (
                          <>
                            <tr className="result-box" key={client?.id}>
                              {/* <td className="fw-bold">
                                {
                                  <input
                                    type="checkbox"
                                    className=""
                                    checked={selectedRows.includes(client.id)}
                                    onChange={() =>
                                      handleCheckboxChange(client.id)
                                    }
                                  />
                                }
                              </td> */}
                              <th className="custID" scope="row">
                                {client?.appointment_agent_name}
                              </th>
                              <td className="dlrname">{client?.total_count}</td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr
                        className="align-middle"
                        style={{
                          position: "relative",
                          // height: "30px",
                          // zIndex: 10 + eventList.length - index,
                        }}
                      >
                        <td
                          style={{
                            background: `${
                              isDarkMode ? "transparent" : "white"
                            }`,
                            borderStyle: "none",
                          }}
                          colSpan={30}
                          className="text-center py-5"
                        >
                          <h4>
                            {translations.EventsPortal.noDataMessage[language]}
                          </h4>
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
              {/* <div ref={bottomMarkerRef} style={{ height: "1px" }} /> */}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default BonusConfirmation;
