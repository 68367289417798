import React, { useEffect, useState } from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ApiUrl } from "../ApiUrl";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
const CreateProspective = ({
  isDarkMode,
  setDisplayComponent,
  setPrevDisplay,
  editMode = false,
  setIsLoading,
  users,
  listId,
}) => {
  const { language } = useLanguage();
  const token = localStorage.getItem("token");
  const [eventId, setEventId] = useState(null);
  const [prospectId, setProspectId] = useState(null);
  const [prospectData, setProspectData] = useState(null);
  const [listIds, setListIds] = useState([]);
  const [repOptions, setRepOptions] = useState([]);
  const [submitType, setSubmitType] = useState(null);

  // const repOptions =
  //   users &&
  //   users.representatives.length > 0 &&
  //   users.representatives.map((user) => ({
  //     value: user.id,
  //     label: user.name,
  //   }));
  const agentOptions =
    users &&
    users.agents.length > 0 &&
    users.agents.map((user) => ({
      value: user.id,
      label: user.name,
    }));

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const ampersandIndex = currentUrl.indexOf("&", secondEqualsIndex + 1); //=41&p=27

        if (ampersandIndex !== -1) {
          const editClientIdValue = currentUrl.substring(
            secondEqualsIndex + 1,
            ampersandIndex
          ); //=41
          setEventId(editClientIdValue);

          const thirdEqualsIndex = currentUrl.indexOf("=", ampersandIndex + 1); // =27

          if (thirdEqualsIndex !== -1) {
            const eventIDValue = currentUrl.substring(thirdEqualsIndex + 1);
            setProspectId(eventIDValue);
          }
        } else {
          // If no '&' found after the second '=', extract until the end of the URL
          const editClientIdValue = currentUrl.substring(secondEqualsIndex + 1);
          setEventId(editClientIdValue);
        }
      }
    }
  });

  useEffect(() => {
    const getProspectData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/prospectives/" + prospectId,

          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          setProspectData(data?.prospective);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong");
      } finally {
      }
    };
    const getListIds = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(ApiUrl + "/api/user/list-ids", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const lists =
            data?.list_ids?.lists &&
            data?.list_ids.lists?.length > 0 &&
            data?.list_ids.lists?.map((list) => {
              return { label: list.list_name, value: list.list_id };
            });

          setListIds(lists);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong2");
      } finally {
      }
    };
    const getExternalUsers = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(ApiUrl + "/api/user/external-user/" + eventId, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const repData = data?.externalUsers?.length
            ? data?.externalUsers.map((list) => ({
                label: list.name,
                value: list.id,
              }))
            : [];
          setRepOptions(repData);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong");
      } finally {
      }
    };
    if (eventId && prospectId) {
      getProspectData(eventId);
    }
    getListIds();
    if (eventId) {
      getExternalUsers();
    }
  }, [eventId]);

  const initialData = {
    activix_id: editMode && prospectData ? prospectData?.activix_id : "",
    dialer_id: editMode && prospectData ? prospectData?.dialer_id : "",
    list_id:
      editMode && prospectData ? prospectData?.private_event.list_id : listId,
    private_event_id:
      editMode && prospectData ? prospectData?.private_event_id : eventId,
    confirmation_agent_name:
      editMode && prospectData
        ? prospectData?.appointments[0]?.confirmation_agent_name
        : "",
    prospect_type: editMode && prospectData ? prospectData?.prospect_type : "",
    first_name: editMode && prospectData ? prospectData?.first_name : "",
    last_name: editMode && prospectData ? prospectData?.last_name : "",
    phone: editMode && prospectData ? prospectData?.phone : "",
    make: editMode && prospectData ? prospectData?.make : "",
    model: editMode && prospectData ? prospectData?.model : "",
    year: editMode && prospectData ? prospectData?.year : "",
    appointment_date:
      editMode && prospectData
        ? prospectData?.appointments[0]?.appointment_date
        : "",
    appointment_entry_date:
      editMode && prospectData
        ? prospectData?.appointments[0]?.appointment_entry_date
        : "",
        appointment_rep_user_name:
      editMode && prospectData
        ? prospectData?.appointments[0]?.appointment_rep_user_name
        : "",
    appointment_agent_name:
      editMode && prospectData
        ? prospectData?.appointments[0]?.appointment_agent_name
        : "",
    appointment_time:
      editMode && prospectData
        ? prospectData.appointments[0]?.appointment_time
        : "",
    appointment_comment:
      editMode && prospectData
        ? prospectData?.appointments[0]?.appointment_comment
        : "",
    // appointment_status:
    //   editMode && prospectData
    //     ? prospectData.appointments[0]?.appointment_status
    //     : "",
    appointment_status_notes:
      editMode && prospectData
        ? prospectData?.appointments[0]?.appointment_status_notes
        : "",
    confirmation_status:
      editMode && prospectData
        ? prospectData?.appointments[0]?.confirmation_status
        : "",
    confirmation_notes:
      editMode && prospectData
        ? prospectData?.appointments[0]?.confirmation_notes
        : "",
    prospect_source:
      editMode && prospectData ? prospectData?.prospect_source : "",
    greetings: editMode && prospectData ? prospectData?.greetings : "",
    mobile_phone: editMode && prospectData ? prospectData?.mobile_phone : "",
    office_phone: editMode && prospectData ? prospectData?.office_phone : "",
    address: editMode && prospectData ? prospectData?.address : "",
    city: editMode && prospectData ? prospectData?.city : "",
    province: editMode && prospectData ? prospectData?.province : "",
    postal_code: editMode && prospectData ? prospectData.postal_code : "",
    email: editMode && prospectData ? prospectData?.email : "",
    language: editMode && prospectData ? prospectData?.language : "",
    purchase_date: editMode && prospectData ? prospectData?.purchase_date : "",
    last_service_date:
      editMode && prospectData ? prospectData?.last_service_date : "",
    km: editMode && prospectData ? prospectData?.km : "",
    vin: editMode && prospectData ? prospectData?.vin : "",
    call_status: editMode && prospectData ? prospectData?.call_status : "",
    confirmation_status_entry_date:
      editMode && prospectData
        ? prospectData?.appointments[0]?.confirmation_status_entry_date
        : "",
    visit_status:
      editMode && prospectData
        ? prospectData?.appointments[0]?.visit_status
        : "",
    visit_status_entry_date:
      editMode && prospectData
        ? prospectData?.appointments[0]?.visit_status_entry_date
        : "",
    visit_notes:
      editMode && prospectData
        ? prospectData?.appointments[0]?.visit_notes
        : "",
    visit_status_user_name:
      editMode && prospectData
        ? prospectData?.appointments[0]?.visit_status_user_name
        : "",
    no_show_status:
      editMode && prospectData
        ? prospectData.appointments[0]?.no_show_status
        : "",
    no_show_status_entry_date:
      editMode && prospectData
        ? prospectData?.appointments[0]?.no_show_status_entry_date
        : "",
    no_show_notes:
      editMode && prospectData
        ? prospectData?.appointments[0]?.no_show_notes
        : "",
    no_show_user_name:
      editMode && prospectData
        ? prospectData?.appointments[0]?.no_show_user_name
        : "",
    appointment: editMode && prospectData ? prospectData?.appointment : "",
    og_rep: editMode && prospectData ? prospectData?.og_rep : "",
    // appointment_id: editMode && prospectData ? prospectData.appointments[0]?.id : "",
  };

  // "opt1": {
            //     "en": "Service-Fidgi",
        //     "fr": "Service-Fidgi"
        // },
        // "opt3": {
        //     "en": "Client-Fidgi",
        //     "fr": "Client-Fidgi "
        // },
        // "opt4": {
        //     "en": "End of lease-Fidgi",
        //     "fr": "Fin de location-Fidgi"
        // },

  const prospectiveSourceOptions = Object.keys(
    translations.createProspective.prospectiveSourceOptions
  ).map((key) => ({
    label: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
    value: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
  }));
  const prospectTypeOptions = [
    {
      label: "Service-Fidgi",
      value: "Service-Fidgi  ",
    },
    {
      label: "Web-Fidgi",
      value: "Web-Fidgi",
    },
    {
      label: translations.createProspective.prospectTypeOptions.opt3[language],
      value: translations.createProspective.prospectTypeOptions.opt3["en"],
    },
    {
      label: translations.createProspective.prospectTypeOptions.opt4[language],
      value: translations.createProspective.prospectTypeOptions.opt4["en"],
    },
    {
      label: translations.createProspective.prospectTypeOptions.opt5[language],
      value: translations.createProspective.prospectTypeOptions.opt5["en"],
    },
    {
      label: translations.createProspective.prospectTypeOptions.opt6[language],
      value: translations.createProspective.prospectTypeOptions.opt6["en"],
    },
    {
      label: translations.createProspective.prospectTypeOptions.opt7[language],
      value: translations.createProspective.prospectTypeOptions.opt7["en"],
    },
  ];
  const visitStatusOptions = [
    {
      label: translations.createProspective.visitStatusOptions.opt1[language],
      value: translations.createProspective.visitStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt2[language],
      value: translations.createProspective.visitStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt3[language],
      value: translations.createProspective.visitStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt4[language],
      value: translations.createProspective.visitStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt5[language],
      value: translations.createProspective.visitStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt6[language],
      value: translations.createProspective.visitStatusOptions.opt6["en"],
    },
  ];

  const confirmationStatusOptions = [
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt1[language],
      value: "CONFIRMED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt2[language],
      value: "WANTCB",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt3[language],
      value: "MSG LEFT",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt4[language],
      value: "NO ANSWER",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt5[language],
      value: "SAVED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt6[language],
      value: "CANCEL",
    },
  ];

  const noShowOptions = [
    {
      label: translations.createProspective.noShowStatusOptions.opt1[language],
      value: translations.createProspective.noShowStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt2[language],
      value: translations.createProspective.noShowStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt3[language],
      value: translations.createProspective.noShowStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt4[language],
      value: translations.createProspective.noShowStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt5[language],
      value: translations.createProspective.noShowStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt6[language],
      value: translations.createProspective.noShowStatusOptions.opt6["en"],
    },
  ];
  const appointmentOption = [
    { label: translations.dropdown.yes[language], value: "1" },
    { label: translations.dropdown.no[language], value: "0" },
  ];

  const handleSubmit = async (values, { resetForm }, error) => {
    setIsLoading(true);
    try {
      const url = `${ApiUrl}/api/user/prospectives${
        editMode ? `/${prospectId}` : ""
      }`;
      const bodyData = editMode
        ? {
            ...values,
            // dialer_id: prospectData.dialer_id,
            appointment_id: prospectData.appointments[0]?.id,
          }
        : values;
      const method = editMode ? "PUT" : "POST";
      const res = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      // console.log("res", res)
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        toast.success(data.message);
        // navigate("/event-portal");

        if (submitType !== "" && submitType === "whole") {
          window.history.back();
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const handleSubmit2 = async (bodydata) => {
    setIsLoading(true);
    try {
      const url = `${ApiUrl}/api/user/prospectives${
        editMode ? `/${prospectId}` : ""
      }`;
      const bodyData = bodydata;
      const method = editMode ? "PUT" : "POST";
      const res = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...bodyData,
          appointment_id: prospectData.appointments[0]?.id,
          // dialer_id: prospectData?.dialer_id,
        }),
      });
      // console.log("res", res)
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        toast.success(data.message);
        // navigate("/event-portal");

        if (submitType !== "" && submitType === "whole") {
          window.history.back();
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  // const submitSectionData = async (values) => {
  //   setIsLoading(true);
  //   try {
  //     const url = `${ApiUrl}/api/user/prospectives${
  //       editMode ? `/${prospectId}` : ""
  //     }`;
  //     const bodyData = editMode
  //       ? {
  //           ...values,
  //           // dialer_id: prospectData.dialer_id,
  //           appointment_id: prospectData.appointments[0]?.id,
  //         }
  //       : values;
  //     const method = editMode ? "PUT" : "POST";
  //     const res = await fetch(url, {
  //       method,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify(bodyData),
  //     });
  //     // console.log("res", res)
  //     const data = await res.json();
  //     // console.log("data", data)
  //     if (data.status === "success") {
  //       toast.success(data.message);
  //       // navigate("/event-portal");
  //       window.history.back();
  //     } else {
  //       toast.error(data.message);
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     toast.error("Something went wrong!");
  //   }
  // };

  return (
    <div>
      <div className="container maincont">
        <div id="app" className="appcountainer2">
          <div className="row align-items-center justify-content-between">
            <div className="d-flex justify-content-between gap-3 align-items-center">
              <div>
                <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                  {window.location.href.includes("edit-appointment")
                    ? translations.appointments.editAppointment[language]
                    : editMode
                    ? translations.createProspective.editProspect[language]
                    : translations.createProspective.title[language]}
                </h2>
              </div>
              <div className="">
                <button
                  className="w-100 admin-btn btn-lg btn-primary d-flex align-items-center gap-1"
                  type="button"
                  onClick={() => window.history.back()}
                >
                  <i className="bi bi-arrow-return-left"></i>
                  {window.location.href.includes("edit-appointment")
                    ? translations.commonFields.backToAppointments[language]
                    : translations.commonFields.backToProspects[language]}
                </button>
              </div>
            </div>
            {false && (
              <div className="col-8 gap-1 d-flex align-items-center justify-content-end">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    //   onClick={() => {
                    //     setDisplayComponent("events");

                    //     window.history.pushState(
                    //       {
                    //         id: "gallery",
                    //         randomData: window.Math.random(),
                    //       },
                    //       "title",
                    //       "/event-portal?page=events"
                    //     );
                    //   }}
                  >
                    {/* <i className="bi bi-speedometer"></i>{" "} */}
                    {translations.commonFields.upload[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                  >
                    {/* <i className="bi bi-calendar2-range"></i>{" "} */}
                    {translations.commonFields.bulkUpload[language]}
                  </button>
                </div>
              </div>
            )}
          </div>
          <Formik
            initialValues={initialData}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ errors, values, setValues, setFieldValue, isSubmitting }) => (
              <Form>
                <div
                  className="p-2"
                  // style={{ height: "calc(100vh - 240px)" }}
                >
                  <div
                    className="card card-body my-4"
                    style={{
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                      maxHeight: editMode ? "415px" : "",
                      overflowY: editMode ? "auto" : "",
                    }}
                  >
                    <h3 className={`mt-2 ${isDarkMode ? "darkthead" : ""}`}>
                      {translations.eventHeadings.general[language]}
                    </h3>

                    <div className="row">
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.commonFields.firstName[language]}
                          </label>
                          <Field
                            type="text"
                            name="first_name"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />

                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.commonFields.lastName[language]}
                          </label>
                          <Field
                            type="text"
                            name="last_name"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.commonFields.phone[language]}
                          </label>
                          <Field
                            type="text"
                            name="phone"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />

                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.commonFields.email[language]}
                          </label>
                          <Field
                            type="email"
                            name="email"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>{" "}
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.createProspective.make[language]}
                          </label>
                          <Field
                            type="text"
                            name="make"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />

                          <ErrorMessage
                            name="make"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.createProspective.model[language]}
                          </label>
                          <Field
                            type="text"
                            name="model"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="model"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="col">
                          <div className="mb-3">
                            <label
                              for="annual-sales-count"
                              className="form-label"
                            >
                              {translations.usersPage.createUser.year[language]}
                            </label>
                            <Field
                              type="number"
                              name="year"
                              className="form-control"
                              style={{
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />

                            <ErrorMessage
                              name="year"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            KM
                          </label>
                          <Field
                            type="text"
                            name="km"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="km"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.clients.table.callStatus[language]}
                          </label>
                          <Field
                            type="text"
                            name="call_status"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="call_status"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>

                      {/* <div className="col-4">
                        <div className="mb-3">
                          <label className="form-label">
                            {
                              translations.commonWords.usersTypes
                                .representative[language]
                            }
                          </label>
                          <Field
                            name="sales_rep_id"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={repOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (repOptions &&
                                    repOptions.length > 0 &&
                                    repOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                    display: "block",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="sales_rep_id"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div> */}
                    </div>
                    {editMode && (
                      <div className="row">
                        <div className="col-4">
                          <div className="mb-3">
                            <label
                              for="annual-sales-count"
                              className="form-label"
                            >
                              {
                                translations.createProspective
                                  .prospectiveSource[language]
                              }
                            </label>
                            {/* <Field type="text" name="digital" className="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                            <Field
                              name="prospect_source"
                              render={({ field, form }) => {
                                // Enhanced matching function to handle both English and French values
                                const getCurrentOption = () => {
                                  // Try to find a match in both English and French values
                                  const matchedOption =
                                    prospectiveSourceOptions.find(
                                      (option) =>
                                        option.value.en?.toLowerCase() ===
                                          field.value?.toLowerCase() ||
                                        option.value.fr?.toLowerCase() ===
                                          field.value?.toLowerCase()
                                    );

                                  // If a match is found, return the option with the current language label
                                  if (matchedOption) {
                                    return {
                                      value: matchedOption.value.en, // Always store English value
                                      label: matchedOption.label[language],
                                    };
                                  }

                                  // If no match found, return a default option
                                  return {
                                    value: field.value || "",
                                    label:
                                      field.value ||
                                      translations["dropdown"].select[language],
                                  };
                                };

                                return (
                                  <Select
                                    className="selectpicker"
                                    options={prospectiveSourceOptions.map(
                                      (option) => ({
                                        value: option.value.en, // Always use English value for storage
                                        label: option.label[language],
                                      })
                                    )}
                                    placeholder={
                                      translations["dropdown"].select[language]
                                    }
                                    isSearchable
                                    value={getCurrentOption()}
                                    onChange={(selectedOption) => {
                                      // Always set the English value as the stored value
                                      form.setFieldValue(
                                        field.name,
                                        selectedOption.value
                                      );
                                    }}
                                    maxMenuHeight={"85px"}
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "white",
                                        border: state.isFocused
                                          ? "1px solid #80bdff"
                                          : isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".45rem",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#868686",
                                        display: "block",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        margin: "0", // Remove default margin
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#34DBB4"
                                            : "#34DBB4",
                                        },
                                      }),
                                    }}
                                  />
                                );
                              }}
                            />
                            <ErrorMessage
                              name="prospect_source"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {
                      <>
                        <div className="row">
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {translations.commonFields.address[language]}
                              </label>
                              <Field
                                type="text"
                                name="address"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {translations.commonFields.city[language]}
                              </label>
                              <Field
                                type="text"
                                name="city"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>{" "}
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {translations.commonFields.province[language]}
                              </label>
                              <Field
                                type="text"
                                name="province"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />
                              <ErrorMessage
                                name="province"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>{" "}
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                Activix ID
                              </label>
                              <Field
                                type="text"
                                name="activix_id"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />

                              <ErrorMessage
                                name="activix_id"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {
                                  translations.clients.table.prospectiveType[
                                    language
                                  ]
                                }
                              </label>
                              <Field
                                type="text"
                                name="prospect_type"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />

                              <ErrorMessage
                                name="prospect_type"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="mb-3">
                              <label className="form-label">
                                {
                                  translations.createProspective.listIds[
                                    language
                                  ]
                                }
                              </label>
                              <Field
                                name="list_id"
                                render={({ field, form }) => (
                                  <Select
                                    className="selectpicker"
                                    aria-labelledby="dealership-name-quick"
                                    // isClearable
                                    options={listIds}
                                    placeholder={
                                      translations["dropdown"].select[language]
                                    }
                                    isDisabled={true}
                                    isSearchable
                                    value={
                                      (listIds &&
                                        listIds.length > 0 &&
                                        listIds.find(
                                          (option) =>
                                            option.value === field.value
                                        )) || {
                                        value: "",
                                        label: "",
                                      }
                                    }
                                    onChange={(selectedOption) =>
                                      form.setFieldValue(
                                        field.name,
                                        selectedOption.value
                                      )
                                    }
                                    maxMenuHeight={"120px"}
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: isDarkMode
                                          ? "#ced4da66"
                                          : "#ced4da66",
                                        border: state.isFocused
                                          ? "1px solid #80bdff"
                                          : isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".45rem",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#868686",
                                        display: "none",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        margin: "0", // Remove default margin
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#34DBB4"
                                            : "#34DBB4",
                                        },
                                      }),
                                    }}
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="list_id"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {
                                  translations.forms.formFields.conquestSection
                                    .postalCode[language]
                                }
                              </label>
                              <Field
                                type="text"
                                name="postal_code"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />
                              <ErrorMessage
                                name="postal_code"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {
                                  translations.createProspective.officeNumber[
                                    language
                                  ]
                                }
                              </label>
                              <Field
                                type="text"
                                name="office_phone"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />
                              <ErrorMessage
                                name="office_phone"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="mb-3">
                              <label className="form-label">
                                {
                                  translations.appointments.appointment[
                                    language
                                  ]
                                }
                              </label>
                              <Field
                                name="appointment"
                                render={({ field, form }) => (
                                  <Select
                                    className="selectpicker"
                                    aria-labelledby="dealership-name-quick"
                                    // isClearable
                                    options={appointmentOption}
                                    placeholder={
                                      translations["dropdown"].select[language]
                                    }
                                    isSearchable
                                    value={
                                      (appointmentOption &&
                                        appointmentOption.length > 0 &&
                                        appointmentOption.find(
                                          (option) =>
                                            option.value === field.value
                                        )) || {
                                        value: "",
                                        label:
                                          translations["dropdown"].select[
                                            language
                                          ],
                                      }
                                    }
                                    onChange={(selectedOption) =>
                                      form.setFieldValue(
                                        field.name,
                                        selectedOption.value
                                      )
                                    }
                                    maxMenuHeight={"120px"}
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "white",
                                        border: state.isFocused
                                          ? "1px solid #80bdff"
                                          : isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".45rem",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#868686",
                                        display: "block",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        margin: "0", // Remove default margin
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#34DBB4"
                                            : "#34DBB4",
                                        },
                                      }),
                                    }}
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="appointment"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {
                                  translations.commonFields.mobileNumber[
                                    language
                                  ]
                                }
                              </label>
                              <Field
                                type="text"
                                name="mobile_phone"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />

                              <ErrorMessage
                                name="mobile_phone"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {translations.commonFields.language[language]}
                              </label>
                              <Field
                                type="text"
                                name="language"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />
                              <ErrorMessage
                                name="language"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {translations.createProspective.VIN[language]}
                              </label>
                              <Field
                                type="text"
                                name="vin"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />
                              <ErrorMessage
                                name="vin"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {
                                  translations.createProspective.purchaseDate[
                                    language
                                  ]
                                }
                              </label>
                              <Field
                                type="date"
                                name="purchase_date"
                                className={`form-control ${
                                  isDarkMode ? "custom-date" : ""
                                }`}
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    purchase_date: e.target.value,
                                  });
                                }}
                              />

                              <ErrorMessage
                                name="purchase_date"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {
                                  translations.createProspective
                                    .lastServiceDate[language]
                                }
                              </label>
                              <Field
                                type="date"
                                name="last_service_date"
                                className={`form-control ${
                                  isDarkMode ? "custom-date" : ""
                                }`}
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    last_service_date: e.target.value,
                                  });
                                }}
                              />

                              <ErrorMessage
                                name="last_service_date"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          {!editMode && (
                            <div className="col-4">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective
                                      .prospectiveSource[language]
                                  }
                                </label>
                                {/* <Field type="text" name="digital" className="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                                <Field
                                  name="prospect_source"
                                  render={({ field, form }) => {
                                    // Enhanced matching function to handle both English and French values
                                    const getCurrentOption = () => {
                                      // Try to find a match in both English and French values
                                      const matchedOption =
                                        prospectiveSourceOptions.find(
                                          (option) =>
                                            option.value.en?.toLowerCase() ===
                                              field.value?.toLowerCase() ||
                                            option.value.fr?.toLowerCase() ===
                                              field.value?.toLowerCase()
                                        );

                                      // If a match is found, return the option with the current language label
                                      if (matchedOption) {
                                        return {
                                          value: matchedOption.value.en, // Always store English value
                                          label: matchedOption.label[language],
                                        };
                                      }

                                      // If no match found, return a default option
                                      return {
                                        value: field.value || "",
                                        label:
                                          field.value ||
                                          translations["dropdown"].select[
                                            language
                                          ],
                                      };
                                    };

                                    return (
                                      <Select
                                        className="selectpicker"
                                        options={prospectiveSourceOptions.map(
                                          (option) => ({
                                            value: option.value.en, // Always use English value for storage
                                            label: option.label[language],
                                          })
                                        )}
                                        placeholder={
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                        isSearchable
                                        value={getCurrentOption()}
                                        onChange={(selectedOption) => {
                                          // Always set the English value as the stored value
                                          form.setFieldValue(
                                            field.name,
                                            selectedOption.value
                                          );
                                        }}
                                        maxMenuHeight={"85px"}
                                        styles={{
                                          input: (provided) => ({
                                            ...provided,
                                            color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                          }),
                                          control: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: isDarkMode
                                              ? "transparent"
                                              : "white",
                                            border: state.isFocused
                                              ? "1px solid #80bdff"
                                              : isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6", // Adjust the border color for focus
                                            boxShadow: state.isFocused
                                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                              : "none", // Bootstrap's box shadow for focus
                                            borderRadius: ".45rem",
                                          }),
                                          valueContainer: (provided) => ({
                                            ...provided,
                                            padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                          }),
                                          indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none", // Hide the indicator separator
                                          }),
                                          dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#868686",
                                            display: "block",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            color: isDarkMode ? "#ffffff" : "black",
                                            backgroundColor: isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            margin: "0", // Remove default margin
                                          }),
                                          placeholder: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#333333", // Specify your desired color for the placeholder
                                            // Additional styles for placeholder
                                          }),
                                          singleValue: (provided) => ({
                                            ...provided,
                                            color: isDarkMode ? "#ffffff" : "black",
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            color: isDarkMode ? "#ffffff" : "black",
                                            backgroundColor: state.isHovered
                                              ? "#80bdff"
                                              : isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            ":hover": {
                                              backgroundColor: isDarkMode
                                                ? "#34DBB4"
                                                : "#34DBB4",
                                            },
                                          }),
                                        }}
                                      />
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="prospect_source"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          )}
                          {editMode && (
                            <>
                              <div className="col-4">
                                <div className="mb-3">
                                  <label
                                    for="annual-sales-count"
                                    className="form-label"
                                  >
                                    {
                                      translations.createProspective.greetings[
                                        language
                                      ]
                                    }
                                  </label>
                                  <Field
                                    type="text"
                                    name="greetings"
                                    className="form-control"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="greetings"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {editMode && (
                          <div className="row">
                            <div className="col-4">
                              <div className="mb-3">
                                <label className="form-label">Dialar ID</label>
                                <Field
                                  className="form-control"
                                  disabled={true}
                                  name="dialer_id"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "#ced4da66"
                                      : "#ced4da66",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="dialer_id"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  OG Rep
                                </label>
                                <Field
                                  type="text"
                                  name="og_rep"
                                  className="form-control"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="og_rep"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>{" "}
                          </div>
                        )}
                        {!editMode && (
                          <div className="row">
                            <div className="col-4">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective.greetings[
                                      language
                                    ]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="greetings"
                                  className="form-control"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="greetings"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>{" "}
                            <div className="col-4">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  OG Rep
                                </label>
                                <Field
                                  type="text"
                                  name="og_rep"
                                  className="form-control"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="og_rep"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>{" "}
                          </div>
                        )}
                      </>
                    }
                  </div>
                  {editMode && (
                    <div className="row align-items-stretch">
                      <div className="col-4">
                        <div
                          className="card card-body h-100 d-flex flex-column justify-content-between "
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        >
                          <div className="row">
                            <h3
                              className={`py-2 ${
                                isDarkMode ? "darkthead" : ""
                              }`}
                            >
                              {translations.appointments.appointment[language]}
                            </h3>
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective
                                      .appointmentRepresentative[language]
                                  }
                                </label>
                                <Field
                                  name="appointment_rep_user_name"
                                  render={({ field, form }) => (
                                    <Select
                                      className="selectpicker"
                                      // isClearable
                                      options={repOptions}
                                      placeholder={
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                      isSearchable
                                      value={
                                        (repOptions &&
                                          repOptions.length > 0 &&
                                          repOptions.find(
                                            (option) =>
                                              option.label === field.value
                                          )) || {
                                          value: "",
                                          label:
                                            field.value || translations["dropdown"].select[
                                              language
                                            ],
                                        }
                                      }
                                      onChange={(selectedOption) =>
                                        form.setFieldValue(
                                          field.name,
                                          selectedOption.label
                                        )
                                      }
                                      maxMenuHeight={"85px"}
                                      styles={{
                                        input: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black", // Color of the text when typing
                                        }),
                                        control: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "white",
                                          border: state.isFocused
                                            ? "1px solid #80bdff"
                                            : isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6", // Adjust the border color for focus
                                          boxShadow: state.isFocused
                                            ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                            : "none", // Bootstrap's box shadow for focus
                                          borderRadius: ".45rem",
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                        }),
                                        indicatorSeparator: (provided) => ({
                                          ...provided,
                                          display: "none", // Hide the indicator separator
                                        }),
                                        dropdownIndicator: (provided) => ({
                                          ...provided,
                                          padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#868686",
                                        }),
                                        menu: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                          backgroundColor: isDarkMode
                                            ? "#1f2023"
                                            : "white",
                                          margin: "0", // Remove default margin
                                        }),
                                        placeholder: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#333333", // Specify your desired color for the placeholder
                                          // Additional styles for placeholder
                                        }),
                                        singleValue: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                          backgroundColor: state.isHovered
                                            ? "#80bdff"
                                            : isDarkMode
                                            ? "#1f2023"
                                            : "white",
                                          ":hover": {
                                            backgroundColor: isDarkMode
                                              ? "#34DBB4"
                                              : "#34DBB4",
                                          },
                                        }),
                                      }}
                                    />
                                  )}
                                />

                                <ErrorMessage
                                  name="appointment_rep_user_name"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.clients.table.appointmentDate[
                                      language
                                    ]
                                  }
                                </label>
                                <Field
                                  type="date"
                                  name="appointment_date"
                                  className={`form-control ${
                                    isDarkMode ? "custom-date" : ""
                                  }`}
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                  onChange={(e) => {
                                    setValues({
                                      ...values,
                                      appointment_date: e.target.value,
                                    });
                                  }}
                                />

                                <ErrorMessage
                                  name="appointment_date"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            {/* <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective
                                      .appointmentAgent[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="appointment_agent_name"
                                  className="form-control"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />

                                <ErrorMessage
                                  name="appointment_agent_name"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {
                                  translations.clients.table.appointmentStatus[
                                    language
                                  ]
                                }
                              </label>
                              <Field
                                type="text"
                                name="appointment_status"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />

                              <ErrorMessage
                                name="appointment_status"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div> */}
                            {/* <div className="col-12">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {
                                  translations.createProspective
                                    .appointmentStatusNotes[language]
                                }
                              </label>
                              <Field
                                type="text"
                                name="appointment_status_notes"
                                className="form-control"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />
                              <ErrorMessage
                                name="appointment_status_notes"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div> */}
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.clients.table.appointmentTime[
                                      language
                                    ]
                                  }
                                </label>
                                <Field
                                  type="time"
                                  name="appointment_time"
                                  className={`form-control ${
                                    isDarkMode ? "custom-date" : ""
                                  }`}
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                  onChange={(e) => {
                                    setValues({
                                      ...values,
                                      appointment_time: e.target.value,
                                    });
                                  }}
                                />
                                <ErrorMessage
                                  name="appointment_time"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective
                                      .appointmentComment[language]
                                  }
                                </label>
                                <Field
                                  as="textarea"
                                  type="text"
                                  name="appointment_comment"
                                  className="form-control"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />

                                <ErrorMessage
                                  name="appointment_comment"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-12 d-flex align-items-center gap-5 mt-3">
                              <p className="fw-bold">
                                {values.appointment_entry_date
                                  ? translations.createProspective.bookedOn[
                                      language
                                    ] +
                                    ": " +
                                    " " +
                                    values?.appointment_entry_date
                                  : ""}
                              </p>
                              <p className="fw-bold">
                                {values.appointment_agent_name
                                  ? `${translations.commonWords.by[language]}: ${values.appointment_agent_name}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            {" "}
                            <div className="col-12 d-flex justify-content-end align-items-center mt-3">
                              <button
                                type="button"
                                className="client-btn btn-success mx-2"
                                onClick={() => {
                                  const appointmentBodyData = {
                                    appointment_rep_user_name:
                                      values.appointment_rep_user_name,
                                    appointment_date: values.appointment_date,
                                    appointment_entry_date:
                                      values.appointment_entry_date,
                                    appointment_time: values.appointment_time,
                                    appointment_comment:
                                      values.appointment_comment,
                                  };
                                  handleSubmit2(appointmentBodyData);
                                }}
                                // id="add-customer-button"
                                // disabled={isSubmitting}
                              >
                                <i className="bi bi-save"></i>{" "}
                                {
                                  translations["commonFields"].submitBtn[
                                    language
                                  ]
                                }
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div
                          className="card card-body h-100 d-flex flex-column justify-content-between "
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        >
                          <div className="row">
                            <h3
                              className={`py-2 ${
                                isDarkMode ? "darkthead" : ""
                              }`}
                            >
                              {
                                translations.createProspective.confirmation[
                                  language
                                ]
                              }
                            </h3>
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective
                                      .confirmationStatus[language]
                                  }
                                </label>
                                <Field
                                  name="confirmation_status"
                                  render={({ field, form }) => (
                                    <Select
                                      className="selectpicker"
                                      aria-labelledby="dealership-name-quick"
                                      // isClearable
                                      options={confirmationStatusOptions}
                                      placeholder={
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                      isSearchable
                                      value={
                                        (confirmationStatusOptions &&
                                          confirmationStatusOptions.length >
                                            0 &&
                                          confirmationStatusOptions.find(
                                            (option) =>
                                              // true
                                              option?.value?.toLowerCase() ===
                                              field?.value?.toLowerCase()
                                          )) || {
                                          value: "",
                                          label:
                                            translations["dropdown"].select[
                                              language
                                            ],
                                        }
                                      }
                                      onChange={(selectedOption) =>
                                        form.setFieldValue(
                                          field.name,
                                          selectedOption.value
                                        )
                                      }
                                      maxMenuHeight={"120px"}
                                      styles={{
                                        input: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black", // Color of the text when typing
                                        }),
                                        control: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "white",
                                          border: state.isFocused
                                            ? "1px solid #80bdff"
                                            : isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6", // Adjust the border color for focus
                                          boxShadow: state.isFocused
                                            ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                            : "none", // Bootstrap's box shadow for focus
                                          borderRadius: ".45rem",
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                        }),
                                        indicatorSeparator: (provided) => ({
                                          ...provided,
                                          display: "none", // Hide the indicator separator
                                        }),
                                        dropdownIndicator: (provided) => ({
                                          ...provided,
                                          padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#868686",
                                          display: "block",
                                        }),
                                        menu: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                          backgroundColor: isDarkMode
                                            ? "#1f2023"
                                            : "white",
                                          margin: "0", // Remove default margin
                                        }),
                                        placeholder: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#333333", // Specify your desired color for the placeholder
                                          // Additional styles for placeholder
                                        }),
                                        singleValue: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                          backgroundColor: state.isHovered
                                            ? "#80bdff"
                                            : isDarkMode
                                            ? "#1f2023"
                                            : "white",
                                          ":hover": {
                                            backgroundColor: isDarkMode
                                              ? "#34DBB4"
                                              : "#34DBB4",
                                          },
                                        }),
                                      }}
                                    />
                                  )}
                                />

                                <ErrorMessage
                                  name="confirmation_status"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            {/* <div className="col-12">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {
                                  translations.createProspective
                                    .confirmationStatusDate[language]
                                }
                              </label>
                              <Field
                                type="date"
                                name="confirmation_status_entry_date"
                                className={`form-control ${
                                  isDarkMode ? "custom-date" : ""
                                }`}
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    confirmation_status_entry_date:
                                      e.target.value,
                                  });
                                }}
                              />

                              <ErrorMessage
                                name="confirmation_status_entry_date"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div> */}
                            {false && (
                              <div className="col-12">
                                <div className="mb-3">
                                  <label
                                    for="annual-sales-count"
                                    className="form-label"
                                  >
                                    {
                                      translations.createProspective
                                        .confirmationAgent[language]
                                    }
                                  </label>
                                  {/* <Field
                                name="confirmation_agent_id"
                                render={({ field, form }) => (
                                  <Select
                                    className="selectpicker"
                                    aria-labelledby="dealership-name-quick"
                                    // isClearable
                                    options={confirmationStatusOptions}
                                    placeholder={
                                      translations["dropdown"].select[language]
                                    }
                                    isSearchable
                                    value={
                                      (confirmationStatusOptions &&
                                        confirmationStatusOptions.length > 0 &&
                                        confirmationStatusOptions.find(
                                          (option) =>
                                            option.value === field.value
                                        )) || {
                                        value: "",
                                        label:
                                          translations["dropdown"].select[
                                            language
                                          ],
                                      }
                                    }
                                    onChange={(selectedOption) =>
                                      form.setFieldValue(
                                        field.name,
                                        selectedOption.value
                                      )
                                    }
                                    maxMenuHeight={"120px"}
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "white",
                                        border: state.isFocused
                                          ? "1px solid #80bdff"
                                          : isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".45rem",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#868686",
                                        display: "block",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        margin: "0", // Remove default margin
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#34DBB4"
                                            : "#34DBB4",
                                        },
                                      }),
                                    }}
                                  />
                                )}
                              /> */}
                                  <Field
                                    type="text"
                                    name="confirmation_agent_name"
                                    className="form-control"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />

                                  <ErrorMessage
                                    name="confirmation_agent_name"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            )}
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective
                                      .confirmationNotes[language]
                                  }
                                </label>
                                <Field
                                  as="textarea"
                                  type="text"
                                  name="confirmation_notes"
                                  className="form-control"
                                  rows={5}
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />

                                <ErrorMessage
                                  name="confirmation_notes"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div className="col-12 d-flex align-items-center gap-5 mt-3">
                              <p className="fw-bold">
                                {values?.confirmation_status_entry_date
                                  ? translations.createProspective.confirmedOn[
                                      language
                                    ] +
                                    ":" +
                                    " " +
                                    values?.confirmation_status_entry_date
                                  : ""}
                              </p>
                              <p className="fw-bold">
                                {values.confirmation_agent_name
                                  ? `${translations.commonWords.by[language]}: ${values.confirmation_agent_name}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            {" "}
                            <div className="col-12 d-flex justify-content-end align-items-center mt-3">
                              <button
                                type="button"
                                className="client-btn btn-success mx-2"
                                // id="add-customer-button"
                                // disabled={isSubmitting}
                                onClick={() => {
                                  const appointmentBodyData = {
                                    confirmation_status:
                                      values.confirmation_status,
                                    confirmation_notes:
                                      values.confirmation_notes,
                                  };
                                  handleSubmit2(appointmentBodyData);
                                }}
                              >
                                <i className="bi bi-save"></i>{" "}
                                {
                                  translations["commonFields"].submitBtn[
                                    language
                                  ]
                                }
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 h-100 ">
                        <div
                          className="card card-body d-flex flex-column justify-content-between "
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        >
                          <div className="row">
                            <h3
                              className={`py-2 ${
                                isDarkMode ? "darkthead" : ""
                              }`}
                            >
                              {translations.createProspective.visit[language]}
                            </h3>
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective.visitStatus[
                                      language
                                    ]
                                  }
                                </label>
                                <Field
                                  name="visit_status"
                                  render={({ field, form }) => (
                                    <Select
                                      className="selectpicker"
                                      // isClearable
                                      options={visitStatusOptions}
                                      placeholder={
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                      isSearchable
                                      value={
                                        (visitStatusOptions &&
                                          visitStatusOptions.length > 0 &&
                                          visitStatusOptions.find(
                                            (option) =>
                                              option.value === field.value
                                          )) || {
                                          value: "",
                                          label:
                                            translations["dropdown"].select[
                                              language
                                            ],
                                        }
                                      }
                                      onChange={(selectedOption) =>
                                        form.setFieldValue(
                                          field.name,
                                          selectedOption.value
                                        )
                                      }
                                      maxMenuHeight={"85px"}
                                      styles={{
                                        input: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black", // Color of the text when typing
                                        }),
                                        control: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "white",
                                          border: state.isFocused
                                            ? "1px solid #80bdff"
                                            : isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6", // Adjust the border color for focus
                                          boxShadow: state.isFocused
                                            ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                            : "none", // Bootstrap's box shadow for focus
                                          borderRadius: ".45rem",
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                        }),
                                        indicatorSeparator: (provided) => ({
                                          ...provided,
                                          display: "none", // Hide the indicator separator
                                        }),
                                        dropdownIndicator: (provided) => ({
                                          ...provided,
                                          padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#868686",
                                        }),
                                        menu: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                          backgroundColor: isDarkMode
                                            ? "#1f2023"
                                            : "white",
                                          margin: "0", // Remove default margin
                                        }),
                                        placeholder: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#333333", // Specify your desired color for the placeholder
                                          // Additional styles for placeholder
                                        }),
                                        singleValue: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                          backgroundColor: state.isHovered
                                            ? "#80bdff"
                                            : isDarkMode
                                            ? "#1f2023"
                                            : "white",
                                          ":hover": {
                                            backgroundColor: isDarkMode
                                              ? "#34DBB4"
                                              : "#34DBB4",
                                          },
                                        }),
                                      }}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="visit_status"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>{" "}
                            {/* <div className="col-12">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {
                                  translations.createProspective
                                    .visitStatusDate[language]
                                }
                              </label>
                              <Field
                                type="date"
                                name="visit_status_entry_date"
                                className={`form-control ${
                                  isDarkMode ? "custom-date" : ""
                                }`}
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    visit_status_entry_date: e.target.value,
                                  });
                                }}
                              />

                              <ErrorMessage
                                name="visit_status_entry_date"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div> */}
                            {/* <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective.visitStatusUser[
                                      language
                                    ]
                                  }
                                </label>
                                <Field
                                  as="textarea"
                                  type="text"
                                  name="visit_status_user_name"
                                  className="form-control"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="visit_status_user_name"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div> */}
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective.visitNotes[
                                      language
                                    ]
                                  }
                                </label>
                                <Field
                                  as="textarea"
                                  type="text"
                                  name="visit_notes"
                                  className="form-control"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="visit_notes"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-12 d-flex align-items-center gap-5 mt-3">
                              <p className="fw-bold">
                                {values?.visit_status_entry_date
                                  ? translations.createProspective.confirmedOn[
                                      language
                                    ] +
                                    ":" +
                                    " " +
                                    values?.visit_status_entry_date
                                  : ""}
                              </p>
                              <p className="fw-bold">
                                {values.visit_status_user_name
                                  ? `${translations.commonWords.by[language]}: ${values.visit_status_user_name}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            {" "}
                            <div className="col-12 d-flex justify-content-end align-items-center mt-3">
                              <button
                                type="button"
                                className="client-btn btn-success mx-2"
                                // id="add-customer-button"
                                // disabled={isSubmitting}
                                onClick={() => {
                                  const appointmentBodyData = {
                                    visit_notes: values.visit_notes,
                                    visit_status: values.visit_status,
                                  };
                                  handleSubmit2(appointmentBodyData);
                                }}
                              >
                                <i className="bi bi-save"></i>{" "}
                                {
                                  translations["commonFields"].submitBtn[
                                    language
                                  ]
                                }
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="card card-body mt-2 d-flex flex-column justify-content-between "
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        >
                          <div className="row">
                            <h3
                              className={`py-2 ${
                                isDarkMode ? "darkthead" : ""
                              }`}
                            >
                              No-Show
                            </h3>
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective.noShowSatus[
                                      language
                                    ]
                                  }
                                </label>
                                <Field
                                  name="no_show_status"
                                  render={({ field, form }) => (
                                    <Select
                                      className="selectpicker"
                                      // isClearable
                                      options={noShowOptions}
                                      placeholder={
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                      isSearchable
                                      value={
                                        (noShowOptions &&
                                          noShowOptions.length > 0 &&
                                          noShowOptions.find(
                                            (option) =>
                                              option.value === field.value
                                          )) || {
                                          value: "",
                                          label:
                                            translations["dropdown"].select[
                                              language
                                            ],
                                        }
                                      }
                                      onChange={(selectedOption) =>
                                        form.setFieldValue(
                                          field.name,
                                          selectedOption.value
                                        )
                                      }
                                      maxMenuHeight={"85px"}
                                      styles={{
                                        input: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black", // Color of the text when typing
                                        }),
                                        control: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "white",
                                          border: state.isFocused
                                            ? "1px solid #80bdff"
                                            : isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6", // Adjust the border color for focus
                                          boxShadow: state.isFocused
                                            ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                            : "none", // Bootstrap's box shadow for focus
                                          borderRadius: ".45rem",
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                        }),
                                        indicatorSeparator: (provided) => ({
                                          ...provided,
                                          display: "none", // Hide the indicator separator
                                        }),
                                        dropdownIndicator: (provided) => ({
                                          ...provided,
                                          padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#868686",
                                        }),
                                        menu: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                          backgroundColor: isDarkMode
                                            ? "#1f2023"
                                            : "white",
                                          margin: "0", // Remove default margin
                                        }),
                                        placeholder: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#333333", // Specify your desired color for the placeholder
                                          // Additional styles for placeholder
                                        }),
                                        singleValue: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                          backgroundColor: state.isHovered
                                            ? "#80bdff"
                                            : isDarkMode
                                            ? "#1f2023"
                                            : "white",
                                          ":hover": {
                                            backgroundColor: isDarkMode
                                              ? "#34DBB4"
                                              : "#34DBB4",
                                          },
                                        }),
                                      }}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="no_show_status"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            {/* <div className="col-12">
                            <div className="mb-3">
                              <label
                                for="annual-sales-count"
                                className="form-label"
                              >
                                {
                                  translations.createProspective
                                    .no_show_status_entry_date[language]
                                }
                              </label>
                              <Field
                                type="date"
                                name="no_show_status_entry_date"
                                className={`form-control ${
                                  isDarkMode ? "custom-date" : ""
                                }`}
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    no_show_status_entry_date: e.target.value,
                                  });
                                }}
                              />

                              <ErrorMessage
                                name="no_show_status_entry_date"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div> */}
                            {/* <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective.noShowUserName[
                                      language
                                    ]
                                  }
                                </label>
                                <Field
                                  as="textarea"
                                  type="text"
                                  name="no_show_user_name"
                                  className="form-control"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="no_show_user_name"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div> */}
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  for="annual-sales-count"
                                  className="form-label"
                                >
                                  {
                                    translations.createProspective.noShowNotes[
                                      language
                                    ]
                                  }
                                </label>
                                <Field
                                  as="textarea"
                                  type="text"
                                  name="no_show_notes"
                                  className="form-control"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="no_show_notes"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div className="col-12 d-flex align-items-center gap-5 mt-3">
                              <p className="fw-bold">
                                {values?.no_show_status_entry_date
                                  ? translations.createProspective.confirmedOn[
                                      language
                                    ] +
                                    ":" +
                                    " " +
                                    values?.no_show_status_entry_date
                                  : ""}
                              </p>
                              <p className="fw-bold">
                                {values.no_show_user_name
                                  ? `${translations.commonWords.by[language]}: ${values.no_show_user_name}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            {" "}
                            <div className="col-12 d-flex justify-content-end align-items-center mt-3">
                              <button
                                type="button"
                                className="client-btn btn-success mx-2"
                                // id="add-customer-button"
                                // disabled={isSubmitting}
                                onClick={() => {
                                  const appointmentBodyData = {
                                    no_show_notes: values.no_show_notes,
                                    no_show_status: values.no_show_status,
                                  };
                                  handleSubmit2(appointmentBodyData);
                                }}
                              >
                                <i className="bi bi-save"></i>{" "}
                                {
                                  translations["commonFields"].submitBtn[
                                    language
                                  ]
                                }
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="footersec">
                  <div className="row">
                    <div className="col-sm" style={{ padding: 7 }}>
                      <button
                        type="submit"
                        className="client-btn btn-success mx-2"
                        onClick={() => setSubmitType("whole")}
                        id="add-customer-button"
                        disabled={isSubmitting}
                      >
                        <i className="bi bi-save"></i>{" "}
                        {translations["commonFields"].submitBtn[language]}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={
                          () =>
                            // window.history.pushState({ prevState: 'prev' }, 'title', '/event-portal?page=events')
                            window.history.back()
                          // setDisplayComponent('events')
                        }
                        id="add-customer-button"
                      >
                        <i className="bi bi-save"></i>{" "}
                        {translations["commonFields"].returnBtn[language]}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateProspective;
