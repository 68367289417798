import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import Select from "react-select";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Loader from "../loader";
import Dropzone from "react-dropzone";
import { ApiUrl } from "../ApiUrl";

const BulkUpload = ({ isDarkMode, eventId, listIds, configIds }) => {
  const token = localStorage.getItem("token");
  const { language } = useLanguage();
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  var button = document.getElementById("bulkUploadClose");
  var currentUrl = window.location.href;

  // console.log(currentUrl.includes("events"));

  const initialData = {
    private_event_id: eventId || "",
    list_id: "",
    config_id: "",
    csv_file: "",
  };
  const validationSchema = Yup.object().shape({});

  const handleSubmit = async (values, { resetForm }, error) => {
    try {
      setLoading(true);
      const formdata = new FormData();
      Object.keys(values).forEach((key) => {
        formdata.append(key, values[key]);
      });
      const res = await fetch(ApiUrl + "/api/user/import-csv", {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formdata,
      });
      const data = await res.json();
      if (data.status === "success") {
        resetForm();
        button.click();
        toast.success(data.msg);
      } else {
        toast.error("Something went wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div id="bulkUploadModal" className="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            id="creationsuccess"
          >
            {({ errors, values, setValues, resetForm, isSubmitting }) => (
              <Form>
                <div
                  class="modal-header"
                  style={{
                    borderBottom: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <h3 style={{ color: "#000000" }}>
                    {translations.clients.uploadFile[language]}
                  </h3>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => button.click()}
                    style={{
                      filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                      color: isDarkMode
                        ? "#ffffff"
                        : "rgb(255, 255, 255) !important",
                    }}
                    id="closeModalBtn"
                  ></button>
                </div>
                <div
                  className="modal-body"
                  style={{ height: "450px", overflowY: "auto" }}
                >
                  <div className="row">
                    <div className="mb-3 col-12">
                      <label className="form-label">
                        {translations.createProspective.listIds[language]}
                      </label>
                      <Field
                        name="list_id"
                        render={({ field, form }) => (
                          <Select
                            className="selectpicker"
                            aria-labelledby="dealership-name-quick"
                            // isClearable
                            options={listIds}
                            placeholder={
                              translations["dropdown"].select[language]
                            }
                            isSearchable
                            value={
                              (listIds &&
                                listIds.length > 0 &&
                                listIds.find(
                                  (option) => option.value === field.value
                                )) || {
                                value: "",
                                label:
                                  translations["dropdown"].select[language],
                              }
                            }
                            onChange={(selectedOption) =>
                              form.setFieldValue(
                                field.name,
                                selectedOption.value
                              )
                            }
                            maxMenuHeight={"120px"}
                            styles={{
                              input: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                              }),
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "white",
                                border: state.isFocused
                                  ? "1px solid #80bdff"
                                  : isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6", // Adjust the border color for focus
                                boxShadow: state.isFocused
                                  ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                  : "none", // Bootstrap's box shadow for focus
                                borderRadius: ".45rem",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none", // Hide the indicator separator
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                color: isDarkMode ? "#ffffff" : "#868686",
                                display: "block",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                margin: "0", // Remove default margin
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                // Additional styles for placeholder
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: state.isHovered
                                  ? "#80bdff"
                                  : isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                ":hover": {
                                  backgroundColor: isDarkMode
                                    ? "#34DBB4"
                                    : "#34DBB4",
                                },
                              }),
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="list_id"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="form-label">
                        {translations.createProspective.configId[language]}
                      </label>
                      <Field
                        name="config_id"
                        render={({ field, form }) => (
                          <Select
                            className="selectpicker"
                            aria-labelledby="dealership-name-quick"
                            // isClearable
                            options={configIds}
                            placeholder={
                              translations["dropdown"].select[language]
                            }
                            isSearchable
                            value={
                              (configIds &&
                                configIds.length > 0 &&
                                configIds.find(
                                  (option) => option.value === field.value
                                )) || {
                                value: "",
                                label:
                                  translations["dropdown"].select[language],
                              }
                            }
                            onChange={(selectedOption) =>
                              form.setFieldValue(
                                field.name,
                                selectedOption.value
                              )
                            }
                            maxMenuHeight={"120px"}
                            styles={{
                              input: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                              }),
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "white",
                                border: state.isFocused
                                  ? "1px solid #80bdff"
                                  : isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6", // Adjust the border color for focus
                                boxShadow: state.isFocused
                                  ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                  : "none", // Bootstrap's box shadow for focus
                                borderRadius: ".45rem",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none", // Hide the indicator separator
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                color: isDarkMode ? "#ffffff" : "#868686",
                                display: "block",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                margin: "0", // Remove default margin
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                // Additional styles for placeholder
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: state.isHovered
                                  ? "#80bdff"
                                  : isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                ":hover": {
                                  backgroundColor: isDarkMode
                                    ? "#34DBB4"
                                    : "#34DBB4",
                                },
                              }),
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="config_id"
                        component="div"
                        className="error text-danger"
                      />
                    </div>

                    <div className="col-12">
                      <Dropzone
                        // disabled={!editable}
                        style={{
                          backgroundColor: isDarkMode
                            ? "#ced4da66"
                            : "#ced4da66",
                        }}
                        multiple={false}
                        accept=".csv, .xlsx"
                        onDrop={(acceptedFiles) => {
                          // console.log(acceptedFiles)
                          if (acceptedFiles.length > 0) {
                            setValues({
                              ...values,
                              csv_file: acceptedFiles[0],
                            });
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="client-form-row w-100">
                            <div className="w-100">
                              <label
                                htmlFor="csv_file"
                                className="pb-3"
                                style={{
                                  color: isDarkMode ? "#33dbb4" : "",
                                  fontSize: "20px",
                                  fontWeight: 600,
                                }} /*class="form-label"*/
                              >
                                {translations.clients.bulkUploadTitle[language]}
                              </label>

                              <div
                                {...getRootProps()}
                                role="presentation"
                                tabIndex="0"
                                style={{
                                  background: isDarkMode
                                    ? "#ced4da77"
                                    : "rgb(231, 236, 239)",
                                  // backgroundImage: `url(${URL.createObjectURL(values.logo)})`,
                                  // backgroundSize: "cover",
                                  // backgroundPosition: "center",
                                  padding: "25px 0px",
                                  borderRadius: "0.375rem",
                                  height: "180px",
                                  display: "flex",
                                  alignContent: "center",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <input
                                  {...getInputProps()}
                                  type="file"
                                  accept=".csv, .xlsx"
                                />
                                <div className="client-file-input">
                                  <p
                                    style={{
                                      textAlign: "center",
                                      fontSize: "15px",
                                      fontWeight: 700,
                                      marginBottom: "30px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    {
                                      translations.clients.bulkUploadSubTitle[
                                        language
                                      ]
                                    }
                                  </p>
                                  <div className="pb-5">
                                    <i
                                      className="bi bi-file-earmark-arrow-up-fill"
                                      style={{
                                        border: "10px dashed #babec1",
                                        padding: "15px 100px",
                                        color: "#babec1",
                                        borderRadius: "0.375rem",
                                        fontSize: "40pt",
                                        marginTop: "10px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </div>

                              {values.csv_file && (
                                <div>
                                  <div className="d-flex gap-3 align-items-center ">
                                    <div
                                      className="d-flex gap-1 py-3 align-items-center"
                                      style={{ position: "relative" }}
                                    >
                                      <i
                                        className="bi bi-x-circle"
                                        onClick={() =>
                                          setValues({ ...values, csv_file: "" })
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "16px",
                                          right: "4px",
                                          color: "red",
                                          cursor: "pointer",
                                          fontSize: "14px",
                                        }}
                                      ></i>
                                      <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{
                                          width: "65px",
                                          height: "60px",
                                        }}
                                      >
                                        <i
                                          className="bi bi-file-earmark-check"
                                          style={{ fontSize: "30px" }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <ErrorMessage
                                name="csv_file"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
                <div
                  class="modal-footer"
                  id="modalfooter"
                  style={{
                    borderTop: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <button
                    type="submit"
                    class="admin-btn btn btn-primary"
                    // id="add-dealership"
                    disabled={isSubmitting}
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                  >
                    {translations.commonFields.upload[language]}
                  </button>
                  <button
                    id="bulkUploadClose"
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    // data-bs-toggle={quickAdd ? "modal" : ""}
                    // data-bs-target={quickAdd ? "#modalQuickAdd" : ""}
                    onClick={() => {
                      resetForm();
                      setValues({
                        csv_file: "",
                        private_event_id: "",
                      });
                      setSelected("");
                    }}
                  >
                    {translations["commonFields"].closeBtn[language]}
                  </button>
                  {/* <br />
                  <div
                    class="alert alert-danger d-flex align-items-center invisible"
                    id="clientFormAlert"
                    role="alert"
                  >
                    <div id="clientFormAlertText"></div>
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default BulkUpload;
