import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "../ApiUrl";


const useDealerships2 = (token, dealerTrigger) => {
  const [dealerships, setDealerships] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    if (token) {
      const getDealerships = async () => {
        try {
          const res = await fetch(
            ApiUrl + "/api/user/drop-dealerships",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data = await res.json();

          if (data.status === "success") {
            setDealerships(data.dealerships);
          } else {
            toast.error(data.message);
          }
        } catch (error) {
          console.error("Error fetching dealerships:", error);
          // Handle error or show a toast if needed
        }
      };
      // if (userData && userData.roles[0].permissions.some((item)=> item.name === "All Dealerships"))
      getDealerships();
    }
  }, [token, dealerTrigger]);

  return dealerships;
};

export default useDealerships2;
